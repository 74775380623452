<template>
  <div>
    <Title
      :title="'customer.notice.title'"
      :goBack="true"
      class="px-5"
    />
    <div class="notice-wrap" v-if="companyInfo != null">
      <div class="notice-list" v-if="noticeTotal != 0">
        <v-list>
          <v-list-item-group>
            <div
              v-for="(item, index) in noticeList"
              :key="index"
            >
              <v-list-item @click="$router.push(`/customer/notice/${item.no}`)">
                <v-list-item-content>
                  <v-list-item-subtitle
                    v-text="time.makeDate(item.created)">
                  </v-list-item-subtitle>
                    <v-list-item-title class="contents-title pt-2 pr-3">
                      <span>{{ item.title }}</span>
                      <v-icon small v-if="item.fileCount !== 0">mdi-paperclip</v-icon>
                      <span>
                        {{ item.commentCount !== 0 ? `[${item.commentCount}]` : '[0]' }}
                      </span>
                    </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < noticeList.length - 1"
                class="mx-3 my-1 pa-0 grey lighten-1"
              ></v-divider>
            </div>
          </v-list-item-group>
          <v-pagination
            class="pt-3"
            v-model="page"
            :length="pageLength"
            circle
            @input="updatePage($event)"
          ></v-pagination>
        </v-list>
      </div>
      <div v-else>
        <div class="history-nothing">
          <font-awesome-icon icon="fa-solid fa-file-signature" />
          <div>{{ $t('customer.noHistory') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    time,
    showNotice: false,
    selectedNotice: null,
    filters: [
      {
        where: 'and',
        condition: 'eq',
        key: 'boardCode',
        value: 'notice',
      },
    ],
    noticeList: [],
    noticeTotal: 0,
    page: 1,
    pageLength: 0,
  }),
  methods: {
    /* eslint no-underscore-dangle: 0 */
    async getNoticeList() {
      this.$dialog.progress(true);
      try {
        const summary = await this.$emitter('post.summary.get', { filters: this.filters });
        this.noticeTotal = summary.data.total;
        this.pageLength = Math.ceil(this.noticeTotal / 10);
        const list = await this.$emitter('post.list.get', {
          page: this.page,
          itemsPerPage: 10,
          sortBy: ['created'],
          sortDesc: [true],
          filters: this.filters,
        });
        this.noticeList = list.items;
        this.$dialog.progress(false);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    updatePage(e) {
      this.page = e;
      this.getNoticeList();
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getNoticeList();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.notice-wrap {
  height: calc(100% - 12vh);
  overflow-y: scroll;
  .notice-list {
    width: 100%;
    .contents-title { white-space: break-spaces; }
  }
}
</style>
