<template>
  <div
    v-if="userInfo"
    class="account-wrap px-5 overflow-y-auto overflow-x-hidden pb-6">
    <div class="w-100 d-flex justify-space-between align-center">
      <Title
        :title="'nav.myAccount'"
        :isStyle="true"
      />
      <v-btn
        @click="openNotify = true"
        :ripple="false"
        depressed
        text
      >
        <v-badge
          v-if="unReadCount && userInfo"
          color="red"
          :content="unReadCount"
          overlap
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell-outline</v-icon>
      </v-btn>
    </div>
    <div class="d-flex align-center justify-space-between pb-3">
      <p>{{ `${userInfo.name} ${$t('user.`s')}`}}</p>
      <v-chip
        small
        outlined
        @click="$router.push('/account/profile')"
        :disabled="transactionData !== null"
      >
        <p class="text-caption">{{ $t('myAccount.update.update') }}</p>
      </v-chip>
    </div>
    <Point
      v-if="userInfo.restPoints !== 0"
      :userInfo="userInfo"
      :userPoint="userPoint"
    />
    <PncConfig
      :userInfo="userInfo"
    />
    <PaymentCard
      :defaultCard="defaultCard"
    />
    <MembershipCard
      :membership="membership"
    />
    <ChargingHistory
      :userInfo="userInfo"
    />
    <div class="d-flex align-center justify-center pt-3">
      <v-chip
        small
        outlined
        @click="logout"
      >
        <p class="text-caption">{{ $t('myAccount.logout.logout') }}</p>
      </v-chip>
    </div>
    <NotificationCenter
      v-show="openNotify"
      :openNotify.sync="openNotify"
      :userInfo="userInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getBackgroundColor } from '@/util/creditcardColors';
import Title from '../common/Title.vue';
import Point from './point/Point.vue';
import PncConfig from './pncConfig/PncConfig.vue';
import PaymentCard from './paymentCard/PaymentCard.vue';
import MembershipCard from './membershipCard/MembershipCard.vue';
import ChargingHistory from './chargingHistory/ChargingHistory.vue';
import NotificationCenter from './notificationCenter/NotificationCenter.vue';

export default {
  components: {
    Title, Point, PncConfig, PaymentCard, MembershipCard, ChargingHistory, NotificationCenter,
  },
  computed: {
    ...mapGetters({
      transactionData: 'transaction/transactionData',
      unReadCount: 'notification/unReadCount',
    }),
  },
  mounted() {
    this.getUser();
  },
  data: () => ({
    defaultCard: {},
    membership: {},
    openNotify: false,
    userPoint: 0,
    userInfo: {},
  }),
  methods: {
    ...mapActions({
      signout: 'auth/signout',
    }),
    async getUser() {
      try {
        const res = await this.$emitter('user.get', {});
        this.userInfo = res.userInfo;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getUserCard() {
      try {
        const res = await this.$emitter('usercard.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: [
            {
              where: 'and',
              condition: 'eq',
              key: 'isDefault',
              value: true,
            },
          ],
        });
        if (res.items.length > 0) {
          this.defaultCard = {
            cardName: res.items[0].cardName,
            cardNo: res.items[0].cardNo,
            icon: getBackgroundColor(res.items[0].cardCode),
            isDefault: res.items[0].isDefault,
          };
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getIdtag() {
      try {
        const res = await this.$emitter('idtag.get', {});
        this.membership = res.item.idTag == null ? {} : res.item.idTag;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async logout() {
      this.$dialog.confirm({
        show: true,
        message: 'myAccount.logout.logoutQ',
        btnText: 'button.confirm',
        callback: () => {
          this.signout();
          const logout = `${this.$t('alert.logouted')}`;
          this.$dialog.alert('success', logout);
          this.$dialog.confirm();
          setTimeout(() => {
            this.$router.push('/');
          }, 1000);
        },
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== null) {
          this.getUserCard();
          this.getIdtag();
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
