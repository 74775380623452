<template>
  <div class="post-wrap">
    <div class="search-addr">
      <div class="address-detail d-flex align-center">
        <p class="field-title">{{ $t('user.zipCode') }}</p>
        <div class="post-btn d-flex align-center">
          <v-text-field
            v-model="addressValue.zipCode"
            :rules="addressRules"
            class="field pa-0"
            disabled
          ></v-text-field>
          <v-btn
            class="pa-0 ml-3 px-1"
            color="primary"
            depressed
            outlined
            @click="searchAddress"
          >{{ $t('user.searchAddr') }}</v-btn>
        </div>
      </div>
    </div>
    <div class="address-detail d-flex align-center">
      <p class="field-title">{{ $t('user.address') }}</p>
      <v-text-field
        class="field pa-0"
        v-model="addressValue.address"
        :rules="addressRules"
        disabled
      ></v-text-field>
    </div>
    <div class="address-detail d-flex align-center">
      <p class="field-title">{{ $t('user.etc') }}</p>
      <v-text-field
        class="field pa-0"
        v-model="addressValue.detailAddress"
        :rules="addressRules"
        ref="detailAddr"
      ></v-text-field>
    </div>
    <div
      id="wrap"
      class="post-iframe"
      style="display:none;border:1px solid;width:100%;height:250px;
      position:relative"
    >
      <img
        src="//t1.daumcdn.net/postcode/resource/images/close.png"
        id="btnFoldWrap"
        style="cursor:pointer;position:absolute;right:0px;z-index:1"
        @click="closePostcode"
        alt="접기 버튼"
      >
    </div>
  </div>
</template>

<script>
import { searchAddress } from '@/util/postCode';

export default {
  props: {
    useAddress: {
      type: Object,
      required: false,
    },
    openSearchAddr: {
      type: Boolean,
    },
  },
  computed: {
    addressValue() {
      if (this.useAddress === null || this.useAddress === undefined) {
        return {
          zipCode: '',
          address: '',
          detailAddress: '',
        };
      }
      return this.useAddress;
    },
    addressRules() {
      return [(v) => !!v || this.$t('rules.require')];
    },
  },
  data: () => ({
    zipCode: '',
    address: '',
    detailAddress: '',
  }),
  methods: {
    closePostcode() {
      const elementWrap = document.getElementById('wrap');
      elementWrap.style.display = 'none';
      this.$emit('update:openSearchAddr', false);
    },
    searchAddress() {
      this.$emit('update:openSearchAddr', true);
      searchAddress((result) => {
        this.$emit('update:useAddress', {
          ...this.useAddress,
          zipCode: result.zipCode,
          address: result.address,
          detailAddress: result.extraAddr,
        });
        this.$refs.detailAddr.focus();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.post-iframe {
  max-width: 767px;
  max-height: 89vh !important;
  min-height: 89vh !important;
  position: fixed !important;
  left: 0;
  top: calc(var(--vh) * 10) !important;
  z-index: 1;
}
.search-addr {
  p {font-size: 0.8rem;}
  .post-btn {
    button { font-size: 12px;}
  }
}
.address-detail {
  p {font-size: 0.8rem;}
}
</style>
