import Vue from 'vue';
import store from '@/store';
import config from '../config/config.json';
import error from './error';

function checkResponseError(response) {
  const massage = error.makeErrorMessage(response);
  if (response.result === 'error') {
    store.dispatch('dialog/alert', ['error', massage]);
    return true;
  }
  return false;
}

function emitter(eventName, body, successMassage = null) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        if (successMassage !== null) store.dispatch('dialog/alert', ['success', successMassage]);
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

const defaultHeaders = {
  headers: { key: config.server.rest.key },
};
const host = `${config.server.path}${config.server.rest.path}`;

const rest = {
  patch: async (path, body, successMassage = null) => {
    const headers = { ...defaultHeaders };
    const token = store.getters['auth/companyInfo'] !== null
      ? localStorage.getItem(`BLITZ.${store.getters['auth/companyInfo'].code}.token`)
      : null;
    if (token !== null) headers.headers.Authorization = `Bearer ${token}`;
    const result = await (new Vue()).$axios.patch(`${host}${path}`, body, headers);
    const response = result.data;
    if (response.result === 'success') {
      if (successMassage !== null) store.dispatch('dialog/alert', ['success', successMassage]);
      return response;
    }
    throw response;
  },
  put: async (path, body, successMassage = null) => {
    const headers = { ...defaultHeaders };
    const token = store.getters['auth/companyInfo'] !== null
      ? localStorage.getItem(`BLITZ.${store.getters['auth/companyInfo'].code}.token`)
      : null;
    if (token !== null) headers.headers.Authorization = `Bearer ${token}`;
    const result = await (new Vue()).$axios.put(`${host}${path}`, body, headers);
    const response = result.data;
    if (response.result === 'success') {
      if (successMassage !== null) store.dispatch('dialog/alert', ['success', successMassage]);
      return response;
    }
    throw response;
  },
  post: async (path, body, successMassage = null) => {
    const headers = { ...defaultHeaders };
    const token = store.getters['auth/companyInfo'] !== null
      ? localStorage.getItem(`BLITZ.${store.getters['auth/companyInfo'].code}.token`)
      : null;
    if (token !== null) headers.headers.Authorization = `Bearer ${token}`;
    const result = await (new Vue()).$axios.post(`${host}${path}`, body, headers);
    const response = result.data;
    if (response.result === 'success') {
      if (successMassage !== null) store.dispatch('dialog/alert', ['success', successMassage]);
      return response;
    }
    throw response;
  },
};

export default {
  emitter,
  rest,
  checkResponseError,
};
