<template>
  <CustomerCenter/>
</template>

<script>
import CustomerCenter from '../../components/customerCenter/CustomerCenterSection.vue';

export default {
  name: 'customer',
  components: { CustomerCenter },
};
</script>
