export default {
  namespaced: true,
  state: {
    userEmail: null,
    userEmailToken: null,
    socialUserEmail: null,
    socialUserSub: null,
    socialUserType: null,
  },

  getters: {
    userEmail(state) {
      return state.userEmail;
    },
    userEmailToken(state) {
      return state.userEmailToken;
    },
    socialUserEmail(state) {
      return state.socialUserEmail;
    },
    socialUserSub(state) {
      return state.socialUserSub;
    },
    socialUserType(state) {
      return state.socialUserType;
    },
  },

  mutations: {
    userEmail(state, value) {
      state.userEmail = value;
    },
    userEmailToken(state, value) {
      state.userEmailToken = value;
    },
    socialUserEmail(state, value) {
      state.socialUserEmail = value;
    },
    socialUserSub(state, value) {
      state.socialUserSub = value;
    },
    socialUserType(state, value) {
      state.socialUserType = value;
    },
  },

  actions: {
  },
};
