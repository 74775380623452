<template>
  <div class="enroll-detail">
    <Title
      :title="'myAccount.paymentCard.enroll'"
      :goBack="true"
      class="px-5"
    />
    <div class="px-5">
      <p class="text-caption py-3">
        <v-icon small>mdi-alert-circle-outline</v-icon>
        {{ $t('myAccount.paymentCard.confirmCard')}}
      </p>
      <v-tabs
        v-model="cards"
        background-color="transparent"
        grow
      >
        <v-tab>{{ $t('myAccount.paymentCard.personalCard') }}</v-tab>
        <v-tab>{{ $t('myAccount.paymentCard.corpCard') }}</v-tab>
      </v-tabs>
      <v-form
        class="send-detail"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <div v-if="isPersonalCardTab" class="pt-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="text-caption red--text text--darken-1">
                {{ `* ${$t('myAccount.paymentCard.info')}` }}
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="red--text text--darken-1"
                >mdi-help-circle-outline</v-icon>
              </p>
            </template>
            <span class="text-caption">
              {{ $t('myAccount.paymentCard.cardInfo') }}
            </span>
          </v-tooltip>
        </div>
        <div class="pt-2">
          <p class="pr-2"> {{ $t('myAccount.paymentCard.number') }} </p>
          <v-text-field
            dense
            v-model="inputData.creditcardNumber"
            :placeholder="$t('myAccount.paymentCard.numberPlaceholder')"
            :rules="rules('creditCard')"
            type="number"
          ></v-text-field>
          <p class="pb-2">{{ $t('myAccount.paymentCard.expiration') }}</p>
          <div class="exp-date d-flex">
            <div class="d-flex align-center">
              <v-select
                :items="items.selectMonth"
                dense
                v-model="inputData.month"
                :rules="rules('common')"
                class="mr-3"
              ></v-select>
              <div class="date"> / </div>
            </div>
            <div class="d-flex align-center">
              <v-text-field
                dense
                v-model="inputData.year"
                :rules="rules('common')"
                type="number"
                class="mr-3"
              ></v-text-field>
              <div class="date align-start pr-3"></div>
            </div>
          </div>
          <p class="pb-2">
            {{ isPersonalCardTab
              ? $t('myAccount.paymentCard.birth')
              : $t('myAccount.paymentCard.corpNo') }}
          </p>
          <v-text-field
            dense
            v-model="inputData.birth"
            :rules="rules('common')"
            type="number"
          ></v-text-field>
          <p class="pb-2">{{ $t('myAccount.paymentCard.password') }}</p>
          <v-text-field
            dense
            v-model="inputData.password"
            :rules="rules('password')"
            type="password"
          ></v-text-field>
        </div>
      </v-form>
    </div>
    <BottomBtn
      :btnText="['button.submit']"
      :callback="submit"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import Title from '../../common/Title.vue';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { Title, BottomBtn },
  computed: {
    rules() {
      return (type) => {
        const common = [(v) => !!v || this.$t('rules.require')];
        const creditCard = [
          (v) => /(5[1-5]\d{14})|(4\d{12})(\d{3}?)|3[47]\d{13}|(62\d{14})|(6011\d{12})|((30[0-5]|36\d|38\d)\d{11})/.test(v) || this.$t('rules.incorrectNumber'),
        ];
        const password = [(v) => /^[0-9]+$/.test(v) || this.$t('rules.number')];
        if (type === 'common') return common;
        if (type === 'creditCard') return [...common, ...creditCard];
        if (type === 'password') return [...common, ...password];
        return '';
      };
    },
    isPersonalCardTab() {
      return this.cards === 0;
    },
    isCorporateCardTab() {
      return this.cards === 1;
    },
  },
  data: () => ({
    valid: true,
    cards: 0,
    items: {
      selectMonth: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    },
    inputData: {
      creditcardNumber: '',
      month: '',
      year: '',
      birth: '',
      password: '',
    },
  }),
  methods: {
    async submit() {
      const validation = this.$refs.form.validate();
      const message = `${this.$t('error.400.400')}`;
      if (validation) {
        this.$dialog.confirm({
          show: true,
          message: 'button.submitQ',
          btnText: 'button.confirm',
          callback: async () => {
            this.$dialog.progress(true);
            try {
              await this.$emitter('usercard.add', {
                cardNo: this.inputData.creditcardNumber,
                expYear: this.inputData.year,
                expMonth: this.inputData.month,
                idNo: this.inputData.birth,
                cardPw: this.inputData.password,
              });
              this.$dialog.messageModal(true, 'common.submitMessage');
              this.$dialog.confirm();
              setTimeout(() => {
                this.$router.push('/');
                this.$dialog.messageModal(false);
              }, 3000);
            } catch (error) {
              console.error(error);
              this.$dialog.confirm();
              this.$dialog.alert('error', this.$error.pgError(error));
            }
            this.$dialog.progress(false);
          },
        });
      } else {
        this.$dialog.alert('error', message);
      }
    },
  },
};
</script>

<style lang="scss">
.enroll-detail {
  height: 100%;
  .send-detail {
    .exp-date {
      .v-input { width: 90%; }
      .date { width: 10%; height: 85%; }
    }
  }
}
</style>
