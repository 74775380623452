<template>
  <div class="dig-msg-modal" v-show="messageModal.show === true">
    <v-row justify="center">
      <v-dialog
        v-model="messageModal.show"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <pre><p class="msg" style="font-size: 1rem;">{{$t(messageModal.message)}}</p></pre>
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DigMsaModal',
  computed: {
    ...mapGetters({
      messageModal: 'dialog/messageModal',
    }),
  },
};
</script>

<style scoped lang="scss">
</style>
