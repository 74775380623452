<template>
  <div>
    <v-form
      class="change-pw-form px-5"
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="oldPassword"
        :append-icon="showCurr ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules('common')"
        :type="showCurr ? 'text' : 'password'"
        @click:append="showCurr = !showCurr"
        :placeholder="$t('login.searchPW.currentPW')"
        autocomplete="on"
      ></v-text-field>
      <v-text-field
        v-model="newPassword"
        :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules('password')"
        :type="showNew ? 'text' : 'password'"
        @click:append="showNew = !showNew"
        :placeholder="$t('login.searchPW.newPW')"
        autocomplete="on"
      ></v-text-field>
      <v-text-field
        v-model="passwordConfirm"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules('common')"
        :type="showConfirm ? 'text' : 'password'"
        @click:append="showConfirm = !showConfirm"
        :placeholder="$t('login.searchPW.newPWconfirm')"
        autocomplete="on"
        @keyup.enter="btnAction('update')"
      >
      </v-text-field>
      <p
        class="v-messages theme--light error--text dismatch"
        v-if="passwordConfirm.length > 0 && !isMatch(newPassword, passwordConfirm)"
      >{{ $t('rules.matchedPwd') }}</p>
    </v-form>
    <BottomBtn
      :btnText="['button.cancel', 'button.update']"
      :callback="() => btnAction('update')"
      :callback2="() => btnAction('cxl')"
      :btnCnt="2"
    />
  </div>
</template>

<script>
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    changePw: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    rules() {
      return (type) => {
        const common = [(v) => !!v || this.$t('rules.require')];
        const password = [
          (v) => /(?=.*?[0-9])(?=.*?[~?!@#$%^&*_-]).{10,16}$/.test(v)
          || this.$t('rules.password'),
        ];
        if (type === 'common') return common;
        if (type === 'password') return [...common, ...password];
        return '';
      };
    },
  },
  data: () => ({
    valid: true,
    oldPassword: '',
    newPassword: '',
    passwordConfirm: '',
    showCurr: false,
    showNew: false,
    showConfirm: false,
  }),
  methods: {
    handlePassword(model, value) {
      this.items.password.map((item) => {
        if (item.model === model) {
          this[item.model] = value;
        }
        return value;
      });
    },
    isMatch(password, passwordConfirm) {
      return password === passwordConfirm;
    },
    async btnAction(type) {
      const message1 = `${this.$t('alert.changePW')}`;
      const message3 = `${this.$t('error.400.400')}`;
      if (type === 'cxl') this.$emit('update:changePw', !this.changePw);
      if (type === 'update') {
        const validation = this.$refs.form.validate();
        if (validation) {
          try {
            await this.$emitter('user.password.update', {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
            });
            this.$dialog.alert('success', message1);
            this.$emit('update:changePw', !this.changePw);
          } catch (error) {
            console.error(error);
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        } else {
          this.$dialog.alert('error', message3);
        }
      }
    },
  },
};
</script>
