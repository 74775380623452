<template>
  <div class="input-email w-100">
    <p class="search-title d-flex justify-center pb-8 font-weight-bold">
      {{$t('login.searchPW.reset')}}</p>
    <v-form
      class="search-email pa-4"
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules('password')"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :placeholder="$t('login.searchPW.newPW')"
        autocomplete="on"
      ></v-text-field>
      <v-text-field
        v-model="passwordConfirm"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules('common')"
        :type="showConfirm ? 'text' : 'password'"
        @click:append="showConfirm = !showConfirm"
        :placeholder="$t('login.searchPW.newPWconfirm')"
        autocomplete="on"
      >
      </v-text-field>
      <p
        class="v-messages theme--light error--text dismatch"
        v-if="passwordConfirm.length > 0 && !isMatch(password, passwordConfirm)"
      >{{ $t('rules.matchedPwd') }}</p>
    </v-form>
    <BottomBtn
      :btnText="['login.searchPW.change']"
      :callback="submit"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    stepThree: {
      type: Boolean,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  computed: {
    rules() {
      return (type) => {
        const common = [(v) => !!v || this.$t('rules.require')];
        const password = [
          (v) => /(?=.*?[0-9])(?=.*?[~?!@#$%^&*_-]).{10,16}$/.test(v)
          || this.$t('rules.password'),
        ];
        if (type === 'common') return common;
        if (type === 'password') return [...common, ...password];
        return '';
      };
    },
  },
  data: () => ({
    valid: true,
    password: '',
    passwordConfirm: '',
    show: false,
    showConfirm: false,
  }),
  methods: {
    async submit() {
      const message = `${this.$t('alert.changePW')}`;
      const errMessage = `${this.$t('error.400.400')}`;
      const validation = this.$refs.form.validate();
      if (validation) {
        try {
          await this.$emitter('user.password.update.token', {
            token: this.token,
            newPassword: this.password,
          });
          this.$dialog.alert('success', message);
          setTimeout(() => {
            this.$router.push('/');
          }, 3000);
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        this.$dialog.alert('error', errMessage);
      }
    },
    handlePassword(model, value) {
      this.items.password.map((item) => {
        if (item.model === model) {
          this[item.model] = value;
        }
        return value;
      });
    },
    isMatch(password, passwordConfirm) {
      return password === passwordConfirm;
    },
  },
};
</script>

<style lang="scss">
</style>
