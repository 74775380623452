<template>
  <div class="nav-wrap w-100">
    <v-bottom-navigation
      style="box-shadow:none; border-top: 1px solid rgb(216, 215, 215);"
      v-model="value"
      color="primary"
    >
      <v-btn
        v-if="!changeNav"
        @click="navigate('map')"
        value="map"
        depressed
      >
        <span>{{ $t('nav.map') }}</span>
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn
        v-else
        @click="navigate('monitoring')"
        value="monitoring"
        depressed
      >
        <span>{{ $t('nav.monitoring') }}</span>
        <v-icon>mdi-monitor-dashboard</v-icon>
      </v-btn>
      <v-btn
        @click="navigate('account')"
        value="account"
        depressed
      >
        <span>{{ $t('nav.myAccount') }}</span>
        <v-badge
          v-if="unReadCount && userInfo"
          color="red"
          :content="unReadCount"
          overlap
        >
          <v-icon>mdi-account</v-icon>
        </v-badge>
        <v-icon v-else>mdi-account</v-icon>
      </v-btn>
      <v-btn
        @click="navigate('customer')"
        value="customer"
        depressed
      >
        <span>{{ $t('nav.customer') }}</span>
        <v-icon>mdi-face-agent</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      transactionData: 'transaction/transactionData',
      unReadCount: 'notification/unReadCount',
    }),
  },
  data: () => ({
    value: 'map',
    changeNav: false,
  }),
  methods: {
    ...mapActions({
      notificationSummary: 'notification/notificationSummary',
    }),
    navigate(type) {
      if (type === 'map') {
        if (this.$route.path !== '/') this.$router.push('/');
      } else if (type === 'account') {
        if (this.$route.path !== '/account' && this.userInfo !== null) this.$router.push('/account');
        else if (this.userInfo === null) this.$router.push('/login');
      } else if (type === 'customer') {
        if (this.$route.path !== '/customer') this.$router.push('/customer');
      } else if (type === 'monitoring') {
        if (this.$route.path !== '/monitoring') this.$router.push('/monitoring');
      }
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (val === '/') {
            this.value = 'map';
          } else if (
            ['/login', '/signup', '/account', '/account/profile', '/account/point', '/account/card-enroll', '/account/paymentCard', '/account/member-enroll', '/account/member', '/account/chargingHistory', '/account/paymentHistory'].includes(val)
          ) {
            this.value = 'account';
          } else if (
            val.includes('/customer/notice') || val === '/customer/faq' || val.includes('/customer/issue') || val === '/customer'
          ) {
            this.value = 'customer';
          } else {
            this.value = null;
          }
        }
      },
    },
    'transactionData.status': {
      immediate: true,
      async handler(val) {
        if (val !== undefined) {
          this.changeNav = true;
          if (val === 'SessionCompleted') {
            await this.notificationSummary();
            this.changeNav = false;
          }
        } else this.changeNav = false;
      },
    },
  },
};
</script>

<style lang="scss">
.nav-wrap {
  background: #fff;
  position: fixed;
  bottom: 0;
  max-width: 767px;
}
</style>
