<template>
  <div class="enroll-detail membership">
    <Title
      :title="'myAccount.membershipCard.enroll'"
      :goBack="true"
      :class="'px-5'"
    />
    <v-form
      class="send-detail pa-5"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="membership-enroll-info font-weight-bold">
        {{ $t('myAccount.membershipCard.info') }}
      </div>
      <p class="address pt-5 pb-0 font-weight-bold">{{ $t('user.address') }}</p>
      <PostCode ref="postcode" :useAddress.sync="address"/>
    </v-form>
    <BottomBtn
      :btnText="['button.submit']"
      :callback="submit"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PostCode from '../../common/PostCode.vue';
import Title from '../../common/Title.vue';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  components: { PostCode, Title, BottomBtn },
  data: () => ({
    valid: true,
    address: {},
  }),
  methods: {
    ...mapActions({
      authorization: 'auth/authorization',
    }),
    async submit() {
      const message = `${this.$t('error.400.400')}`;
      const validation = this.$refs.form.validate();
      if (validation) {
        const postcodeValue = this.$refs.postcode;
        const addressData = {
          zipCode: postcodeValue.useAddress.zipCode,
          address: postcodeValue.useAddress.address,
          detailAddress: postcodeValue.useAddress.detailAddress,
        };
        this.$dialog.confirm({
          show: true,
          message: 'myAccount.membershipCard.submit',
          btnText: 'button.confirm',
          callback: async () => {
            try {
              await this.$emitter('user.update', {
                address: addressData,
              });
              this.$dialog.confirm();
              this.authorization('update');
              this.$dialog.alert('success', `${this.$t('alert.registered')}`);
              this.$dialog.confirm();
              setTimeout(() => {
                this.$router.push('/account');
              }, 1000);
            } catch (error) {
              console.error(error);
              this.$dialog.alert('error', this.$error.makeErrorMessage(error));
            }
          },
        });
      } else {
        this.$dialog.alert('error', message);
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.address = val.address;
        }
      },
    },
  },
};
</script>
