import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import i18n from '../i18n';
import routes from './routes';

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

async function beforEachCallback(to, from, next) {
  // 로그인한 계정정보
  const verified = store.getters['auth/userInfo'] !== null;

  // 허용되지 않은 경로 블럭
  if (!to.matched.length) {
    store.dispatch('dialog/alert', ['error', i18n.t('error.invalidAccess')]);

    // 이전페이지가 존재하지 않을 경우 홈으로
    if (!from.matched.length) next('/');
    else next(false); // 아닐 경우 현재 페이지 유지

  // 계정정보가 있고 로그인, 가입화면인 경우 홈으로 이동
  } else if (verified && (to.path === '/login' || to.path === '/signup')) {
    next('/');

  // 계정정보가 없고 로그인이 필요한 페이지인 경우 확인 모달
  } else if (!verified && to.meta.needLogin) {
    store.commit('dialog/loginModal', { show: true, message: 'myAccount.needLogin' });
  // 계정정보있고 서비스 준비중인 페이지인 경우 모달
  } else if (verified && to.meta.preparing) {
    store.commit('dialog/preparingMsg', {
      show: true,
      message: {
        text1: 'common.preparing',
        text2: 'common.preparingText',
        text3: 'common.thanks',
      },
    });
    next(false);
  } else {
    next();
  }

  if (to.path === '/monitoring') {
    if (to.path !== from.path && to.path === '/') {
      next('/monitoring');
    }
  }
}

// 페이지 이동전 감지
router.beforeEach(async (to, from, next) => {
  // 앱 초기화가 되지 않았을 때
  if (!store.getters['auth/initialized']) {
    // 앱 초기화 완료 감지
    const unwatch = await store.watch((_state, getters) => getters['auth/initialized'], async () => {
      await beforEachCallback(to, from, next);
      // 감지 해제
      await unwatch();
    });
  } else {
    await beforEachCallback(to, from, next);
  }
});

Vue.use(VueRouter);

export default router;
