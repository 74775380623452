<template>
  <div>
    <Title :title="'login.signup'" :isStyle="true" :goBack="true" class="px-5"/>
    <div v-if="!inputEmailCode && !inputDetail && !parameterValue" class="px-5">
      <div class="signup-email mt-10 text-h6 font-weight-bold">
        <pre><p>{{ $t('login.signUp.inputEmail') }}</p></pre>
      </div>
    </div>
    <div
      v-if="!inputEmailCode && !inputDetail && !parameterValue"
      class="email-wrap font-weight-bold px-5"
    >
      <div class="form mt-10">
        <v-text-field
          id="click-box"
          placeholder="ex) zyuutennkun@co.jp"
          dense
          v-model="email"
          :rules="rules"
          @keyup.enter="goToNext"
        ></v-text-field>
        <v-alert
          v-model="showError"
          type="error"
          dense
          outlined
          class="email-error"
        >{{ $t('alert.emailError') }}</v-alert>
      </div>
    </div>
    <BottomBtn
      v-if="!inputEmailCode && !inputDetail"
      :btnCnt="1"
      :btnText="['button.next']"
      :callback="goToNext"
      :isDisabled="email === ''"
    />
    <SignUpEmailCode
      v-if="inputEmailCode && !parameterValue"
      :inputEmailCode.sync="inputEmailCode"
      :inputDetail.sync="inputDetail"
      :companyInfo="companyInfo"
    />
    <SignUpDetail
      v-if="inputDetail || parameterValue === 'social'"
      :inputEmailCode.sync="inputDetail"
      :inputDetail.sync="inputDetail"
      :parameterValue="parameterValue"
      :companyInfo="companyInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Title from '../common/Title.vue';
import BottomBtn from '../common/BottomBtn.vue';
import SignUpEmailCode from './emailEnroll/SignUpEmailCode.vue';
import SignUpDetail from './emailEnroll/SignUpDetail.vue';

export default {
  components: {
    Title, BottomBtn, SignUpEmailCode, SignUpDetail,
  },
  data: () => ({
    inputEmailCode: false,
    inputDetail: false,
    showError: false,
    email: '',
    parameterValue: '',
  }),
  mounted() {
    this.parameterValue = this.$route.query.type;
  },
  computed: ({
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
    rules() {
      return [(v) => /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/.test(v) || this.$t('rules.email')];
    },
  }),
  methods: {
    ...mapMutations({
      userEmailUpdate: 'signUpUser/userEmail',
    }),
    isValidEmail(email) {
      return /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+.[A-Za-z0-9-]/.test(email);
    },
    isEmpty(email) {
      if (email.length !== 0) return true;
      return false;
    },
    async goToNext() {
      const emailCheck = this.isValidEmail(this.email);
      const lengthCheck = this.isEmpty(this.email);
      if (emailCheck && lengthCheck) {
        this.userEmailUpdate(this.email);
        try {
          await this.$emitter('user.email.auth.send', {
            action: 'signup',
            email: this.email,
          });
          this.inputEmailCode = true;
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          this.email = '';
        }
      } else {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss">
.email-wrap {
  font-size: 20px;
  .form {
    width: 90%;
    .v-input { font-size: 15px;}
    .v-label { color: #ccc; }
    .email-error {
      font-size: 10px;
    }
  }
}
</style>
