<template>
  <SignUpSection/>
</template>

<script>
import SignUpSection from '../../components/signUp/SignUpSection.vue';

export default {
  name: 'SignUp',
  components: { SignUpSection },
};
</script>
