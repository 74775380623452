<!-- 회원카드 신청 되어있을 경우 보이는 페이지 -->
<template>
  <div class="detail-wrap h-100 px-5">
    <Title
      :title="'myAccount.membershipCard.title'"
      :goBack="true"
    />
    <div class="card-icon d-flex justify-center align-center primary--text">
      <v-icon size="200" style="cursor: auto;">mdi-card-account-details-outline</v-icon>
    </div>
    <div v-if="membershipItems.length > 0">
      <v-list v-for="(item, idx) in membershipItems" :key="idx">
        <v-list-item
          v-for="(value, key) in item" :key="key">
          <v-list-item-title class="font-weight-bold">
            {{ $t(`myAccount.membershipCard.${key}`) }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-right font-weight-bold black--text"
          >
            {{ value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">
            {{ $t('myAccount.membershipCard.status') }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-right font-weight-bold black--text">
            {{ $t('myAccount.membershipCard.available') }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </div>
    <div
      v-else
      class="card-detail d-flex justify-space-around font-weight-bold my-5"
    >
      <p>{{ $t('myAccount.membershipCard.issued') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    membershipItems: [],
  }),
  methods: {
    async checkIdtag() {
      const res = await this.$emitter('idtag.get', {});
      this.membership = res.item.idTag;
      if (this.membership !== null) {
        this.membershipItems.push({
          cardNumber: this.membership.idTag.replace(/(.{4})(?!$)/g, '$1-'),
          cardEnrollDate: timeConvert.makeDate(this.membership.created),
        });
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      async handler(val) {
        if (val != null) {
          await this.checkIdtag();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/style.scss';
.detail-wrap {
  .card-icon { height: 40vh; font-size: 200px;}
}
</style>
