<template>
  <div>
    <Title :title="'login.login'" :isStyle="true" class="px-5"/>
    <div class="login-box d-flex flex-column px-5">
      <v-text-field id="click_box"
        v-model="email"
        prepend-icon="mdi-account"
        :label="$t('login.e-mail')"
        :rules="rules('email')"
        @keyup.enter="login"
      ></v-text-field>
      <v-text-field
        v-model="password"
        prepend-icon="mdi-lock-outline"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :label="$t('login.password')"
        :rules="rules('common')"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        autocomplete="on"
        @keyup.enter="login"
      ></v-text-field>
    </div>
    <LoginBtns
      v-if="!openSearch"
      :email="email"
      :password="password"
      :login="login"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Title from '../../common/Title.vue';
import LoginBtns from './LoginBtns.vue';

export default {
  components: { Title, LoginBtns },
  props: {
    openSearch: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
    rules() {
      return (type) => {
        const common = [(v) => !!v || this.$t('rules.require')];
        const email = [
          (v) => /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/.test(v) || this.$t('rules.email'),
        ];
        if (type === 'common') return common;
        if (type === 'email') return [...common, ...email];
        return '';
      };
    },
  },
  data: () => ({
    valid: true,
    email: '',
    password: '',
    show: false,
  }),
  methods: {
    ...mapActions({
      authorization: 'auth/authorization',
    }),
    async login() {
      try {
        this.$dialog.progress(true);
        const { email, password } = this;
        // 토큰 요청
        const { token } = await this.$emitter('user.token.get', {
          email,
          password,
        });
        // 토큰 브라우저 스토리지 저장
        localStorage.setItem(`BLITZ.${this.companyInfo.code}.token`, token);
        this.authorization();
        this.$dialog.progress(false);
        this.$router.push('/');
      } catch (error) {
        this.$dialog.progress(false);
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
};
</script>

<style lang="scss">
</style>
