<template>
  <div class="language-wrap">
    <v-list class="d-flex align-center">
      <v-icon color="primary">mdi-web</v-icon>
      <v-list-item
        class="language-list"
        v-for="item in languageList"
        :key="item.name"
        :ripple="false"
        variant="plain"
        :class="{ 'selected-lang': selectedLang === item.value }"
        @click="selectLanguage(item.value)"
      >
        <v-list-item-title v-text="item.name"></v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  data: () => ({
    languageList: [
      { name: '한국어', value: 'Ko' },
      { name: '日本語', value: 'Jp' },
      { name: 'English', value: 'Eng' },
    ],
    selectedLang: 'Ko',
  }),
  mounted() {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      this.selectedLang = storedLang;
    }
  },
  methods: {
    selectLanguage(value) {
      this.selectedLang = value;
      if (value === 'Ko') {
        this.$i18n.locale = 'ko';
      } else if (value === 'Eng') {
        this.$i18n.locale = 'en';
      } else if (value === 'Jp') {
        this.$i18n.locale = 'jp';
      }
      localStorage.setItem('selectedLang', value);
    },
  },
};
</script>

<style lang="scss">
.language-wrap {
  .selected-lang { font-weight: bold; }
}
</style>
