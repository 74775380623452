<template>
  <div class="comments-wrap pa-4">
    <p class="comments-count pb-4">댓글 {{ commentsCnt }}개</p>
    <div class="comments-box d-flex" v-if="userInfo">
      <v-text-field
        :placeholder="$t('customer.notice.comment')"
        dense
        v-model="contentText"
        @keyup.enter="submitComment"
      ></v-text-field>
      <v-btn
        color="primary"
        class="submit-btn ml-2"
        small
        depressed
        @click="submitComment"
      >
        {{$t('button.submit')}}
      </v-btn>
    </div>
    <div class="comments-list w-100 d-flex align-center">
      <div class="comments-list-item w-100 pa-0">
        <v-list-item
          class="comments w-100 d-flex flex-column align-start pa-0"
          v-for="(item, idx) in comments"
          :key="idx"
        >
          <div class="comment-writer w-100 d-flex justify-space-between align-center">
            <div>
              <v-list-item-content class="comment-item align-center">
                <v-list-item-subtitle
                  v-if="item.creatorStaffInfo"
                  class="writer"
                >
                {{
                  `${item.creatorStaffInfo.name} | ${timeConvert.displayedAt(item.updated, $i18n)}`
                }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="item.creatorUserInfo"
                  class="writer"
                >
                  {{
                    `${item.creatorUserInfo.name} | ${timeConvert.displayedAt(item.updated, $i18n)}`
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else
                  class="writer"
                >
                  {{
                    `${$t('common.withdrawal')} | ${timeConvert.displayedAt(item.updated, $i18n)}`
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>
            <div
              class="more-btn-wrap"
              v-if="userInfo &&
              item.creatorUserInfo &&
              item.creatorUserInfo.email === userInfo.email"
            >
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                  >
                    <v-icon class="more-btn pr-13">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <div class="more-detail d-flex flex-column">
                  <div class="update-btn">
                    <v-btn
                      text
                      depressed
                      color="info"
                      @click="updateMode(item, idx)"
                    >{{$t('button.update')}}</v-btn>
                  </div>
                  <div>
                    <v-btn
                      class="delete-btn"
                      depressed
                      text
                      color="error"
                      @click="deleteComment(item)"
                    >
                      {{$t('button.delete')}}
                    </v-btn>
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="update-wrap w-100">
            <div
              v-if="selectedComment === idx && updateBtn"
              class="update d-flex"
            >
              <v-text-field
                class="update-comment d-flex"
                dense
                v-model="item.content"
                @keyup.enter="updateComment(item)"
              >
              </v-text-field>
              <v-btn
                text
                depressed
                color="primary"
                @click="updateComment(item)"
              >{{ $t('button.submit') }}</v-btn>
            </div>
            <v-list-item-title
              v-else
              class="comment-title pr-5"
              v-model="item.content"
            >{{ item.content }}</v-list-item-title>
          </div>
        </v-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import timeConvert from '@/util/time';

export default {
  props: {
    selectedNoticeId: {
      type: Number,
      required: true,
    },
    userInfo: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    timeConvert,
    comments: [],
    commentsCnt: 0,
    contentText: '',
    updateBtn: false,
    selectedComment: -1,
    updateContent: '',
  }),
  methods: {
    /* eslint no-underscore-dangle: 0 */
    async getCommentList() {
      try {
        const filter = [
          {
            where: 'and',
            condition: 'eq',
            key: 'postNo',
            value: this.selectedNoticeId,
          },
        ];
        const summary = await this.$emitter('comment.summary.get', { filters: filter });
        this.commentsCnt = summary.data.total;
        const res = await this.$emitter('comment.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['created'],
          sortDesc: [true],
          filters: filter,
        });
        this.comments = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async submitComment() {
      const message = `${this.$t('error.400.400')}`;
      if (this.contentText.length > 0) {
        this.$dialog.confirm({
          show: true,
          message: 'button.submitQ',
          btnText: 'button.confirm',
          callback: async () => {
            try {
              await this.$emitter('comment.add', {
                postNo: this.selectedNoticeId,
                content: this.contentText,
              });
              this.$dialog.confirm();
              this.contentText = '';
              this.$dialog.alert('success', `${this.$t('alert.registered')}`);
              this.getCommentList();
            } catch (error) {
              console.error(error);
              this.$dialog.alert('error', this.$error.makeErrorMessage(error));
            }
          },
        });
      } else this.$dialog.alert('error', message);
    },
    async updateMode(item, idx) {
      this.selectedComment = idx;
      this.updateBtn = !this.updateBtn;
    },
    async updateComment(item) {
      this.$dialog.confirm({
        show: true,
        message: 'button.modifyQ',
        btnText: 'button.confirm',
        callback: async () => {
          try {
            await this.$emitter('comment.update', {
              _id: item._id,
              content: item.content,
            });
            this.$dialog.confirm();
            this.updateBtn = !this.updateBtn;
            this.$dialog.alert('success', `${this.$t('alert.edited')}`);
            this.getCommentList();
          } catch (error) {
            console.error(error);
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        },
      });
    },
    async deleteComment(item) {
      this.$dialog.confirm({
        show: true,
        message: 'button.deleteQ',
        btnText: 'button.delete',
        callback: async () => {
          try {
            await this.$emitter('comment.delete', {
              _id: item._id,
            });
            this.$dialog.confirm();
            this.$dialog.alert('success', `${this.$t('alert.deleted')}`);
            this.getCommentList();
          } catch (error) {
            console.error(error);
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        },
      });
    },
  },
  watch: {
    selectedNoticeId: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getCommentList();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.comments-wrap {
  .comments-list {
    .comments-list-item {
      .comments {
        min-height: 15px !important;
        .comment-writer {
          .comment-item {
            flex-wrap: inherit !important;
            .writer { white-space:inherit; font-size: 0.75rem; }
          }
          .more-btn-wrap {
            button::before {display: none;}
            .more-btn { font-size: 1.2rem !important;}
            .update-btn, .delete-btn {font-size: 0.75rem;}
          }
        }
        .update-wrap {
          .update-comment {
            input { padding-bottom: 6px !important; font-size: 0.75rem;}
          }
          .comment-title { white-space: inherit; font-size: 0.75rem; }
        }
      }
    }
  }
}
</style>
