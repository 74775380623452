<template>
  <div @click="$router.push('/account/chargingHistory')">
    <div class="d-flex align-center justify-space-between pt-4">
      <p class="font-weight-bold">
        {{ `${convertMonth(timeConvert.getMonth())} ${$t('myAccount.chargingHistory.title')}` }}
      </p>
      <p
        class="text-subtitle-2 detail-view"
        @click="$router.push('/account/chargingHistory')"
      >{{ $t('button.detail') }}</p>
    </div>
    <div class="charging-box rounded-lg mt-2 d-flex flex-column justify-center">
      <div class="d-flex align-center justify-space-between px-5">
        <p>{{ $t('myAccount.chargingHistory.totalMeter') }}</p>
        <p class="pr-3 font-weight-bold">
          {{ summary.total !== 0 ? summary.totalChargedMeter / 1000 : 0}} kWh
        </p>
      </div>
      <div class="d-flex align-center justify-space-between px-5">
        <p>{{ $t('myAccount.chargingHistory.totalPrice') }}</p>
        <p class="pr-3 font-weight-bold">
          {{ currency }}
          {{ summary.total !== 0 ? summary.totalChargedPrice.toLocaleString() : 0 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';
import { convertMonth } from '@/util/calendar';

export default {
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    timeConvert,
    convertMonth,
    currency: '',
    summary: {
      total: 0,
      totalChargedMeter: 0,
      totalChargedPrice: 0,
    },
    chList: [],
    filters: [
      {
        where: 'and',
        condition: 'gte',
        key: 'sessionStartDate',
        value: `${timeConvert.getMonth2()}-01 00:00:00`,
      },
      {
        where: 'and',
        condition: 'lte',
        key: 'sessionStartDate',
        value: `${timeConvert.getLastDay()} 23:59:59`,
      },
      {
        where: 'and',
        condition: 'eq',
        key: 'status',
        value: 'SessionCompleted',
      },
    ],
  }),
  methods: {
    async chargingHistoryList(filter) {
      this.$dialog.progress(true);
      try {
        const response = await this.$emitter('transaction.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: filter,
          sortBy: ['sessionStartDate'],
          sortDesc: [true],
        });
        this.chList = response.items;
        this.transactionTotal(filter);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async transactionTotal(filter) {
      const res = await this.$emitter('transaction.summary.get', {
        filters: filter,
      });
      this.summary = res.data;
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.chargingHistoryList(this.filters);
        }
      },
    },
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          switch (val.currencyUnit) {
            case 'krw': this.currency = this.$t('common.krw'); break;
            case 'usd': this.currency = this.$t('common.usd'); break;
            case 'jpy': this.currency = this.$t('common.jpy'); break;
            default: break;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.charging-box {
  height: 100px;
  border: 1px solid #ccc;
}
</style>
