<template>
  <v-dialog
    v-model="show"
    transition="slide-x-reverse-transition"
    fullscreen
    persistent
  >
    <v-card class="remote-wrap">
      <div class="timeout"></div>
      <div class="connector-out">
        <img src="@/assets/images/connector/unplug.gif" alt="" class="connect-icon">
        <pre><p>{{$t('map.remoteStart.connectorOut')}}</p></pre>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.$dialog.confirm();
          this.$dialog.progress(false);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.remote-wrap {
  height: 100%;
  overflow-x: hidden;
  .connect-icon {
    width: 200px;
  }
  .timeout {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
  .connector-out {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {font-weight: bold; font-size: 1.2rem;}
  }
}
</style>
