<template>
  <div class="charging-wrap h-100" v-if="transactionData !== null">
    <div v-if="transactionData.status === 'Charging'">
      <div class="d-flex align-center justify-center align-baseline charging-title">
        <div class="d-flex align-center justify-center flex-column pt-7">
          <p class="text-h4">{{$t('monitoring.charging')}}</p>
          <p class="text-subtitle-1 grey--text">
            {{ transactionData.chargepoint.cpCode + '-' +
              transactionData.connectorId }}
          </p>
        </div>
        <v-btn
          class="refresh-btn pa-0 ml-2"
          plain
          depressed
          @click="refresh"
          :disabled="blockRefresh"
          min-width="0"
          :ripple="false"
        >
          <v-icon
            :class="{ 'rotate-icon' : blockRefresh }"
          >mdi-cached</v-icon>
        </v-btn>
      </div>
      <v-divider color="darkGrey" class="mx-5 my-0"></v-divider>

      <div
        class="d-flex flex-column align-center charging-data"
        style="height: calc(100vh - 250px); overflow-y: scroll; justify-content: space-evenly;"
      >
        <div class="py-4">
          <v-progress-circular
            :rotate="-90"
            :size="circularSize"
            :width="20"
            :value="progressValue"
            color="primary"
          >
            <div class="d-flex flex-column justify-center align-center">
              <div color="primary" class="text-h2">
                <font-awesome-icon icon="fa-solid fa-bolt"/>
              </div>
              <div class="black--text text-h4">
                <p
                  v-if="transactionData.chargepoint.model.type === 'DC' && transactionData.meter"
                  class="soc text-center py-4"
                >
                  {{
                    transactionData.meter.soc ? transactionData.meter.soc : 0
                  }}%
                </p>
                <p
                  v-else
                  class="soc text-center"
                > 0% </p>
              </div>
              <div class="black--text text-subtitle-2">잔여시간 0 분</div>
            </div>
          </v-progress-circular>
        </div>
        <div class="charging-info">
          <div class="d-flex justify-center align-center text-center pb-3">
            <div class="pr-1">
              <p class="font-weight-bold text-subtitle-1">
                {{$t('common.krw')}}
                {{ (transactionData.chargedPrice).toLocaleString() }}
              </p>
              <p class="grey--text text-subtitle-2">{{ $t('monitoring.price') }}</p>
            </div>
            <v-divider vertical color="grey" style="height: 50px;" class="mx-3"></v-divider>
            <div  class="px-1">
              <p class="font-weight-bold text-subtitle-1">
                {{
                transactionData.chargedMeter == null || Number.isNaN(transactionData.chargedMeter)
                ? 0
                : (transactionData.chargedMeter / 1000).toLocaleString()
                }}kWh
              </p>
              <p class="grey--text text-subtitle-2">{{ $t('monitoring.capacity') }}</p>
            </div>
            <v-divider vertical color="grey" style="height: 50px;" class="mx-3"></v-divider>
            <div class="pl-1">
              <p class="font-weight-bold text-subtitle-1">
                {{
                transactionData.meter === undefined ||
                transactionData.meter === null
                ? 0
                : (transactionData.meter.voltage * transactionData.meter.ampere / 1000).toFixed(1)
              }} kW
              </p>
              <p class="grey--text text-subtitle-2">{{ $t('monitoring.power') }}</p>
            </div>
          </div>
          <div class="d-flex justify-center align-center text-center">
            <div>
              <p class="font-weight-bold text-subtitle-1">{{ formattedTime() }}</p>
              <p class="grey--text text-subtitle-2">{{ $t('monitoring.time') }}</p>
            </div>
          </div>
        </div>
      </div>
      <BottomBtn
        :btnText="['button.stop']"
        :callback="stopRemote"
        :btnCnt="1"
        :btnInfo="true"
      />
    </div>
    <RetmoeStop
      v-else-if="transactionData.status === 'PaymentCompleted'
        || transactionData.status === 'ChargeFinished'"
      :show="transactionData.status === 'PaymentCompleted'
        || transactionData.status === 'ChargeFinished'"
    />
    <RetmoeStart
      v-else-if="transactionData.status === 'Accepted'"
      :show="transactionData.status === 'Accepted'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import timeConvert from '@/util/time';
import RetmoeStop from '../remoteStop/RemoteStop.vue';
import RetmoeStart from '../../home/remoteStart/RemoteStart.vue';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { RetmoeStop, RetmoeStart, BottomBtn },
  computed: {
    ...mapGetters({
      transactionData: 'transaction/transactionData',
    }),
    circularSize() {
      const width = window.innerWidth;
      if (width >= 450) return 300;
      if (width >= 360 && width < 450) return 250;
      return 200;
    },
    // progressValue() {
    //   if (this.transactionData.meter === undefined) return 0;
    //   return Number(this.transactionData.meter.soc);
    // },
  },
  mounted() {
  },
  data: () => ({
    timeConvert,
    chargeFinished: false,
    interval: null,
    chargingTime: null,
    blockRefresh: false,
    progressValue: '',
  }),
  methods: {
    ...mapMutations({
      transactionDataChange: 'transaction/transactionData',
    }),
    ...mapActions({
      transactionCheck: 'transaction/transactionCheck',
    }),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    refresh() {
      this.transactionCheck();
      this.blockRefresh = true;
      setTimeout(() => {
        this.blockRefresh = false;
      }, 15000);
    },
    stopRemote() {
      this.$dialog.confirm({
        show: true,
        message: 'monitoring.stop',
        btnText: 'button.confirm',
        callback: async () => {
          this.$dialog.progress(true);
          try {
            if (this.transactionData.status === 'Charging') {
              await this.$emitter('transaction.stop', {});
              await this.transactionCheck();
            }
          } catch (error) {
            console.error(error);
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        },
      });
    },
    formattedTime() {
      if (this.transactionData.chargingStartDate == null) {
        return '-- : -- : --';
      }
      return this.chargingTime;
    },
    updateChargingTime() {
      if (this.transactionData != null) {
        if (this.transactionData.chargingStartDate != null) {
          this.chargingTime = timeConvert.makeCalcTime(
            this.transactionData.chargingStartDate, timeConvert.getTime(),
          );
        }
      } else clearInterval(this.interval);
    },
  },
  watch: {
    'transactionData.status': {
      immediate: true,
      handler(val) {
        if (val != null) {
          if (val === 'Charging') {
            this.interval = setInterval(this.updateChargingTime, 1000);
          } else if (val === 'SessionCompleted' || val === 'PaymentRequired') {
            this.$router.push('/');
          }
        } else {
          this.$router.push('/');
        }
      },
    },
    'transactionData.meter': {
      handler(val) {
        if (val.soc != null) this.progressValue = val.soc;
        else this.progressValue = 0;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.charging-wrap {
  position: static;
  .refresh-btn {
    .rotate-icon {
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg);}
      100% { transform: rotate(-360deg);}
    }
  }
}
</style>
