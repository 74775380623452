<template>
  <div class="notice-detail-wrap" v-if="selectedNotice !== null">
    <Title
      :title="'customer.notice.title'"
      :goBack="true"
      class="px-5"
    />
    <v-card class="detail">
      <v-card-title
      class="notice-title pb-0"
      v-text="selectedNotice.title"
      ></v-card-title>
      <v-card-title class="notice-date py-2 px-4 lightGrey2--text"
        v-text="timeConvert.makeMin(selectedNotice.created)"
      >
      </v-card-title>
      <v-divider class="my-2 mx-0 pa-0 grey lighten-1"></v-divider>
      <v-card-actions>
        <v-btn
          v-if="fileLength > 0"
          outlined
          depressed
          rounded
          text
          @click="fileDownload"
        >
          {{$t('customer.notice.download')}} ({{ fileLength }})
        </v-btn>
      </v-card-actions>
      <v-card-text class="notice-body mt-2">
        <Viewer :initialValue="selectedNotice.content" />
      </v-card-text>
      <v-divider></v-divider>
      <NoticeComments
        :selectedNoticeId="selectedNoticeId"
        :userInfo="userInfo"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';
// eslint-disable-next-line import/no-cycle
import fileConverter from '@/util/file';
import { Viewer } from '@toast-ui/vue-editor';
import Title from '../../common/Title.vue';
import NoticeComments from './NoticeComments.vue';

export default {
  components: { Title, Viewer, NoticeComments },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    selectedNotice: null,
    timeConvert,
    fileConverter,
    fileLength: 0,
    selectedNoticeId: 0,
  }),
  methods: {
    async noticeDetail(item) {
      this.$dialog.progress(true);
      try {
        const res = await this.$emitter('post.get', {
          no: item,
        });
        this.selectedNotice = res.item;
        this.selectedNoticeId = res.item.no;
        this.fileLength = res.item.files.length;
        this.$dialog.progress(false);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async fileDownload() {
      try {
        this.selectedNotice.files.map(async (file) => {
          const res = await this.$emitter('file.read', {
            no: file.no,
          });
          await fileConverter.writeFileFromPayload(res.item);
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          const no = Number(this.$route.params.id);
          this.noticeDetail(no);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.notice-detail-wrap {
  position: absolute !important;
  overflow-y: scroll;
  .back { height: 10%; cursor: pointer; }
  .detail {
    height: 90%;
    box-shadow: none !important;
    .notice-date { font-size: 13px; }
    .notice-body { white-space: pre-wrap; }
  }
}
</style>
