<template>
  <div>
    <div
      v-if="btnCnt === 1"
      class="w-100 text-center"
      :class="isStyle ? 'btn-wrap btn-position' : 'btn-wrap'"
    >
      <div v-if="btnInfo">
        <v-divider color="grey" class="mx-5 my-0"></v-divider>
        <p class="w-100 d-flex justify-center text-subtitle-2 py-2">
          충전을 중지하시려면 아래 '충전중지' 버튼을 눌러주세요
        </p>
      </div>
      <v-btn
        class="rounded-lg"
        depressed
        large
        color="primary"
        @click="callback"
        :disabled="isDisabled"
      >
        <v-icon v-if="mdiIcon">{{ `mdi-${mdiIcon}` }}</v-icon>
        {{ $t(btnText[0]) }}
      </v-btn>
    </div>
    <div v-else class="btn-double-wrap w-100 d-flex justify-center">
      <v-btn
        outlined
        depressed
        class="rounded-lg mr-3"
        large
        color="primary"
        @click="callback2"
        :disabled="isDisabled"
      >
        <v-icon v-if="mdiIcon">{{ `mdi-${mdiIcon}` }}</v-icon>
        {{ $t(btnText[0]) }}
      </v-btn>
      <v-btn
        class="rounded-lg"
        depressed
        large
        color="primary"
        @click="callback"
        :disabled="isDisabled"
      >
        <v-icon v-if="mdiIcon">{{ `mdi-${mdiIcon}` }}</v-icon>
        {{ $t(btnText[1]) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnCnt: {
      type: Number,
      required: true,
    },
    mdiIcon: {
      type: String,
      required: false,
    },
    btnText: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    callback2: {
      type: Function,
      default: () => {},
    },
    isStyle: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    btnInfo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.btn-wrap {
  max-width: 767px;
  position: fixed;
  bottom: 70px;
  background-color: #fff;
  button { width: 90%;}
}
.btn-position { left: 0;}
.btn-double-wrap {
  max-width: 767px;
  position: fixed;
  bottom: 70px;
  button {width: 45%;}
}
</style>
