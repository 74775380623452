<template>
  <v-dialog
    class="map-search-wrap w-100"
    v-model="searchOpen"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <v-card class="search-card w-100 h-100 rounded-0">
      <div class="pa-3">
        <v-btn
          @click="closeCard"
          text
          depressed
          min-width="32"
          class="pa-0"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-tabs
          v-model="search"
          background-color="transparent"
          grow
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            @click="setSearchType(tab.value)"
          >{{ $t(tab.title) }}</v-tab>
          <v-tabs-items v-model="search">
            <v-tab-item
              v-for="(tab, idx) in tabs"
              :key="idx"
            >
            <div class="search d-flex flex-column justify-start py-3">
              <div
                v-if="tab.value === 'evseNumber'"
                class="search-evse d-flex align-center rounded-xl"
              >
                <font-awesome-icon icon="fa-solid fa-bolt" class="search-icon px-2" />
                <v-text-field
                  class="search-input"
                  type="text"
                  flat
                  dense
                  solo
                  :placeholder="$t('map.searchBox.inputEvse')"
                  v-model="inputEvse"
                  @keyup.enter="searchPlace(searchType)"
                  autofocus
                >
                </v-text-field>
              </div>
              <div
                v-else
                class="search-addr d-flex align-center rounded-xl"
              >
                <font-awesome-icon icon="fa-solid fa-location-dot" class="search-icon px-2" />
                <GmapAutocomplete
                  class="search-input input-addr w-100"
                  type="text"
                  ref="inputAddr"
                  :placeholder="$t('map.searchBox.inputAddr')"
                  @place_changed="onPlaceChanged"
                  @keyup.enter="searchPlace(searchType)"
                >
                </GmapAutocomplete>
              </div>
            </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
      <div class="search-btn-wrap w-100 d-flex justify-center">
        <v-btn
          class="rounded-lg"
          depressed
          color="primary"
          large
          @click="searchPlace(searchType)"
          :disabled="isButtonDisabled"
        >{{ $t('button.searchBtn') }}</v-btn>
      </div>
    </v-card>
    <div class="close" @click="closeCard"></div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    searchOpen: {
      type: Boolean,
      required: true,
    },
    markers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    isButtonDisabled() {
      if (this.searchType === 'evseNumber') {
        return this.inputEvse.length === 0;
      } if (this.searchType === 'address') {
        return this.placeName.length === 0;
      }
      return false;
    },
  },
  data: () => ({
    search: null,
    searchType: 'evseNumber',
    placeName: '',
    inputEvse: '',
    tabs: [
      { title: 'map.searchBox.searchEvse', value: 'evseNumber' },
      { title: 'map.searchBox.searchAddr', value: 'address' },
    ],
  }),
  methods: {
    closeCard() {
      this.$emit('update:searchOpen', !this.searchOpen);
      this.placeName = '';
      this.inputEvse = '';
    },
    setSearchType(type) {
      this.searchType = type;
      if (type === 'address') {
        setTimeout(() => {
          if (document.querySelector('.input-addr')) {
            document.querySelector('.input-addr').focus();
          }
        }, 300);
      }
    },
    searchPlace(type) {
      if (type === 'evseNumber') this.searchEvse();
      else if (type === 'address') this.searchLocation();
    },
    searchEvse() {
      if (this.inputEvse !== '') {
        const result = this.markers.filter((marker) => {
          const filterItem = marker.evse
            .filter(
              (evse) => evse.evseNumber != null && evse.evseNumber.indexOf(this.inputEvse) !== -1,
            );
          return filterItem.length > 0;
        });
        if (result.length > 0) {
          this.$emit('searchEvseEvent', result[0], this.inputEvse);
          this.$emit('update:searchOpen', !this.searchOpen);
          this.inputEvse = '';
        } else {
          const message = `${this.$t('alert.noSearch')}`;
          this.$dialog.alert('error', message);
        }
      } else {
        const search = `${this.$t('alert.search')}`;
        this.$dialog.alert('warning', search);
      }
    },
    searchLocation() {
      const geocoder = new window.google.maps.Geocoder();
      if (this.placeName !== undefined) {
        geocoder.geocode({ address: this.placeName }, (results, status) => {
          if (status === 'OK' && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            this.center = { lat: lat(), lng: lng() };
            this.$emit('centerEvent', this.center);
            this.$emit('update:searchOpen', !this.searchOpen);
            this.placeName = '';
          }
        });
      } else {
        const search = `${this.$t('alert.search')}`;
        this.$dialog.alert('warning', search);
      }
    },
    onPlaceChanged() {
      const place = this.$refs.inputAddr[0].$refs.input.value;
      if (place) {
        this.placeName = place;
      }
    },
  },
  watch: {
  },
};
</script>

<style lang="scss">
  .search-card {
    z-index: 999;
    box-shadow: none !important;
    .select-box { height: 45%;}
    .search {
      height: 35%;
      .search-addr, .search-evse {
        border: 1px solid #333;
        p { height: 15%; }
        input:focus {outline: none;}
        input::placeholder {color: #bbbbbb;}
        .search-input {
          height: 35px;
          .v-input__control {min-height: 30px;}
          .v-input__slot {padding: 0; width: 90%;}
        }
      }
    }
    .search-btn-wrap {
      max-width: 767px;
      position: fixed;
      bottom: 10px;
      button {width: 90%;}
    }
  }
  .close {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
