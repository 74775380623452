<template>
  <v-dialog
    v-if="selectedEvse !== null"
    v-model="openPriceTable"
    width="600"
    @click:outside="init()"
  >
    <v-card>
      <v-card-title>{{ $t('map.priceTable.energy') }}</v-card-title>
      <v-simple-table style="padding-bottom: 35px;">
        <template>
          <thead>
            <tr width="100%">
              <th class="text-center" width="25%"></th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.spring') }}<br>
              </th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.summer') }}<br>
              </th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.winter') }}<br>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(loadType, loadIndex) in loadTypes"
              :key="loadIndex"
            >
              <td class="text-center">{{ $t(loadType) }}</td>
              <td
                class="text-center"
                v-for="(season, seasonIndex) in priceArray"
                :key="seasonIndex"
              >
                <span
                  v-for="(price, priceIndex) in season[loadIndex].prices" :key="priceIndex"
                >
                  <p v-if="memberPrice != null && memberPrice.method === 'rate'">
                    {{ (+(price) * +(memberPrice.value / 100)).toFixed(1) }}
                  </p>
                  <p v-else-if="memberPrice != null && memberPrice.method === 'amount'">
                    {{ (+(price) + +(memberPrice.value)).toFixed(1) }}
                  </p>
                  <p v-else-if="memberPrice != null && memberPrice.method === 'fix'">
                    {{ Number.isInteger(memberPrice.value)
                      ? +(memberPrice.value).toFixed(1)
                      : +(memberPrice.value) }}
                  </p>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-title>{{ $t('map.priceTable.seasonTime') }}</v-card-title>
      <v-simple-table style="padding-bottom: 35px;">
        <template>
          <thead>
            <tr width="100%">
              <th class="text-center" width="25%"></th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.spring') }}<br>
                ({{ seasonMonth.spring.join(', ') }})
              </th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.summer') }}<br>
                ({{ seasonMonth.summer.join(', ') }})
              </th>
              <th class="text-center" width="25%">
                {{ $t('map.priceTable.winter') }}<br>
                ({{ seasonMonth.winter.join(', ') }})
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(loadType, index) in loadTypes"
              :key="index"
            >
              <td class="text-center">{{ $t(loadType) }}</td>
              <td class="text-center" v-html="getTimeRange(0, index)"></td>
              <td class="text-center" v-html="getTimeRange(1, index)"></td>
              <td class="text-center" v-html="getTimeRange(2, index)"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openPriceTable: {
      tupe: Boolean,
      required: true,
    },
    selectedEvse: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadTypes: ['map.priceTable.light', 'map.priceTable.middle', 'map.priceTable.maximum'],
    priceArray: [],
    seasonMonth: { spring: [], summer: [], winter: [] },
    memberPrice: {},
  }),
  methods: {
    init() {
      this.$emit('update:openPriceTable', false);
    },
    getSeasons() {
      if (this.selectedEvse.priceTable && Object.keys(this.selectedEvse.priceTable).length > 0) {
        this.seasonMonth = { spring: [], summer: [], winter: [] };
        this.selectedEvse.priceTable.forEach((item, index) => {
          switch (item.season) {
            case 0: this.seasonMonth.spring.push(index + 1); break;
            case 1: this.seasonMonth.summer.push(index + 1); break;
            case 2: this.seasonMonth.winter.push(index + 1); break;
            default: break;
          }
        });
      }
    },
    prices() {
      if (this.selectedEvse && Object.keys(this.selectedEvse).length > 0) {
        const result = {};
        this.selectedEvse.priceTable.forEach(({ season, times }) => {
          result[season] = {};
          times.forEach(({ price, load }) => {
            if ([0, 1, 2].includes(load)) {
              result[season][load] = result[season][load] || [];
              if (!result[season][load].includes(price)) {
                result[season][load].push(price);
              }
            }
          });
        });
        this.priceArray = Object.keys(result)
          .map((season) => Object.keys(result[season]).map((load) => ({
            season,
            load,
            prices: Array.from(result[season][load]),
          })));
      }
    },
    getTimeRange(season, loadType) {
      if (!this.selectedEvse || !this.selectedEvse.priceTable) {
        return '';
      }
      const seasonData = this.selectedEvse.priceTable.find((data) => data.season === season);
      if (seasonData) {
        const { times } = seasonData;
        const timeRanges = [];
        let startTime = -1;

        times.forEach((time, index) => {
          if (time.load === loadType) {
            if (startTime === -1) {
              startTime = index;
            }
          } else if (startTime !== -1) {
            const endTime = index;
            timeRanges.push(`${this.formatTime(startTime)} - ${this.formatTime(endTime)}`);
            startTime = -1;
          }
        });
        if (startTime !== -1) {
          timeRanges.push(`${this.formatTime(startTime)} - ${this.formatTime(times.length - 1)}`);
        }
        return timeRanges.join('<br>');
      }
      return '';
    },
    formatTime(time) {
      if (time === 23) return '00:00';
      return time < 10 ? `0${time}:00` : `${time}:00`;
    },
  },
  watch: {
    selectedEvse: {
      handler(value) {
        if (value != null) {
          const memberValue = value.membershipPrice[String(value.memberGrade)];
          this.memberPrice = memberValue.disable
            ? value.membershipPrice[0] : value.membershipPrice[String(value.memberGrade)];
          this.getSeasons();
          this.prices();
        }
      },
    },
  },
};
</script>

<style>
.origin-rate {color: red; text-decoration: line-through;}
</style>
