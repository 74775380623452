import store from '@/store';

// store.dialogs의 alert액션을 호출하는 함수
const alert = (type, message) => {
  store.dispatch('dialog/alert', [type, message]);
};

// store.dialog의 confirm 뮤데이션을 호출하는 함수
const confirm = (payload = null) => {
  if (payload === null) {
    const before = store.getters['dialog/confirm'];
    store.commit('dialog/confirm', {
      ...before,
      show: false,
    });
  } else {
    store.commit('dialog/confirm', payload);
  }
};

// store.dialog의 progress 뮤데이션을 호출하는 함수
const progress = (show) => {
  store.commit('dialog/progress', show);
};

const messageModal = (show, message) => {
  store.commit('dialog/messageModal', {
    show,
    message,
  });
};

const loginModal = (show, message) => {
  store.commit('dialog/loginModal', {
    show,
    message,
  });
};

const preparingMsg = (show, message) => {
  store.commit('dialog/preparingMsg', {
    show,
    message,
  });
};

export default {
  alert,
  confirm,
  progress,
  messageModal,
  loginModal,
  preparingMsg,
};
