export default {
  namespaced: true,
  // 상태저장
  state: {
    alert: {
      show: false,
      timeout: 4000,
      timeoutInterval: true,
      type: 'info',
      message: null,
    },
    progress: false,
    confirm: {
      show: false,
      callback: null,
      message: null,
      color: null,
      btnText: null,
    },
    viewLoad: false,
    messageModal: {
      show: false,
      message: null,
    },
    loginModal: {
      show: false,
      message: null,
    },
    preparingMsg: {
      show: false,
      message: {
        text1: null,
        text2: null,
        text3: null,
      },
    },
    system: {
      show: false,
      type: 'error',
      icon: 'mdi-connection',
      message: null,
    },
  },

  // 상태값 가져오는 메서드
  getters: {
    alert(state) {
      return state.alert;
    },
    progress(state) {
      return state.progress;
    },
    confirm(state) {
      return state.confirm;
    },
    viewLoad(state) {
      return state.viewLoad;
    },
    messageModal(state) {
      return state.messageModal;
    },
    loginModal(state) {
      return state.loginModal;
    },
    preparingMsg(state) {
      return state.preparingMsg;
    },
    system(state) {
      return state.system;
    },
  },

  // 상태값을 변경하는 메서드
  mutations: {
    alert(state, value) {
      state.alert = value;
    },
    progress(state, bool) {
      state.progress = bool;
    },
    confirm(state, value) {
      state.confirm = value;
    },
    viewLoad(state, value) {
      state.viewLoad = value;
    },
    messageModal(state, value) {
      state.messageModal = value;
    },
    loginModal(state, value) {
      state.loginModal = value;
    },
    preparingMsg(state, value) {
      state.preparingMsg = value;
    },
    system(state, value) {
      state.system = value;
    },
  },

  // 비동기 작업 수행 메서드 (mutation 호출 또는 API 통신 등 비동기 작업 처리)
  actions: {
    alert({ state, commit }, [type, message, timeout = 4000]) {
      commit('alert', { ...state.alert, show: false });
      setTimeout(() => {
        commit('alert', {
          ...state.alert,
          message,
          type,
          timeout,
          show: true,
        });
        if (timeout != null) {
          const startInterval = () => {
            commit('alert', { ...state.alert, timeoutInterval: false });
            const interval = setInterval(() => {
              commit('alert', { ...state.alert, timeout: state.alert.timeout - 100 });
              if (state.alert.timeout === 0) {
                commit('alert', {
                  ...state.alert,
                  show: false,
                  timeoutInterval: true,
                });
                clearInterval(interval);
              }
            }, 100);
          };
          if (state.alert.timeoutInterval) {
            startInterval();
          }
        }
      }, 400);
    },
  },
};
