<template>
  <div class="cp-card-wrap w-100" v-if="selectedMarkerData != null">
    <v-card class="cp-card">
      <v-card-text class="cp-card-text pt-0">
        <div class="pb-5 card-title pt-4">
          <p class="cp-title">{{selectedMarkerData.name}}</p>
          <p>{{selectedMarkerData.address}}</p>
          <v-chip
            label
            outlined
            text
            color="primary"
            class="mt-3"
          >
            <span class="pr-2">{{ $t('map.mapInfo.available') }}</span>
            <span class="pr-1">{{ $t('map.mapInfo.slow') }}</span>
            <span class="font-weight-bold">{{ typeCount.acAvailable }}</span>
            <span class="pr-2">{{ `/${typeCount.ac}` }}</span>
            <span class="pr-1">{{ $t('map.mapInfo.highSpeed') }}</span>
            <span class="font-weight-bold">{{ typeCount.dcAvailable }}</span>
            <span>{{ `/${typeCount.dc}` }}</span>
          </v-chip>
        </div>
        <div class="evse-list" v-if="length !== 0">
          <v-card
            v-for="(evse, idx) in selectedMarkerData.evse"
            :key="idx"
            outlined
            class="mb-3 pa-4"
          >
            <div class="card-content w-100 text-subtitle-1 pb-4">
              <div class="d-flex justify-space-between">
                <div>
                  <p>{{ $t('map.mapInfo.evse') }}</p>
                  <p class="text-h6">{{ evse.evseNumber }}</p>
                </div>
                <button @click="openImg(evse)">
                  <v-img
                    width="50"
                    :src="fileConverter.imagePathToSrc(evse.model.thumbnailPath)"
                    :alt="fileConverter.imagePathToSrc(evse.model.thumbnailPath)"
                  />
                </button>
              </div>
              <v-divider color="#E8E7E7"></v-divider>
              <div class="d-flex justify-space-between">
                <p>{{ $t('map.mapInfo.type') }}</p>
                <p class="font-weight-medium">{{ evse.model.type === 'DC' ?
                `${$t('map.mapInfo.highSpeed')} (${evse.model.kwh }kW)`
                : `${$t('map.mapInfo.slow')} (${evse.model.kwh }kW)` }}</p>
              </div>
              <div class="d-flex justify-space-between">
                <p>
                  {{ $t('map.mapInfo.amount') }}
                  <v-btn
                    v-if="evse.currentPriceData.method === 'kwh'"
                    @click="goToCharge(selectedMarkerData, evse, 'price')"
                    :ripple="false"
                    depressed
                    text
                    outlined
                    x-small
                    rounded
                    link
                    class="ml-1"
                  >{{ $t('map.mapInfo.viewDetail') }}</v-btn>
                </p>
                <p class="font-weight-medium">
                  {{ `${$t(transferRateMethod(evse.currentPriceData.method))} /
                      ${currency} ${evse.currentPriceData.price.toFixed(1)}` }}
                </p>
              </div>
              <div class="d-flex justify-space-between">
                <p>{{ $t('map.mapInfo.status') }}</p>
                <p
                  class="font-weight-medium d-flex align-center"
                  :style="{ color: isAbleToCharge(evse)[2] }"
                >
                  <v-icon
                    small
                    :color=isAbleToCharge(evse)[2]
                    class="pr-1"
                    style="cursor: auto;"
                  >{{ `mdi-${isAbleToCharge(evse)[3]}` }}</v-icon>
                  {{ $t(isAbleToCharge(evse)[0]) }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-center mb-2 w-100">
              <v-btn
                class="charge-btn rounded-lg w-100"
                color="primary"
                small
                depressed
                :disabled="!(isAbleToCharge(evse)[0] === 'map.mapInfo.ableToCharge')"
                @click="goToCharge(selectedMarkerData, evse, 'charge')"
              >
                <font-awesome-icon
                  icon="fa-solid fa-charging-station"
                  class="pr-1"
                />
                {{$t('map.mapInfo.startCharging')}}
              </v-btn>
            </div>
          </v-card>
        </div>
        <div v-else class="not-available d-flex align-center justify-center font-weight-bold">
          <p>{{ $t('map.mapInfo.notAvailable') }}</p>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showImg">
      <v-card class="modal-img d-flex flex-column align-center">
        <v-card-title class="justify-center">{{ selectedImg.modelName }}</v-card-title>
        <img
          class="show-cp-img w-50 h-50 pb-6"
          :src="selectedImg.img"
          :alt="selectedImg.img"
        />
        <button @click="showImg = false"><v-icon>mdi-close</v-icon></button>
      </v-card>
    </v-dialog>
    <StartSetting
      :openChargeSetting.sync="openChargeSetting"
      :userInfo="userInfo"
      :selectedEvse="selectedEvse"
      :chargeRate="chargeRate"
      :companyInfo="companyInfo"
      :userCards="userCards"
    />
    <PriceTable
      :openPriceTable.sync="openPriceTable"
      :selectedEvse="selectedEvse"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fileConverter from '@/util/file';
import StartSetting from '../remoteStart/StartSetting.vue';
import PriceTable from './PriceTable.vue';

export default {
  props: {
    infoOpen: {
      type: Boolean,
      required: true,
    },
    selectedMarkerData: {
      type: Object,
      default: null,
    },
    companyInfo: {
      type: Object,
    },
    transactionData: {
      type: null,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  components: { StartSetting, PriceTable },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    fileConverter,
    showImg: false,
    selectedImg: {},
    openChargeSetting: false,
    openPriceTable: false,
    selectedEvse: {},
    isCreditCard: true,
    chargeRate: 0,
    length: 0,
    userCards: [],
    typeCount: {
      dc: 0,
      dcAvailable: 0,
      ac: 0,
      acAvailable: 0,
    },
  }),
  methods: {
    closeCard() {
      this.$emit('update:infoOpen', !this.infoOpen);
    },
    openImg(selected) {
      this.selectedImg = {
        modelName: selected.evseNumber,
        img: fileConverter.imagePathToSrc(selected.model.thumbnailPath),
      };
      this.showImg = true;
    },
    isAbleToCharge(evse) {
      if (
        evse.chargepointOnline === true
        && evse.chargepointState.availability === 'Operative'
        && evse.state.availability === 'Operative'
        && evse.state.online === true
        && evse.state.status === 'Available'
      ) return ['map.mapInfo.ableToCharge', 'able', '#0069B8', 'lightning-bolt'];
      if (
        evse.chargepointOnline === true
        && evse.chargepointState.availability === 'Operative'
        && evse.state.availability === 'Operative'
        && evse.state.online === true
        && [
          'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved',
        ].includes(evse.state.status)
      ) return ['map.mapInfo.charging', 'charging', '#FFC107', 'car-electric'];
      return ['map.mapInfo.unableToCharge', 'unable', '#FF5252', 'alert'];
    },
    async getArea(item, evse, type) {
      try {
        const area = await this.$emitter('area.get', {
          _id: item._id,
        });
        const result = area.item.evse.find((data) => data.evseNumber === evse.evseNumber);
        this.selectedEvse = {
          cpName: this.selectedMarkerData.name,
          cpAddress: this.selectedMarkerData.address,
          evseNumber: result.evseNumber,
          kwh: result.model.kwh,
          type: result.model.type,
          imageUrl: fileConverter.imagePathToSrc(result.model.thumbnailPath),
          timeOut: result.connetionTimeOut,
          priceTable: result.pricetable.data,
          membershipPrice: result.membershipPrice,
          memberGrade: this.userInfo !== null ? this.userInfo.grade : 0,
        };
        this.chargeRate = result.currentPriceData.price;
        switch (type) {
          case 'charge': this.openChargeSetting = !this.openChargeSetting; break;
          case 'price': this.openPriceTable = !this.openPriceTable; break;
          default: break;
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    /* eslint no-underscore-dangle: 0 */
    async goToCharge(item, evse, type) {
      if (type === 'price') this.getArea(item, evse, type);
      if (type === 'charge') {
        if (this.userInfo) {
          if (this.isCreditCard) {
            this.getArea(item, evse, 'charge');
          } else {
            this.$dialog.confirm({
              show: true,
              message: 'map.remoteStart.needCreditCard',
              btnText: 'button.confirm',
              callback: async () => {
                this.$dialog.confirm();
                this.$router.push('/account/card-enroll');
              },
            });
          }
        } else {
          this.$dialog.confirm({
            show: true,
            message: 'myAccount.needLogin',
            btnText: 'button.goLogin',
            callback: async () => {
              this.$dialog.confirm();
              this.$router.push('/login');
            },
          });
        }
      }
    },
    transferRateMethod(method) {
      switch (method) {
        case 'min': return 'map.mapInfo.1minute';
        case 'kwh': return 'map.mapInfo.1KWh';
        default: return null;
      }
    },
    async haveCreditcard() {
      const res = await this.$emitter('usercard.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['isDefault', 'created'],
        sortDesc: [true, true],
      });
      this.isCreditCard = res.items.length > 0;
      this.userCards = res.items;
    },
  },
  watch: {
    selectedMarkerData: {
      deep: true,
      handler(val) {
        if (val != null) {
          this.length = val.evse.length;
          this.typeCount = {
            dc: 0, dcAvailable: 0, ac: 0, acAvailable: 0,
          };
          val.evse.forEach((item) => {
            const modelType = item.model.type;
            const available = this.isAbleToCharge(item);
            if (modelType === 'DC') {
              this.typeCount.dc = (this.typeCount.dc || 0) + 1;
              if (available[1] === 'able') this.typeCount.dcAvailable = (this.typeCount.dcAvailable || 0) + 1;
            } else if (modelType === 'AC') {
              this.typeCount.ac = (this.typeCount.ac || 0) + 1;
              if (available[1] === 'able') this.typeCount.acAvailable = (this.typeCount.acAvailable || 0) + 1;
            }
          });
        }
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          if (val != null) this.haveCreditcard();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.cp-card-wrap {
  position: fixed;
  max-width: 767px;
  height: 70%;
  bottom: 56px;
  background-color: #fff;
  overflow-y: scroll;
  .cp-card { z-index: 999; box-shadow: none !important;}
  .cp-card-text {
    .card-title {position: sticky; top: 0; background: #fff; z-index: 1;}
    .cp-title { font-size: 1.8rem; line-height: 2rem;}
    .not-available { height: 60%; font-size: 1.3rem; }
  }
}
.v-dialog--fullscreen { max-width: 767px; position: relative;}
.v-dialog__content {
  max-width: 767px;
  position: absolute;
  .modal-img {
    button {position: absolute; top: 5px; right: 15px; font-size: 1.2rem;}
  }
}
</style>
