<template>
  <div>
    <v-form
      class="submitForm issue-form px-5"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <p class="pb-5">{{ $t('customer.issue.chargeNo') }}</p>
      <v-select
        :items="cpList"
        :item-text="selectItemText"
        v-model="chargeName"
        outlined
        dense
        :rules="rules"
      >
        <template v-slot:item="data">
          {{
            data.item.area && data.item.area.name
            ? `${data.item.cpCode} (${data.item.area.name})` : data.item.cpCode
          }}
        </template>
      </v-select>
      <p class="pb-5">{{ $t('customer.issue.content') }}</p>
      <v-textarea
        outlined
        maxlength="1000"
        name="content"
        v-model="content"
        :rules="rules"
      ></v-textarea>
      <v-file-input
        class="fileInput"
        accept="image/png, image/jpeg, image/bmp"
        :placeholder="$t('customer.issue.file')"
        prepend-icon="mdi-camera"
        :value="files"
        counter
        multiple
        show-size
        clearable
        @change="handleFiles"
      >
        <template v-slot:selection="{ text, file }">
          <v-chip
            small
            label
            color="primary"
            close
            close-icon="mdi-close"
            @click:close="removeOneFile(file)">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
    </v-form>
    <BottomBtn
      :btnText="['button.submit']"
      :callback="submitIssue"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import fileConverter from '@/util/file';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    writeIssue: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    rules() {
      return [(v) => !!v || this.$t('rules.require')];
    },
  },
  data: () => ({
    cpList: [],
    fileConverter,
    valid: true,
    chargeName: '',
    content: '',
    files: [],
    convertFiles: [],
    maxCount: 3,
  }),
  methods: {
    async getCpList() {
      try {
        const res = await this.$emitter('cp.list.get', {
          page: 1,
          itemsPerPage: 0,
        });
        this.cpList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    selectItemText(item) {
      return item.area && item.area.name
        ? `${item.cpCode} (${item.area.name})` : item.cpCode;
    },
    async handleFiles(files) {
      files.forEach((item) => {
        if (!this.files
          .some((file) => file.name === item.name && file.size === item.size)
        ) { this.files.push(item); }
      });
      // 파일 갯수 확인
      if (this.files.length > this.maxCount) {
        this.$dialog.messageModal(true, 'alert.count');
        this.files = [];
      } else {
        const totalSize = this.files.reduce((acc, item) => acc + item.size, 0);
        // 파일 사이즈 확인 10MB
        if (totalSize > 10285760) {
          this.files = [];
          this.$dialog.messageModal(true, 'alert.size');
        }
        this.files.forEach((item) => {
          // 파일 확장자 확인
          if (!item.type.includes('image')) {
            this.$dialog.messageModal(true, 'alert.type');
            this.files = [];
          }
        });
      }
    },
    // 파일 개별 삭제
    removeOneFile(selectFile) {
      this.files = this.files
        .filter((file) => file.name !== selectFile.name && file.size !== selectFile.size);
    },
    async submitIssue() {
      const message = `${this.$t('error.400.400')}`;
      // 파일 데이터 바이너리 변환
      const fileData = this.files.map((file) => fileConverter.readFileToPayload(file));
      const convertedFiles = await Promise.all(fileData);
      this.convertFiles.push(...convertedFiles);

      const validation = this.$refs.form.validate();
      if (validation) {
        this.$dialog.confirm({
          show: true,
          message: 'button.submitQ',
          btnText: 'button.confirm',
          callback: async () => {
            try {
              await this.$emitter('post.add', {
                boardCode: 'problem',
                title: `${this.chargeName}`,
                content: this.content,
                files: this.convertFiles,
              });
              this.$dialog.confirm();
              this.alert = true;
              this.$dialog.alert('success', `${this.$t('alert.reportReceived')}`);
              this.$dialog.confirm();
              setTimeout(() => {
                this.$emit('update:writeIssue', !this.writeIssue);
              }, 1000);
            } catch (error) {
              console.error(error);
              this.$dialog.alert('error', this.$error.makeErrorMessage(error));
              this.$dialog.confirm();
            }
          },
        });
      } else {
        this.$dialog.alert('error', message);
      }
    },
  },
  watch: {
    writeIssue: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getCpList();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.issue-wrap {
  .submitForm {
    .fileInput div > div > button { font-size: 2.25rem !important;}
  }
}
</style>
