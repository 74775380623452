<template>
  <v-dialog
    class="w-100"
    v-model="openNotify"
    fullscreen
    transition="dialog-top-transition"
    persistent
  >
    <v-card class="w-100 h-100 rounded-0 ">
      <div class="w-100 d-flex align-center justify-space-between px-5 notification-modal">
        <Title
          :title="'myAccount.notificationCenter.title'"
          :isStyle="true"
        />
        <v-btn
          @click="closeCard"
          depressed
          text
          class="pa-0"
          min-width="20"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <div class="px-4 notification-list">
        <div class="d-flex justify-space-between">
          <p class="d-flex align-center text-caption">
            {{ $t('myAccount.notificationCenter.3month') }}
          </p>
          <v-btn
            v-if="notifications.length !== 0"
            class="pa-0"
            depressed
            text
            :ripple="false"
            @click="readNotification('all')"
          >{{ $t('myAccount.notificationCenter.readAll') }}</v-btn>
        </div>
        <v-list
          v-for="(item, index) in notifications"
          :key="index"
        >
          <v-list-item
            @click="readNotification('one', item.url, item._id)"
            class="px-0 py-3 notification-box rounded-lg"
            :class="item.isRead ? 'read-notification' : 'notification'"
          >
            <v-img
              v-if="item.iconFilePath"
              class="rounded-circle"
              :src="fileConverter.imagePathToSrc(item.iconFilePath)"
              max-width="40" height="40"
            />
            <div class="w-100 d-flex justify-space-between">
              <div class="w-100 d-flex justify-space-between align-center">
                <div class="pl-5 w-75">
                  <p class="font-weight-bold">{{ item.title }}</p>
                  <p class="text-caption">{{ item.body }}</p>
                </div>
                <p class="text-caption text-right pr-2">
                  {{ timeConvert.displayedAt(item.created, $i18n) }}
                </p>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import timeConvert from '@/util/time';
import { getPrevMonth } from '@/util/calendar';
import fileConverter from '@/util/file';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  props: {
    openNotify: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      unReadCount: 'notification/unReadCount',
    }),
  },
  data: () => ({
    timeConvert,
    fileConverter,
    notifications: [],
  }),
  methods: {
    ...mapActions({
      notificationSummary: 'notification/notificationSummary',
    }),
    closeCard() {
      this.$emit('update:openNotify', !this.openNotify);
    },
    async getNotification() {
      const prevMonth = getPrevMonth(timeConvert.getMonth2());
      const currentMonth = timeConvert.getMonth2();
      const year = currentMonth.split('-')[0];
      const month = currentMonth.split('-')[1];
      const filter = [
        {
          where: 'and',
          condition: 'gte',
          key: 'created',
          value: `${prevMonth}-01 00:00:00`,
        },
        {
          where: 'and',
          condition: 'lte',
          key: 'created',
          value: `${timeConvert.getLastDay(year, month)} 23:59:59`,
        },
      ];
      const res = await this.$emitter('notification.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['isRead', 'created'],
        sortDesc: [false, true],
        filters: filter,
      });
      this.notifications = res.items;
    },
    async readNotification(type, url, id) {
      if (type === 'one') {
        if (url) this.$router.push(url);
        await this.$emitter('notification.read', { _id: id });
        await this.notificationSummary();
      } else if (type === 'all') {
        await this.$emitter('notification.read', { isAll: true });
        await this.notificationSummary();
      }
      this.getNotification();
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.notifications.sort((a, b) => a.isConfirm - b.isConfirm);
          this.getNotification();
        }
      },
    },
    closeCard: {
      immediate: true,
      handler(val) {
        if (val != null) this.getNotification();
      },
    },
  },
};
</script>

<style lang="scss">
.notification-list {
  background: #fff;
  .notification-box {
    background-color : rgba(185, 178, 178, 0.1);
  }
  .read-notification {
    background-color: transparent;
    opacity: 0.5;
  }
}
</style>
