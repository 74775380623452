<template>
  <Issue/>
</template>

<script>
import Issue from '../../components/customerCenter/issue/Issue.vue';

export default {
  name: 'issue',
  components: { Issue },
};

</script>
