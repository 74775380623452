import Vue from 'vue';
// import native from '@/util/native';
// eslint-disable-next-line import/no-cycle
import { getDeviceInfo, getFCMToken } from '@/util/native';
import Error from '../util/error';
import i18n from '../i18n';

function emitter(eventName, body) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

export default {
  namespaced: true,
  state: {
    initialized: false,
    userInfo: null,
    companyInfo: null,
  },
  getters: {
    initialized(state) {
      return state.initialized;
    },
    userInfo(state) {
      return state.userInfo;
    },
    companyInfo(state) {
      return state.companyInfo;
    },
  },
  mutations: {
    initialized(state, value) {
      state.initialized = value;
    },
    userInfo(state, value) {
      state.userInfo = value;
    },
    companyInfo(state, value) {
      state.companyInfo = value;
    },
  },
  actions: {
    // 로그아웃
    async signout({ state, dispatch }) {
      try {
        await emitter('user.signout', {});
        localStorage.removeItem(`BLITZ.${state.companyInfo.code}.token`);
        dispatch('authorization');
      } catch (error) {
        dispatch('dialog/alert', ['error', Error.makeErrorMessage(error)], { root: true });
        console.error(error);
      }
    },
    // 회사정보
    async initialization({ commit, dispatch }) {
      try {
        const response = await emitter('sysconf.company.get', {});
        commit('companyInfo', response.item);
        await dispatch('authorization');
      } catch (error) {
        dispatch('dialog/alert', ['error', Error.makeErrorMessage(error)], { root: true });
        console.error(error);
      }
    },
    // 사용자정보
    async authorization({ state, commit, dispatch }, type) {
      const tokenPath = `BLITZ.${state.companyInfo.code}.token`;
      const token = localStorage.getItem(tokenPath);
      if (token) {
        try {
          const response = await emitter('user.token.verify', { token });
          const { userInfo } = response;
          commit('userInfo', userInfo);
          console.info('User Verified.');
          if (type !== 'update') {
            const message = `${userInfo.name}${i18n.t('alert.welcome')}`;
            dispatch('dialog/alert', ['success', message], { root: true });
          }
          await dispatch('transaction/transactionCheck', null, { root: true });
          // 모바일일 경우
          const isMobile = await getDeviceInfo();
          if (['android', 'ios'].includes(isMobile.platform)) {
          // firebase token 저장
            const firebaseToken = await getFCMToken();
            await emitter('user.update', { fcmToken: firebaseToken });
          }
        } catch (error) {
          commit('userInfo', null);
          localStorage.removeItem(tokenPath);
        }
      } else { // 토큰이 없을 경우
        commit('userInfo', null);
      }
      console.info('App Initialized.');
      commit('initialized', true);
      commit('dialog/progress', false, { root: true });
    },
    // 사용자 선택 언어 등록
    async userLocale(_, locale) {
      await emitter('user.update', { locale });
    },
  },
};
