<template>
  <div class="login-wrap">
    <LoginBox
      v-if="!openSearch"
      :openSearch="openSearch"
    />
    <SearchPW
      v-if="openSearch"
      :openSearch.sync="openSearch"
    />
    <div
      v-if="!openSearch"
      class="pw-search d-flex justify-center pt-2 navyBlue--text"
    >
      <p @click="$router.push('/signup')">{{ $t('login.signup') }}</p>
      <span class="px-2">|</span>
      <p
        @click="openSearch = !openSearch"
      >{{ $t('login.searchPW.search') }}</p>
    </div>
  </div>
</template>

<script>
import LoginBox from './loginBox/LoginBox.vue';
import SearchPW from './searchPassword/SearchPw.vue';

export default {
  components: {
    LoginBox, SearchPW,
  },
  data: () => ({
    openSearch: false,
  }),
};

</script>

<style lang="scss">
.login-wrap {
  .pw-search {
    max-width: 767px;
    width: 100%;
    position: fixed;
    bottom: 90px;
    cursor: pointer;
    p {font-size: 0.875rem;}
  }
}
</style>
