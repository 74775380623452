/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import auth from './auth';
import dialog from './dialog';
import signUpUser from './signUpUser';
import transaction from './transaction';
import notification from './notification';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    dialog,
    signUpUser,
    transaction,
    notification,
  },
});
