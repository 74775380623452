<template>
  <div class="dig-msg-modal" v-show="preparingMsg.show === true">
    <v-row justify="center">
      <v-dialog
        v-model="preparingMsg.show"
        max-width="500px"
      >
        <v-card min-height="200px">
          <div class="d-flex flex-column align-center justify-center">
            <v-icon class="pt-5" style="font-size: 3rem;" color="primary">
              mdi-alert-circle-outline
            </v-icon>
            <p class="font-weight-bold pt-2 pb-3" style="font-size: 1rem;">
              {{$t(preparingMsg.message.text1)}}
            </p>
            <pre style="display: contents; text-align: center;">
              <p style="font-size: 0.8rem;">{{$t(preparingMsg.message.text2)}}</p>
            </pre>
            <p style="font-size: 0.8rem;">{{$t(preparingMsg.message.text3)}}</p>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      preparingMsg: 'dialog/preparingMsg',
    }),
  },
};
</script>

<style scoped lang="scss">
</style>
