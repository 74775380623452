<template>
  <MembershipCardDetail/>
</template>

<script>
import MembershipCardDetail from '../../components/myAccount/membershipCard/MembershipCardDetail.vue';

export default {
  name: 'Membership',
  components: { MembershipCardDetail },
};
</script>
