<template>
  <div class="alert" v-show="loginModal.show === true">
    <v-row justify="center">
      <v-dialog
        v-model="loginModal.show"
        max-width="350px"
      >
        <v-card
          class="modal-card"
          min-height="150px"
        >
          <v-card-title class="card-title">
            <pre><p class="msg">{{$t(loginModal.message)}}</p></pre>
          </v-card-title>
          <v-card-actions class="btns">
            <v-btn
              outlined
              depressed
              color="primary"
              @click="btnAction('cxl')"
            >{{$t('button.cancel')}}</v-btn>
            <v-btn
              color="primary"
              @click="btnAction('login')"
              depressed
            >{{$t('button.goLogin')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DigLoginModal',
  computed: {
    ...mapGetters({
      loginModal: 'dialog/loginModal',
    }),
  },
  methods: {
    btnAction(type) {
      if (type === 'login' && this.$route.path !== '/login') this.$router.push('/login').catch(() => {});
      this.$dialog.loginModal(false);
    },
  },
};
</script>

<style scoped lang="scss" scope>
.modal-card {
  .card-title { padding-bottom: 0 !important; justify-content: center;}
  .msg  {font-size: 1rem;}
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

}
</style>
