<template>
  <div class="my-account">
    <v-form
      class="change-detail px-5"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="my-detail w-100 d-flex align-baseline">
        <p class="field-title">{{ $t('user.e-mail') }}</p>
        <v-text-field
          v-model="email"
          disabled
          class="field pa-0"
        ></v-text-field>
      </div>
      <div class="my-detail w-100 d-flex align-baseline">
        <p class="field-title">{{ $t('user.mobile') }}</p>
        <v-text-field
          :rules="rules"
          v-model="mobile"
          class="field pa-0"
          @input="handleInput"
        >
        </v-text-field>
      </div>
      <p class="address font-weight-bold pt-5 pb-0">
        {{ $t('user.address') }}
        <span class="address-info red--text pl-2">{{ $t('myAccount.update.addressEnroll')}}</span>
      </p>
      <PostCode
        ref="postcode"
        :useAddress.sync="address"
        :openSearchAddr="openSearchAddr"
        @update:openSearchAddr="$emit('update:openSearchAddr', $event)"
      />

      <button
        class="withdrawal grey--text text--lighten-1 font-weight-bold"
        @click="showWithdrawal = !showWithdrawal"
      >{{ $t('myAccount.update.withdrawal') }} ></button>
      <withdrawal
        v-if="showWithdrawal"
        :showWithdrawal.sync="showWithdrawal"
      />
    </v-form>
    <BottomBtn
      :btnText="['button.update']"
      :callback="submitChange"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import withdrawal from './Withdrawal.vue';
import PostCode from '../../common/PostCode.vue';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { PostCode, withdrawal, BottomBtn },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    rules() {
      return [
        (v) => !!v || this.$t('rules.require'),
        (v) => /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || this.$t('rules.mobile'),
      ];
    },
  },
  props: {
    openSearchAddr: {
      type: Boolean,
    },
  },
  data: () => ({
    valid: true,
    initialMobile: '',
    mobile: '',
    email: '',
    address: {},
    showWithdrawal: false,
    loading: true,
  }),
  methods: {
    ...mapActions({
      authorization: 'auth/authorization',
    }),
    handleInput() {
      if (this.mobile !== this.initialMobile) {
        this.initialMobile = '';
      }
    },
    async submitChange() {
      const message = `${this.$t('error.400.400')}`;
      const validation = this.$refs.form.validate();
      if (validation) {
        const postcodeValue = this.$refs.postcode;
        const addressData = {
          zipCode: postcodeValue.useAddress.zipCode,
          address: postcodeValue.useAddress.address,
          detailAddress: postcodeValue.useAddress.detailAddress,
        };
        this.$dialog.confirm({
          show: true,
          message: 'button.modifyQ',
          btnText: 'button.confirm',
          callback: async () => {
            if (this.mobile !== this.initialMobile) {
              try {
                await this.$emitter('user.update', {
                  phone: this.mobile,
                  address: addressData,
                });
              } catch (error) {
                console.error(error);
                this.$dialog.alert('error', this.$error.makeErrorMessage(error));
              }
              this.initialMobile = this.mobile;
            } else {
              try {
                await this.$emitter('user.update', {
                  address: addressData,
                });
              } catch (error) {
                console.error(error);
                this.$dialog.alert('error', this.$error.makeErrorMessage(error));
              }
            }
            this.$dialog.confirm();
            this.authorization('update');
            this.$dialog.alert('success', `${this.$t('alert.edited')}`);
            this.$dialog.confirm();
            setTimeout(() => {
              this.$router.push('/account');
            }, 1000);
          },
        });
      } else {
        this.$dialog.alert('error', message);
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.loading = false;
          this.initialMobile = val.phone;
          this.mobile = val.phone;
          this.email = val.email;
          if (val.address != null) {
            this.address = {
              zipCode: val.address.zipCode,
              address: val.address.address,
              detailAddress: val.address.detailAddress,
            };
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.change-detail {
  .my-detail {
    height: 48px;
    p {font-size: 0.8rem;}
  }
  .address {
    .address-info { font-size: 0.8rem; }
  }
  .withdrawal {
    font-size: 0.8rem;
    cursor: pointer;
  }
}
</style>
