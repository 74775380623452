<template>
  <div
    v-if="progress === true"
    class="progress-wrap"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="50"
      :width="10"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DlgProgressCircle',
  computed: {
    ...mapGetters({
      progress: 'dialog/progress',
    }),
  },
};
</script>

<style scoped lang="scss">
  .progress-wrap {
    z-index: 400;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    top:0px;
    left: 0px;
    background-color: rgba(255,255,255,0.4);
    align-items: center;
    justify-content: center;
  }
</style>
