import Vue from 'vue';
import CommSocket from '@/plugins/comm';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import '@mdi/font/css/materialdesignicons.css';
import request from '@/util/request';
import './registerServiceWorker';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueGeolocationApi from 'vue-geolocation-api';
import dialog from './util/dialog';
import Error from './util/error';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import config from './config/config.json';
import App from './App.vue';
import native from './util/native';

// 설정
Vue.config.productionTip = false;

// i18n 기본언어
if (localStorage['BLITZadmin.locale'] == null) localStorage.setItem('BLITZadmin.locale', 'ko');

// 전역변수
Vue.prototype.$axios = axios;
Vue.prototype.$emitter = request.emitter;
Vue.prototype.$rest = request.rest;
Vue.prototype.$error = Error;
Vue.prototype.$dialog = dialog;

// socket
Vue.use(CommSocket);

// fontawesome
library.add(fas, far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// googleMap
Vue.prototype.$googleMapOptions = Vue.observable({
  load: {
    key: config.google.map,
    language: localStorage['BLITZadmin.locale'],
    libraries: 'places, geolocation, geocoder, maps',
  },
});
Vue.use(VueGeolocationApi);
Vue.use(VueGoogleMaps, Vue.prototype.$googleMapOptions);
Vue.component('GmapCluster', GmapCluster);

native.firebaseInit();
native.iPhoneNotchChecker();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  watch: {
    '$i18n.locale': (value) => {
      const langMap = {
        en: 'en',
        jp: 'ja',
        ko: 'ko',
      };
      localStorage.setItem('BLITZadmin.locale', langMap[value]);
      // userinfo 확인 후 locale update
      const user = store.getters['auth/userInfo'];
      if (user != null) {
        store.dispatch('auth/userLocale', value);
      }
    },
  },
}).$mount('#app');
