<template>
  <v-app id="app">
    <RouterView class="common-wrap" />
    <NavSection/>
    <DlgSystemBar/>
    <DlgProgressCircle/>
    <DlgAlert/>
    <DlgConfirm
      :show.sync="confirm.show"
      :callback="confirm.callback"
      :message="confirm.message"
      :btnText="confirm.btnText"
      :color="confirm.color"
    />
    <MessageModal/>
    <LoginModal/>
    <PreparingMsg/>
    <NotificationMessages
      v-show="pushMessage"
      :pushMessage.sync="pushMessage"
    />
  </v-app>
</template>
<script>
import { App } from '@capacitor/app';
import { mapGetters, mapActions } from 'vuex';
import DlgProgressCircle from './components/dialog/DlgProgressCircle.vue';
import DlgAlert from './components/dialog/DlgAlert.vue';
import DlgConfirm from './components/dialog/DlgConfirm.vue';
import DlgSystemBar from './components/dialog/DlgSystemBar.vue';
import NavSection from './components/nav/NavSection.vue';
import MessageModal from './components/dialog/DlgMsgModal.vue';
import LoginModal from './components/dialog/DlgLoginModal.vue';
import PreparingMsg from './components/dialog/DlgPreparing.vue';
import NotificationMessages from './components/myAccount/notificationCenter/NotificationMessages..vue';

export default {
  components: {
    DlgAlert,
    DlgProgressCircle,
    DlgConfirm,
    NavSection,
    MessageModal,
    LoginModal,
    DlgSystemBar,
    PreparingMsg,
    NotificationMessages,
  },
  data: () => ({
    vh: 0,
    pushMessage: {
      show: false,
      message: '',
      page: '',
    },
  }),
  computed: {
    ...mapGetters({
      confirm: 'dialog/confirm',
      transactionData: 'transaction/transactionData',
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
      notification: 'notification/notification',
      unReadCount: 'notification/unReadCount',
    }),
  },
  mounted() {
    // 앱 뒤로가기 버튼 이벤트 리스너
    App.addListener('backButton', async (event) => {
      const { canGoBack } = event;
      if (canGoBack) this.$router.back();
    });

    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);

    window.addEventListener('resize', () => {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    });
  },
  methods: {
    ...mapActions({
      notificationSummary: 'notification/notificationSummary',
    }),
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.notificationSummary();
        }
      },
    },
    transactionData: {
      immediate: true,
      handler(value) {
        if (value != null && this.userInfo != null) {
          if (['Accepted'].includes(value.status) && this.$route.path !== '/monitoring') {
            this.$router.push('/monitoring');
          } else if (['Charging'].includes(value.status) && this.$route.path === '/') {
            this.$router.push('/monitoring');
          } else if (['SessionCompleted', 'PaymentRequired'].includes(value.satus)
              && this.$router.path !== '/') {
            this.$router.push('/');
          }
        }
      },
    },
    notification: {
      handler(val) {
        this.pushMessage = {
          show: true,
          img: val.iconFilePath,
          title: val.title,
          message: val.body,
          link: val.url,
        };
      },
    },
  },
};
</script>
<style lang="scss">
@import '~@/assets/scss/style.scss';

  html{
    overflow: hidden;
    max-width: 767px;
    margin: 0 auto;
    background-color: #f7f7f7;
    // padding-top: env(safe-area-inset-top);
    // padding-bottom: env(safe-area-inset-bottom);
  }
  // .v-application { height: calc(var(--vh) * 100);}
  // .v-application--wrap { height: calc(var(--vh) * 100);}
  .common-wrap {
    position: fixed;
    width:100%;
    max-width: 767px;
    height: calc(var(--vh)*100 - 56px);
  }
  .ios-notch-wrap {
    .common-wrap {
      height: calc(var(--vh)*100 - 76px);
      .btn-wrap {
        bottom: 90px;
      }
      &.account-wrap, &.customer-wrap {
        padding-top: 30px;
      }
      .titles-wrap {
        padding-top: 58px !important;
      }
    }
    .map-search-bar {
      top: 70px;
    }
    .google-map-area {
      height: calc(var(--vh)*100 - 76px);
    }
    .nav-wrap {
      padding-bottom: 20px;
    }
    .search-card {
      .search-btn-wrap {
        bottom: 30px;
      }
    }
    .notification-modal {
      padding-top: 30px;
    }
    .search-card>div {
      padding-top: 50px !important;
    }
    .start-wrap {
      .start-top {
        padding-top: 35px !important;
      }
    }
    .charging-wrap {
      .charging-title { padding-top: 30px; }
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 0px;
    background-clip: padding-box;
  }
  *::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    border-radius: 0px;
  }
  * {
    box-sizing: border-box;
    font-family:'Pretendard','Noto Sans KR','Malgun Gothic',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Thin.woff');
    font-weight: 100;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Light.woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Regular.woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Medium.woff');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Bold.woff');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Black.woff');
    font-weight: 900;
  }
</style>
