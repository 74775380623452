<template>
  <div>
    <p class="pt-4 font-weight-bold">
      {{ $t('myAccount.paymentCard.title') }}
      <span
        v-if="cardStatus"
        class="red--text text-caption font-weight-bold"
      >{{ $t('myAccount.paymentCard.unavailable') }}</span>
    </p>
    <div
      class="card-box rounded-lg mt-2 d-flex align-center"
      @click="movePage"
    >
      <div v-if="Object.keys(this.defaultCard).length !== 0" class="w-100 d-flex">
        <p
          class="w-25 white--text rounded text-center mx-3"
          :style="{background: defaultCard.icon}"
        >
        {{ defaultCard.cardName }}
        </p>
        <p class="w-50" v-if="defaultCard.cardName && defaultCard.cardNo">
          {{ `${defaultCard.cardName}${$t('myAccount.paymentCard.card')}
            (${defaultCard.cardNo.slice(-4)})` }}</p>
        <v-icon
          v-if="defaultCard.isDefault"
          color="primary"
          class="w-25 d-flex justify-end pr-2"
        >mdi-star</v-icon>
      </div>
      <div
        v-else
        class="w-100 d-flex align-center justify-center"
        @click="$router.push('/account/card-enroll')"
      >
        <p class="text-center">
          <v-icon>mdi-plus-circle-outline</v-icon>
          {{ $t('myAccount.paymentCard.enroll') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    defaultCard: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cardStatus: false,
  }),
  computed: {
    ...mapGetters({
      transactionData: 'transaction/transactionData',
    }),
  },
  methods: {
    movePage() {
      if (this.transactionData === null) this.$router.push('/account/paymentCard');
      else this.$dialog.alert('error', `${this.$t('alert.cannotMove')}`);
    },
  },
  watch: {
    'transactionData.status': {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.cardStatus = true;
          if (val === 'SessionCompleted') this.cardStatus = false;
        } else this.cardStatus = false;
      },
    },
  },
};
</script>

<style lang="scss">
.card-box {
  height: 50px;
  border: 1px solid #ccc;
}
</style>
