<template>
  <PaymentCardDetail/>
</template>

<script>
import PaymentCardDetail from '../../components/myAccount/paymentCard/PaymentCardDetail.vue';

export default {
  name: 'paymentCardDetail',
  components: { PaymentCardDetail },
};

</script>
