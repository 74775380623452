<template>
  <div class="my-account-wrap d-flex flex-column">
    <Title
      :title="'myAccount.update.update'"
      :goBack="!openSearchAddr ? true : false"
      :class="'px-5'"
    />
    <div class="name-wrap px-5">
      <p class="name">{{!loading ?  userInfo.name + $t('user.`s') : ' '}}</p>
      <v-divider color="darkGrey"></v-divider>
    </div>
    <div class="change-pw px-5 mb-3" v-if="socialLogin">
      <div class="d-flex align-center justify-space-between" v-if="!changePw">
        <p>{{$t('login.password')}}</p>
        <v-btn
          outlined
          depressed
          color="primary"
          @click="changePw = !changePw"
        >{{ $t('button.update') }}</v-btn>
      </div>
    </div>
    <ChangePassword
      v-if="changePw"
      :changePw.sync="changePw"
    />
    <ProfileDetail
      v-if="!changePw"
      :openSearchAddr.sync="openSearchAddr"
      @update:openSearchAddr="updateOpenSearchAddr"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChangePassword from './ChangePassword.vue';
import ProfileDetail from './ProfileDetail.vue';
import Title from '../../common/Title.vue';

export default {
  components: {
    ChangePassword, ProfileDetail, Title,
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    changePw: false,
    loading: true,
    socialLogin: false,
    openSearchAddr: false,
  }),
  methods: {
    updateOpenSearchAddr(value) {
      this.openSearchAddr = value;
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.loading = false;
          if (val.type === 'email') {
            this.socialLogin = !this.socialLogin;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.my-account-wrap {
  position: absolute !important;
  overflow-y: scroll;
  .name-wrap { height: 10%;}
  .change-pw {
    height: 5%;
    p {font-size: 0.8rem;}
  }
  .myAccount {
    width: 100%;
    margin: 0 auto;
    height: 75%;
  }
}
</style>
