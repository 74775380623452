<template>
  <div>
    <v-list
      v-for="(item, idx) in menus"
      :key="idx"
    >
      <v-list-item
        class="px-0"
        @click="openDialog(item)"
      >
        <v-icon class="pr-3" :color="item.color">{{ item.icon }}</v-icon>
        <p>{{ $t(item.title) }}</p>
      </v-list-item>
    </v-list>
    <v-dialog
      v-if="selectedItem"
      v-model="dialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title
          class="text-subtitle-1 font-weight-bold grey lighten-2">
          {{ $t(selectedItem.title) }}
        </v-card-title>
        <v-card-text style="white-space: pre-wrap;">
          {{ companyInfo[selectedItem.content] }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 회사정보 -->
    <div class="company-info d-flex flex-column">
      <div class="d-flex align-center">
        <v-img
          :src="fileConverter.imagePathToSrc(companyInfo.logoPath)"
          max-width="20" height="20"
        />
        <p class="company-name pl-1 font-weight-bold">{{ companyInfo.name }}</p>
      </div>
      <div class="text-caption pl-1 py-1">
        <p class="pr-10">
          <span class="font-weight-bold">{{ $t('customer.company.owner') }}</span>
          {{ companyInfo.ceoName }}
        </p>
        <p>
          <span class="font-weight-bold">{{ $t('customer.company.companyNo') }}</span>
          {{ companyInfo.taxId }}
        </p>
        <p class="pr-5">
          <span class="font-weight-bold">{{ $t('customer.company.companyAddr') }}</span>
          {{ companyInfo.address }}
        </p>
        <p><span class="font-weight-bold">{{ $t('customer.company.tel') }}</span>
          {{ companyInfo.tel }}</P>
        <p class="text-caption">
          <span class="font-weight-bold">{{ $t('customer.company.email') }}</span>
          {{ companyInfo.email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import fileConverter from '@/util/file';

export default {
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fileConverter,
    selectedItem: null,
    dialog: false,
    menus: [
      {
        title: 'customer.company.terms',
        icon: 'mdi-file-document-multiple-outline',
        color: '#424242',
        content: 'termsOfService',
      },
      {
        title: 'customer.company.privacy',
        icon: 'mdi-account-key',
        color: '#424242',
        content: 'privacyPolicy',
      },
    ],
  }),
  methods: {
    openDialog(item) {
      this.selectedItem = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
