<template>
  <div class="input-email w-100" v-if="!stepThree">
    <p class="search-title d-flex justify-center pb-8 font-weight-bold">
      {{$t('login.searchPW.verification')}}</p>
    <div class="search-email w-100 pa-4">
      <v-text-field
        outlined
        dense
        prepend-inner-icon="mdi-timer-sand"
        :rules="rules"
        v-model="code"
      >
        <template v-slot:append>
          <div class="countdown">{{ Time.makeCountDown(countdown) }}</div>
        </template>
      </v-text-field>
    </div>
    <BottomBtn
      :btnText="['login.searchPW.code']"
      :callback="submit"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import Time from '@/util/time';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    stepTwo: {
      type: Boolean,
      required: true,
    },
    stepThree: {
      type: Boolean,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  computed: {
    rules() {
      return [(v) => !!v || this.$t('rules.require')];
    },
  },
  data: () => ({
    Time,
    countdown: 600, // 10분
    code: '',
  }),
  methods: {
    startCountdown() {
      setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        }
      }, 1000);
    },
    async submit() {
      try {
        const response = await this.$emitter('user.email.auth.verify', {
          email: this.email,
          code: this.code,
        });
        this.$emit('email-token', response.token);
        this.$emit('update:stepThree', !this.stepThree);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    email: {
      immediate: true,
      handler(val) {
        if (val != null) this.startCountdown();
      },
    },
  },
};
</script>

<style lang="scss">
</style>
