import i18n from '../i18n';

function makeErrorMessage({ code, name, message: errorMsg }) {
  if (code == null) return i18n.t('error.errorMessage');
  let message = `${i18n.t('error.errorMessage')} (${errorMsg})`;

  if ([204, 429, 500].indexOf(code) !== -1) {
    message = i18n.t(`error.${code}`);
  } else if (
    i18n.t(`error.${code}.${name}`)
    !== `error.${code}.${name}`
  ) {
    message = i18n.t(`error.${code}.${name}`);
  }
  return message;
}

function pgError({ code, name }) {
  if (code === 400) {
    if (name === 'DUPLICATE_VALUE') return i18n.t('error.pgError.400.DUPLICATE_VALUE');
    if (name === 'BAD_REQUEST') return i18n.t('error.pgError.400.BAD_REQUEST');
  } else if (code === 8037) {
    if (name === 'PG_SERVER_ERROR') return i18n.t('error.pgError.8037.PG_SERVER_ERROR');
  } else if (code === 'F118') {
    if (name === 'PG_SERVER_ERROR') return i18n.t('error.pgError.F118.PG_SERVER_ERROR');
  } else if (code === '8016') {
    if (name === 'PG_SERVER_ERROR') return i18n.t('error.pgError.8016.PG_SERVER_ERROR');
  }
  return i18n.t('error.errorMessage');
}

function transactionError({ code, name }) {
  if (code === 500) {
    if (name === 'CannotFindChargepointDocument') return i18n.t('error.transactionError.500.noCharger');
    if (name === 'CannotFindUserDocument') return i18n.t('error.transactionError.500.notFound');
    if (name === 'ExpiredIdTag') return i18n.t('error.transactionError.500.expired');
    if (name === 'UserNumberNotMatched') return i18n.t('error.transactionError.500.notMatch');
    if (name === 'PrePaymentResultFailed') return i18n.t('error.transactionError.500.failed');
  }
  return i18n.t('error.errorMessage');
}

export default {
  makeErrorMessage,
  pgError,
  transactionError,
};
