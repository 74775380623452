<template>
  <PointDetail/>
</template>

<script>
import PointDetail from '../../components/myAccount/point/PointDetail.vue';

export default {
  name: 'point',
  components: { PointDetail },
};

</script>
