import Vue from 'vue';

function emitter(eventName, body) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

export default {
  namespaced: true,
  state: {
    transactionData: null,
    evseInfo: null,
  },
  getters: {
    transactionData(state) {
      return state.transactionData;
    },
    evseInfo(state) {
      return state.evseInfo;
    },
  },
  mutations: {
    transactionData(state, value) {
      state.transactionData = value;
    },
    evseInfo(state, value) {
      state.evseInfo = value;
    },
  },
  actions: {
    'socket.transaction.progress.update': ({ commit }, resp) => {
      commit('transactionData', resp);
    },
    async transactionCheck({ commit }) {
      try {
        const response = await emitter('transaction.check', {});
        commit('transactionData', response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
