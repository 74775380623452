<template>
  <div class="login-btns d-flex flex-column align-center">
    <v-btn
      class="px-3 mb-3 login-btn"
      color="primary"
      depressed
      type="submit"
      @click="login"
      :disabled="email === '' || password === ''"
    >{{ $t('login.login') }}</v-btn>
    <v-btn
      class="px-3"
      @click="authGoogle"
      depressed
      outlined
      color="#aaa"
    >
      <img
        src="@/assets/images/social/google.png"
        style="height:20px; width: 20px;"
      />
      <span
        class="ml-1 black--text"
        style="width: 100%; text-align: center;"
      >
        {{ $t(`${this.$route.name}.google`) }}
      </span>
    </v-btn>
    <v-btn
      v-if="platform === 'ios'"
      class="mt-3 px-3"
      @click="authApple"
      depressed
      color="#eee"
    >
      <img src="@/assets/images/social/apple.png" height="20" width="20"/>
      <span
        class="ml-1 black--text"
        style="width: 100%; text-align: center;"
      >
        {{ $t(`${this.$route.name}.apple`) }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { getDeviceInfo } from '@/util/native';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import config from '@/config/config.json';

export default {
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    login: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    platform: null,
  }),
  methods: {
    ...mapActions({
      authorization: 'auth/authorization',
    }),
    ...mapMutations({
      socialUserEmail: 'signUpUser/socialUserEmail',
      socialUserSub: 'signUpUser/socialUserSub',
      socialUserType: 'signUpUser/socialUserType',
    }),
    async authGoogle() {
      this.socialUserType('google');
      try {
        const response = await GoogleAuth.signIn();
        await this.handleCallbackResponse({
          ...response,
          platform: 'google',
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    authApple() {
      if (this.platform === 'ios') {
        this.socialUserType('apple');
        SignInWithApple.authorize(config.apple)
          .then(async (result) => {
            const { response: { user, email, identityToken } } = result;
            let convertEmail = email;
            if (email == null) {
              const { email: decodeEmail } = JSON.parse(atob(identityToken.split('.')[1]));
              convertEmail = decodeEmail;
            }
            await this.handleCallbackResponse({
              email: convertEmail,
              id: user,
              platform: 'apple',
            }, email == null);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async handleCallbackResponse(data, already = false) {
      const { email, id: socialUid, platform } = data;
      let isAlready = already;
      // 구글의 경우 전달받은 이메일로 가입여부 확인 (애플은 앱과 계정에 연동되어있음)
      if (platform === 'google') {
        const emailCheck = await this.$emitter('user.email.check', { email });
        isAlready = emailCheck.data;
      }
      // 가입되어 있으면 로그인 처리
      if (isAlready === true) {
        try {
          const { token } = await this.$emitter('user.social.token.get', {
            email,
            socialUid,
            type: platform,
          });
          // 토큰 브라우저 스토리지 저장
          localStorage.setItem(`BLITZ.${this.companyInfo.code}.token`, token);
          this.authorization();
          this.$dialog.progress(false);
          this.$router.push('/');
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        // 아닐 경우 신규가입으로 이동
        this.socialUserEmail(email);
        this.socialUserSub(socialUid);
        this.$router.push({ path: '/signUp', query: { type: 'social' } });
      }
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      async handler(val) {
        if (val != null) {
          const { platform } = await getDeviceInfo();
          this.platform = platform;
          GoogleAuth.initialize({
            clientId: config.google.client_id,
            grantOfflineAccess: true,
            scopes: ['profile', 'email'],
          });
        }
      },
    },
  },
};
</script>
<style lang="scss">
.login-btns {
  max-width: 767px;
  width: 100%;
  position: fixed;
  bottom: 130px;
  button {width: 90%;}
}
</style>
