<template>
  <div class="customer-wrap px-5 overflow-y-auto pb-6">
    <Title
      :title="'nav.customer'"
      :isStyle="true"
    />
    <Language />
    <v-divider color="darkGrey"></v-divider>
    <div>
      <v-list
        v-for="(item, idx) in menus"
        :key="idx"
      >
        <v-list-item
          class="px-0 font-weight-bold"
          @click="movePage(item.path)"
        >
          <v-icon class="pr-3" :color="item.color">{{ item.icon }}</v-icon>
          <p>{{ $t(item.title) }}</p>
        </v-list-item>
      </v-list>
    </div>
    <v-divider color="darkGrey"></v-divider>
    <Company :companyInfo="companyInfo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '../common/Title.vue';
import Company from './company/Company.vue';
import Language from './language/Language.vue';

export default {
  components: { Title, Company, Language },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    menus: [
      {
        title: 'customer.notice.title', icon: 'mdi-bullhorn-outline', path: '/customer/notice', color: '#F9A825',
      },
      {
        title: 'customer.faq.title', icon: 'mdi-head-question-outline', path: '/customer/faq', color: '#424242',
      },
      {
        title: 'customer.issue.title', icon: 'mdi-tools', path: '/customer/issue', color: '#00796B',
      },
    ],
  }),
  methods: {
    movePage(path) {
      if (path === '/customer/issue' && !this.userInfo) this.$router.push('/login');
      else this.$router.push(path);
    },
  },
  watch: {
    companyInfo: {
      // immediate: true,
      // handler(val) {
      //   console.log(val);
      // },
    },
  },
};
</script>

<style lang="scss">
</style>
