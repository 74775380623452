<template>
  <ChargingHistoryDetail/>
</template>

<script>
import ChargingHistoryDetail from '../../components/myAccount/chargingHistory/ChargingHistoryDetail.vue';

export default {
  name: 'chargingHistory',
  components: { ChargingHistoryDetail },
};

</script>
