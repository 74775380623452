<template>
  <div class="faq-wrap px-5">
    <Title :title="'customer.faq.title'" :goBack="true"/>
    <div class="tel d-flex align-center justify-center">
      <font-awesome-icon icon="fa-solid fa-phone"/>
      <a :href="`tel:${telNumber}`"><p class="pl-2 black--text">{{ telNumber }}</p></a>
    </div>
    <div class="divider-sm"></div>
    <v-divider></v-divider>
    <div v-if="faqTotal != 0">
      <div class="search-wrap">
        <v-text-field
          dense
          rounded
          outlined
          :placeholder="$t('customer.faq.search')"
          @input="handleSearch"
          v-model="inputValue"
          ref="search"
          @focus="clearInput"
          @keyup.enter="goSearch"
        >
        </v-text-field>
        <button
          class="search-icon"
          @click="goSearch"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
      </div>
      <div class="tab-wrap
        w-100 d-flex flex-wrap justify-center align-center grey--text text--darken-1">
        <ul
          v-for="(tab, idx) in tabList"
          :key="idx"
          @click="selectedMenu(tab.title, idx)"
          :class="selected.id  === idx ? 'selected-tab' : 'tab-list'"
        >
          <li>{{ $t(`customer.faq.${tab.title}`) }}</li>
        </ul>
      </div>
      <div class="faq-list">
        <v-list>
          <v-list-group
            v-for="faq in faqList"
            :key="faq.title"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="q-mark primary--text pr-2">
                    <font-awesome-icon icon="fa-solid fa-q" />
                  </span>
                  <span>{{ faq.title.replace(/\[.*?\]/, '') }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item>
              <v-list-item-title>
                <span class="a-mark error--text pr-3">
                  <font-awesome-icon icon="fa-solid fa-a" />
                </span>
                <span
                  class="faq-a"
                  v-html="faq.content.includes('\n') ?
                  faq.content.replace(/\n/g, '<br>') :
                  faq.content">
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </div>
    <div v-else>
      <div class="history-nothing">
        <font-awesome-icon icon="fa-solid fa-file-signature" />
        <div>{{ $t('customer.noHistory') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    telNumber: '',
    inputValue: '',
    faqList: [],
    tab: null,
    tabList: [
      { title: 'all', value: '' },
      { title: 'charger', value: '' },
      { title: 'payment', value: '' },
      { title: 'certification', value: '' },
      { title: 'page', value: '' },
    ],
    selected: {
      id: 0,
      tab: null,
    },
    faqTotal: 0,
  }),
  methods: {
    selectedMenu(menu, idx) {
      this.selected = {
        id: idx,
        tab: menu,
      };
    },
    async getFaqList(tab) {
      try {
        this.faqList = [];
        const filter = [
          {
            where: 'and',
            condition: 'eq',
            key: 'boardCode',
            value: 'faq',
          },
        ];
        if (tab !== 'all') {
          filter.push({
            where: 'and',
            condition: 'inc',
            key: 'title',
            value: `[${tab}]`,
          });
        }
        const list = await this.$emitter('post.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: filter,
        });
        list.items.map(async (item) => {
          const detail = await this.$emitter('post.get', {
            no: item.no,
          });
          this.faqList.push(detail.item);
        });
        const summary = await this.$emitter('post.summary.get', { filters: filter });
        this.faqTotal = summary.data.total;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    pressEnter(e) {
      if (e.keyCode === 13) this.goSearch();
    },
    async goSearch() {
      if (this.inputValue.length !== 0) {
        try {
          const list = await this.$emitter('post.list.get', {
            page: 1,
            itemsPerPage: 0,
            filters: [
              {
                where: 'and',
                condition: 'eq',
                key: 'boardCode',
                value: 'faq',
              },
              {
                where: 'and',
                condition: 'inc',
                key: 'title',
                value: this.inputValue,
              },
            ],
          });
          if (list.length > 0) {
            list.items.map(async (item) => {
              const detail = await this.$emitter('post.get', {
                no: item.no,
              });
              this.faqList = [];
              this.faqList.push(detail.item);
              this.selected.id = 0;
            });
          } else {
            const noSearch = `${this.$t('alert.noSearch')}`;
            this.$dialog.alert('error', noSearch);
          }
          this.clearInput();
        } catch (error) {
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        const search = `${this.$t('alert.search')}`;
        this.$dialog.alert('warning', search);
        this.faqList = [];
        this.selected.id = -1;
      }
    },
    clearInput() {
      this.inputValue = '';
    },
    handleSearch(value) {
      this.inputValue = value;
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.telNumber = val.tel;
          this.getFaqList('all');
        }
      },
    },
    'selected.tab': {
      handler(val) {
        this.faqList = [];
        this.getFaqList(val);
      },
    },
  },
};
</script>

<style lang="scss">

.faq-wrap {
  position: absolute !important;
  overflow-y: scroll;
  .faq-title { font-size: 20px; }
  .divider-sm { bottom: 0; top: 7px;}
  .search-wrap{
    position: relative;
    .search-icon {
      position: absolute;
      right: 20px;
      bottom: 36px;
    }
  }
  .tab-wrap {
    cursor: pointer;
    .tab-list {padding: 0 20px; font-size: 1rem;}
    .selected-tab {
      text-decoration: underline;
      text-underline-offset: 10px;
      color: #0069B8;
      padding: 0 20px;
    }
  }
  .faq-list {
    .v-list-item__title {font-size: 0.9rem !important;}
    .faq-a {line-height: 2;}
    .v-list-group__items {background: #f8f3f3;}
  }
}
</style>
