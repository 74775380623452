<template>
  <div class="mypoint-wrap px-5">
    <div class="title-sticky">
      <Title :title="'myAccount.point.myPoint'" :goBack="true" />
      <div class="point-history-date w-100 d-flex justify-center">
        <button @click="moveMonth('prev')">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </button>
        <div class="px-5">{{ month }}</div>
        <button @click="moveMonth('next')">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </button>
      </div>
      <v-divider></v-divider>
      <div class="mypoint" v-if="userInfo != null">
        <p>{{ `${userInfo.name}${$t('user.`s')} ${$t('myAccount.point.point')}` }}</p>
        <p class="restPoints font-weight-bold">{{ userInfo.restPoints.toLocaleString() }} P</p>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="mypoint-detail">
      <p class="total grey--text text--lighten-1 pb-3">
        {{ $t('myAccount.chargingHistory.total', { total: total}) }}
      </p>
      <ul
        class="point-list pa-0"
        v-for="(item, index) in pointList"
        :key="index"
      >
        <li class="d-flex justify-space-between align-center">
          <div>
            <p class="point-type">{{ convertAction(item.action) }}</p>
            <p class="point-date grey--text text--darken-1 pb-3">
              {{ timeConvert.makeDays(item.created, $i18n, 'full') }}</p>
          </div>
          <div>
            <p
              class="point font-weight-bold"
              :class="item.amount < 0 ? 'primary--text' : 'red--text'"
            >
              {{ item.amount > 0 ?
                '+' + item.amount.toLocaleString() :
                item.amount.toLocaleString() }}
            </p>
          </div>
        </li>
        <v-divider v-if="index < pointList.length - 1"></v-divider>
      </ul>
      <div
        class="history-nothing w-100 d-flex align-center justify-center flex-column"
        v-if="total === 0"
      >
        <v-icon x-large class="black--text">mdi-point-of-sale</v-icon>
        <div class="pt-3 font-weight-bold">{{$t('myAccount.point.noPoint')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';
import { navigateMonth } from '@/util/calendar';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    timeConvert,
    month: timeConvert.getMonth(),
    total: 0,
    userNo: 0,
    restPoints: 0,
    purchaseAmount: 0,
    manualAmount: 0,
    pointList: [],
    transactionAmount: 0,
    filters: [
      {
        where: 'and',
        condition: 'gte',
        key: 'created',
        value: `${timeConvert.getMonth2()}-01 00:00:00`,
      },
      {
        where: 'and',
        condition: 'lte',
        key: 'created',
        value: `${timeConvert.getLastDay()} 23:59:59`,
      },
    ],
  }),
  methods: {
    async pointHistoryList(filter) {
      this.$dialog.progress(true);
      try {
        const response = await this.$emitter('point.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['created'],
          sortDesc: [true],
          filters: filter,
        });
        this.pointList = response.items;
        this.total = response.items.length;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    convertAction(action) {
      switch (action) {
        case 'transaction': return this.$t('myAccount.point.transaction');
        case 'purchase': return this.$t('myAccount.point.purchase');
        case 'event': return this.$t('myAccount.point.event');
        case 'manual': return this.$t('myAccount.point.manual');
        case 'accrual': return this.$t('myAccount.point.accumulate');
        default: return action;
      }
    },
    moveMonth(type) {
      const result = navigateMonth(type, this.month, this.filters, 'point');
      this.month = result.newDate;
      this.pointHistoryList(result.filters);
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(val) {
        if (val != null && this.userInfo != null) this.pointHistoryList(val);
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.pointHistoryList(this.filters);
          this.restPoints = val.restPoints;
          this.userNo = val.no;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.mypoint-wrap {
  position: absolute !important;
  overflow-y: scroll;
  .mypoint-header {
    height: 10%;
    .mypoint-title { font-size: 20px; }
  }
  .mypoint {
    p:nth-child(1) {font-size: 0.8rem;}
    .restPoints {font-size: 1.2rem;}
  }
  .mypoint-detail {
    .total { font-size: 0.8rem;}
    .point-list {
      li {
        .point-date { font-size: 0.8rem; }
        .point { font-size: 1.1rem; }
      }
    }
  }
}
</style>
