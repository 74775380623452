export const getBackgroundColor = (code) => {
  switch (code) {
    case '01': return '#F04557';
    case '02': return '#FAB019';
    case '03': case '16': return '#009e8e';
    case '04': return '#1d3885';
    case '06': return '#1c8fbb';
    case '07': return '#231f20';
    case '08': return '#ee1b24';
    case '11': return '#1a2d72';
    case '12': return '#fab711';
    case '15': case '17': return '#0282c9';
    case '22': return '#168dcf';
    default: return 'primary';
  }
};
