import timeConvert from '@/util/time';
import i18n from '../i18n';

// history 월 이동 함수 (type = prev, next, date = 2023.10)
export const navigateMonth = (type, date, filters, page) => {
  const [year, month] = date.split('.');
  let searchMonth = type === 'prev' ? +(month) - 1 : +(month) + 1;
  let searchYear = +(year);
  if (type === 'prev') {
    if (searchMonth < 1) {
      searchMonth = 12;
      searchYear -= 1;
    }
  } else if (searchMonth > 12) {
    searchMonth = 1;
    searchYear += 1;
  }

  // padStart(maxLength, 'fillString')는 문자열 길이를 maxLength로 유지하고, 앞에 fillString을 추가하는 함수
  const formattedMonth = searchMonth.toString().padStart(2, '0');
  const newDate = `${searchYear}.${formattedMonth}`;
  const startDate = `${searchYear}-${formattedMonth}-01 00:00:00`;
  const endDate = `${timeConvert.getLastDay(searchYear, formattedMonth)} 23:59:59`;
  let newFilters = [];
  if (page === 'charging') {
    newFilters = [
      { ...filters[0], value: startDate },
      { ...filters[1], value: endDate },
      { ...filters[2] },
    ];
  } else {
    newFilters = [
      { ...filters[0], value: startDate },
      { ...filters[1], value: endDate },
    ];
  }
  return {
    newDate, filters: newFilters,
  };
};

// 월 영어 약어로 변환 (date = 2023.10.10)
export const convertMonth = (date) => {
  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const language = i18n.locale;
  const monthIndex = +(date.split('.')[1]) - 1;
  if (language === 'en') return monthList[monthIndex];
  if (language === 'jp') return `${monthIndex + 1}月`;
  return `${monthIndex + 1}월`;
};

// 최근 3개월 (date = 2024-03)
export const getPrevMonth = (date) => {
  const currentDate = new Date(date);
  currentDate.setMonth(currentDate.getMonth() - 3);
  return currentDate.toISOString().slice(0, 7);
};
