<template>
  <div class="issue-wrap">
    <Title :title="'customer.issue.title'" :goBack="!writeIssue ? true : false" :class="'px-5'"/>
    <div
      class="d-flex align-center justify-end pa-2 write-issue"
      @click="writeIssue = !writeIssue;"
    >
      <div v-if="!writeIssue" class="pr-2">
        <v-btn :ripple="false" text depressed>{{ $t('customer.issue.write') }}</v-btn>
      </div>
      <div v-else><v-icon class="pr-5">mdi-close</v-icon></div>
    </div>
    <SubmitIssue
      v-if="writeIssue"
      :writeIssue.sync="writeIssue"
      :getPostList="getPostList"
    />
    <div v-else class="issue-list">
      <v-list v-if="isData">
        <v-list-item-group>
          <div
            v-for="(item, index) in myPostList"
            :key="item.date"
          >
            <v-list-item @click="goToPostDetail(item)">
              <div class="d-flex justify-space-between align-center w-100 px-3">
                <div class="py-1">
                  <p class="text--secondary text-body-2">
                    {{ $t('customer.issue.chargeNo') }}</p>
                  <p class="pt-1">{{ item.title }}
                    <v-icon v-if="item.fileCount > 0" small>mdi-paperclip</v-icon>
                  </p>
                </div>
                <p class="text--secondary text-body-2">{{ time.makeTime(item.created) }}</p>
              </div>
            </v-list-item>
            <v-divider
              class="divider mx-2 my-0 pa-0"
              v-if="index < myPostList.length - 1"
              :key="index"
            ></v-divider>
          </div>
        </v-list-item-group>
        <v-pagination
          class="pt-3"
          v-model="page"
          :length="pageLength"
          circle
          @input="updatePage($event)"
        ></v-pagination>
      </v-list>
      <v-list v-else>
        <div class="history-nothing">
          <font-awesome-icon icon="fa-solid fa-file-signature" />
          <div>{{ $t('customer.issue.noHistory') }}</div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import Title from '../../common/Title.vue';
import SubmitIssue from './SubmitIssue.vue';

export default {
  components: { Title, SubmitIssue },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    time,
    myPostList: [],
    isData: false,
    writeIssue: false,
    page: 1,
    pageLength: 0,
  }),
  methods: {
    /* eslint no-underscore-dangle: 0 */
    async getPostList() {
      try {
        const filter = [
          {
            where: 'and',
            condition: 'inc',
            key: 'boardCode',
            value: 'problem',
          },
          {
            where: 'and',
            condition: 'eq',
            key: 'creatorUserNo',
            value: this.userInfo.no,
          },
        ];
        const summary = await this.$emitter('post.summary.get', { filters: filter });
        this.pageLength = Math.ceil(summary.data.total / 10);
        const list = await this.$emitter('post.list.get', {
          page: this.page,
          itemsPerPage: 10,
          filters: filter,
          sortBy: ['created'],
          sortDesc: [true],
        });
        this.myPostList = list.items;
        if (this.myPostList.length > 0) this.isData = true;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    updatePage(e) {
      this.page = e;
      this.getPostList();
    },
    goToPostDetail(item) {
      this.$router.push(`/customer/issue/${item.no}`);
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getPostList();
        }
      },
    },
    writeIssue: {
      immediate: true,
      handler() {
        this.getPostList();
      },
    },
  },
};
</script>

<style lang="scss">
.issue-wrap {
  .write-issue { height: 40px; }
  .issue-list {
    height: calc(100% - 12vh - 40px);
    overflow-y: scroll;
  }
  .divider {
    border-color: #d9d9d9;
    opacity: inherit;
  }
}
</style>
