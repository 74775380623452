<template>
  <div
    class="point-box rounded-lg d-flex flex-column align-center justify-center"
    @click="$router.push('/account/point')"
  >
    <div class="pb-2 d-flex align-center">
      <v-icon color="yellow darken-1">mdi-alpha-p-circle</v-icon>
      <p class="pl-1">{{ $t('myAccount.point.point')}}</p>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
    <p class="text-h5 font-weight-bold">
      {{ userInfo.restPoints > 0 ? userInfo.restPoints.toLocaleString() : 0}} P
    </p>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.point-box {
  height: 150px;
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>
