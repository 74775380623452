import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ko from 'vuetify/lib/locale/ko';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0069B8',
        secondary: '#ccc',
        accent: '#AA6063',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        blitz: '#0069B8',
        lightGrey: '#F9F9F9',
        darkGrey: '#D9D9D9',
        lightGrey2: '#b3b0b0',
        navyBlue: '#02375f',
      },
    },
  },
  lang: {
    locales: { ko },
    current: 'ko',
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
});
