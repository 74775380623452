<template>
  <div class="pt-4 d-flx justify-space-between align-center">
    <div class="d-flex justify-start align-center">
      <p class="font-weight-bold mr-1"> {{ $t('myAccount.pnc.title') }}</p>
    </div>
    <p class="text-small">
      <v-icon small color="success" style="cursor: default">mdi-alert-circle-outline</v-icon>
      PNC(Plug and Charge): {{ $t('myAccount.pnc.explain') }}
    </p>
    <div class="pnc-box rounded-lg mt-2 px-4 d-flex justify-start align-center">
      <v-switch
        color="success"
        class="mr-6"
        hide-detail
        inset
        :label="$t('myAccount.pnc.activate')"
        v-model="pncMode"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    pncMode: false,
  }),
  methods: {
    async updatePnc() {
      this.$dialog.progress(true);
      try {
        await this.$emitter('user.update', {
          pncConfig: {
            ...this.userInfo.pncConfig,
            active: this.pncMode,
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val !== null) this.pncMode = val.pncConfig.active;
      },
    },
    pncMode: {
      handler() {
        this.updatePnc();
      },
    },
  },
};
</script>

<style lang="scss">
.text-small {
  font-size: .85rem;
  color: #555;
}
.pnc-box {
  height: 50px;
  border: 1px solid #ccc;
  .v-label {
    margin-left: -8px;
  }
}
</style>
