export const searchAddress = (callback) => {
  const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
  new window.daum.Postcode({
    oncomplete: (data) => {
      const result = {
        zipCode: '',
        address: '',
        extraAddr: '',
      };
      if (data.userSelectedType === 'R') { // 도로명 주소를 선택했을 경우
        result.address = data.roadAddress;
      } else { // 지번 주소를 선택했을 경우 (J)
        result.address = data.jibunAddress;
      }

      // 도로명 타입일때 참고항목 조합
      if (data.userSelectedType === 'R') {
        // 동,로,가 로 끝날때
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          result.extraAddr += data.bname;
        }
        // 건물명 있고 공동주택일 때
        if (data.buildingName !== '' && data.apartment === 'Y') {
          result.extraAddr += (result.extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        // 표시할 참고항목이 있을 때, 괄호까지 추가한 최종 문자열 만든다
        if (result.extraAddr !== '') {
          result.extraAddr = ` (${result.extraAddr})`;
        }
      }

      // 우편번호와 주소 정보를 해당 필드에
      result.zipCode = data.zonecode;

      const elementWrap = document.getElementById('wrap');
      elementWrap.style.display = 'none';

      document.body.scrollTop = currentScroll;
      callback(result);
    },
    onresize: (size) => {
      const elementWrap = document.getElementById('wrap');
      elementWrap.style.height = `${size.height}px`;
    },
    width: '100%',
    height: '100%',
  }).embed(document.getElementById('wrap'));

  const elementWrap = document.getElementById('wrap');
  elementWrap.style.display = 'block';
};
