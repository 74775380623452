<template>
  <div class="signUp-step3">
    <v-form
      v-if="inputDetail || parameterValue === 'social'"
      class="signUp-form pt-2 px-5"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div>
        <p>{{$t('user.name')}}</p>
        <v-text-field
          class="pa-0 ma-0"
          v-model="userName"
          :rules="rules('common')"
        ></v-text-field>
      </div>
      <div>
        <p>{{$t('user.e-mail')}}</p>
        <v-text-field
          class="pa-0 ma-0"
          v-if="socialUserEmail !== null"
          v-model="socialUserEmail"
          :rules="rules('common')"
        ></v-text-field>
        <v-text-field
          v-else
          class="pa-0 ma-0"
          v-model="userEmail"
          :rules="rules('common')"
        ></v-text-field>
      </div>
      <div>
        <p>{{$t('user.mobile')}}</p>
        <v-text-field
          class="pa-0 ma-0"
          v-model="mobile"
          :rules="rules('mobile')"
        ></v-text-field>
      </div>
      <div v-if="!socialUserEmail">
        <p>{{$t('user.password')}}</p>
        <v-text-field
          class="pa-0 ma-0"
          v-model="password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="rules('password')"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :placeholder="$t('login.signUp.pwRule')"
          autocomplete="on"
        ></v-text-field>
        <p>{{$t('user.passwordConfirm')}}</p>
        <v-text-field
          class="pa-0 ma-0"
          v-model="passwordConfirm"
          :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="rules('common')"
          :type="showConfirm ? 'text' : 'password'"
          @click:append="showConfirm = !showConfirm"
          autocomplete="on"
        >
        </v-text-field>
        <p
          class="v-messages theme--light error--text dismatch pa-0"
          v-if="passwordConfirm.length > 0 && !isMatch(password, passwordConfirm)"
        >{{ $t('rules.matchedPwd') }}</p>
      </div>
      <v-container class="pa-0">
        <div
          v-for="(item, idx) in checkedItems"
          :key="idx"
        >
          <v-checkbox
            v-model="item.checked"
            :label="$t(`login.signUp.${item.type}`)"
            dense
            hide-details="false"
            @change="openDialog(item)"
          ></v-checkbox>
        </div>
      </v-container>
    </v-form>
    <BottomBtn
      v-if="inputDetail || parameterValue"
      :btnCnt="1"
      :btnText="['button.join']"
      :callback="submit"
      :isDisabled="socialUserEmail === null ?
        userName && mobile && password && passwordConfirm === ''
        || !selectedTypes.includes('privacyPolicy')
        || !selectedTypes.includes('termsOfService')
        : userName && mobile && email === ''
      "
    />
    <v-dialog
      v-if="selectedItem"
      v-model="dialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title
          class="text-subtitle-1 font-weight-bold grey lighten-2">
          {{ $t(`login.signUp.${selectedItem.type}`) }}
        </v-card-title>
        <v-card-text style="white-space: pre-wrap;">
          {{ companyInfo[selectedItem.type] }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    inputEmailCode: {
      type: Boolean,
    },
    inputDetail: {
      type: Boolean,
    },
    parameterValue: {
      type: String,
    },
    email: {
      type: String,
    },
    token: {
      type: String,
    },
    companyInfo: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      userEmail: 'signUpUser/userEmail',
      userEmailToken: 'signUpUser/userEmailToken',
      socialUserEmail: 'signUpUser/socialUserEmail',
      socialUserSub: 'signUpUser/socialUserSub',
      socialUserType: 'signUpUser/socialUserType',
    }),
    rules() {
      return (type) => {
        const common = [(v) => !!v || this.$t('rules.require')];
        const mobile = [(v) => /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || this.$t('rules.mobile')];
        const password = [(v) => /(?=.*?[0-9])(?=.*?[~?!@#$%^&*_-]).{10,16}$/.test(v)
      || this.$t('rules.password')];
        if (type === 'common') return common;
        if (type === 'mobile') return [...common, ...mobile];
        if (type === 'password') return [...common, ...password];
        return '';
      };
    },
  },
  data: () => ({
    valid: true,
    show: false,
    showConfirm: false,
    userName: '',
    mobile: '',
    password: '',
    passwordConfirm: '',
    checkedItems: [
      { checked: false, type: 'privacyPolicy' },
      { checked: false, type: 'termsOfService' },
    ],
    selectedItem: null,
    dialog: false,
    selectedTypes: [],
  }),
  methods: {
    async submit() {
      const validation = this.$refs.form.validate();
      if (validation) {
        if (this.socialUserEmail) {
          try {
            await this.$emitter('user.social.token.get', {
              email: this.socialUserEmail,
              socialUid: this.socialUserSub,
              type: this.socialUserType,
              name: this.userName,
              phone: this.mobile,
              locale: this.$i18n.locale,
            });
            this.$dialog.loginModal(true, 'login.signUp.registrated');
            this.$dialog.confirm();
          } catch (error) {
            console.error(error);
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        } else {
          try {
            await this.$emitter('user.signup', {
              token: this.userEmailToken,
              name: this.userName,
              phone: this.mobile,
              password: this.password,
              locale: this.$i18n.locale,
            });
            this.$dialog.loginModal(true, 'login.signUp.registrated');
            this.$dialog.confirm();
          } catch (error) {
            this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          }
        }
      } else {
        console.error(`${this.$t('error.errorMessage')}`);
      }
      this.$emit('update:inputEmailCode', false);
      this.$emit('update:inputDetail', false);
    },
    handlePassword(model, value) {
      this.password.map((item) => {
        if (item.model === model) {
          this[item.model] = value;
        }
        return value;
      });
    },
    isMatch(password, passwordConfirm) {
      return password === passwordConfirm;
    },
    openDialog(item) {
      this.selectedItem = item;
      if (item.checked && item.type !== 'push') {
        this.dialog = !this.dialog;
      }
    },
    isChecked() {
      this.selectedTypes = this.checkedItems
        .filter((item) => item.checked)
        .map((item) => item.type);
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != null) this.isChecked();
      },
    },
  },
};
</script>

<style lang="scss">
.signUp-step3 {
  .back {cursor: pointer;}
  .signUp-form {
    height: 70vh;
    overflow-y: scroll;
    .v-input { font-size: 15px;}
    .dismatch {position: relative; bottom: 16px;}
  }
}
</style>
