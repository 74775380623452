<template>
  <div class="map-wrap">
    <Map
      v-if="companyInfo != null"
      :transactionData="transactionData"
      :currency="currency"
      :companyInfo="companyInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Map from './map/Map.vue';

export default {
  components: { Map },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
      transactionData: 'transaction/transactionData',
    }),
  },
  data: () => ({
    transactionStatus: {},
    currency: '',
  }),
  methods: {
    ...mapMutations({
      transactionDataChange: 'transaction/transactionData',
    }),
    async checkTransaction() {
      const res = await this.$emitter('transaction.check', {});
      this.transactionDataChange(res.data);
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.checkTransaction();
        }
      },
    },
    '$i18n.locale': {
      immediate: true,
      handler(value) {
        const langMap = {
          en: 'en',
          jp: 'ja',
          ko: 'ko',
        };
        if (langMap[value] !== this.$googleMapOptions.load.language) window.location.reload();
      },
    },
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          switch (val.currencyUnit) {
            case 'krw': this.currency = this.$t('common.krw'); break;
            case 'usd': this.currency = this.$t('common.usd'); break;
            case 'jpy': this.currency = this.$t('common.jpy'); break;
            default: break;
          }
        }
      },
    },
  },
};
</script>
