<template>
  <v-dialog
    v-model="show"
    fullscreen
    persistent
  >
    <v-card class="remote-wrap h-100">
      <div class="timeout d-flex justify-end pa-5 pt-10">
        <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        :width="3"
        right
        >{{ timeOut }}</v-progress-circular>
      </div>
      <div class="connector-in d-flex flex-column align-center justify-center">
        <img src="@/assets/images/connector/plug_in.gif" alt="" class="connect-icon">
        <pre><p class="font-wight-bold">{{ $t('map.remoteStart.connectorIn')}}</p></pre>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      transactionData: 'transaction/transactionData',
      evseInfo: 'transaction/evseInfo',
    }),
  },
  data: () => ({
    timeOut: 0,
    failureStart: false,
  }),
  methods: {
    ...mapMutations({
      changeTransactionData: 'transaction/transactionData',
    }),
    ...mapActions({
      transactionCheck: 'transaction/transactionCheck',
    }),
    startCountdown() {
      const interval = setInterval(() => {
        if (this.timeOut > 0) {
          this.timeOut -= 1;
        } else {
          clearInterval(interval);
          this.transactionCheck();
        }
      }, 1000);
    },
  },
  watch: {
    'transactionData.status': {
      immediate: true,
      handler(val) {
        if (val != null) {
          if (val === 'Accepted') this.$dialog.progress(false);
          this.timeOut = this.evseInfo.timeOut === undefined ? 120 : this.evseInfo.timeOut;
          this.startCountdown();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.remote-wrap {
  overflow-x: hidden;
  .connect-icon { width: 200px; }
  .timeout { height: 10%; }
  .connector-in {
    height: 70%;
    p {font-size: 1.2rem;}
  }
}
</style>
