import Vue from 'vue';

function emitter(eventName, body) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

export default {
  namespaced: true,
  state: {
    notification: null,
    unReadCount: null,
  },
  getters: {
    notification(state) {
      return state.notification;
    },
    unReadCount(state) {
      return state.unReadCount;
    },
  },
  mutations: {
    notification(state, value) {
      state.notification = value;
    },
    unReadCount(state, value) {
      state.unReadCount = value;
    },
  },
  actions: {
    'socket.notification.send': ({ commit }, resp) => {
      commit('notification', resp);
    },
    async notificationSummary({ commit }) {
      try {
        const response = await emitter('notification.summary.get', {});
        commit('unReadCount', response.data.unReadCount);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
