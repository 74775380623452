<template>
  <TestSection/>
</template>

<script>
import TestSection from '../../components/test/TestSection.vue';

export default {
  components: { TestSection },
};
</script>
