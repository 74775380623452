import { Filesystem } from '@capacitor/filesystem';
import { Device } from '@capacitor/device';
import { FileOpener } from '@capacitor-community/file-opener';
import store from '@/store';
import config from '../config/config.json';

const payloadUnit = 1500;

function combineBuffer(buffers) {
  const size = buffers.reduce((total, buf) => (total + buf.byteLength), 0);
  const bytes = new Uint8Array(size);

  let offset = 0;
  buffers.forEach((buf) => {
    bytes.set(new Uint8Array(buf), offset);
    offset += buf.byteLength;
  });

  return bytes;
}

function downloadFile(bytes, filename) {
  const blob = new Blob([bytes]);
  const url = URL.createObjectURL(blob);

  const tag = document.createElement('a');
  tag.href = url;
  tag.download = filename;

  document.body.appendChild(tag);

  tag.style.display = 'none';
  tag.click();
  tag.remove();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 1000);
}

async function downloadFileNative(bytes, filename) {
  await store.commit('dialog/progress', true);
  try {
    const blob = new Blob([bytes]);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {
      const { uri } = await Filesystem.writeFile({
        path: `/${filename}`,
        data: reader.result,
        directory: 'DATA',
        recursive: true,
      });
      await FileOpener.open({ filePath: uri });
      await store.commit('dialog/progress', false);
    };
  } catch (error) {
    await store.commit('dialog/progress', false);
    console.error(error);
  }
}

export default {
  async readFileToPayload(file) {
    const data = [];
    const buf = await file.arrayBuffer();
    const last = buf.byteLength;

    for (let idx = 0; idx < last; idx += payloadUnit) {
      data.push(buf.slice(idx, idx + payloadUnit));
    }

    return {
      name: file.name,
      size: buf.byteLength,
      data,
    };
  },
  imagePathToSrc(path) {
    return `${config.server.path}${path.substring(1)}`;
  },
  imagePathName(path) {
    return path.split('/')[3];
  },
  async writeFileFromPayload(payload) {
    const data = combineBuffer(payload.data);
    const { platform } = await Device.getInfo();
    if (platform === 'android' || platform === 'ios') await downloadFileNative(data, payload.name);
    else downloadFile(data, payload.name);
  },
  arrayBufferToBase64(data) {
    const contentType = 'image/png';
    const blob = new Blob(data, { type: contentType });
    return window.URL.createObjectURL(blob);
  },
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  byteTrans(x) {
    const units = ['byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && (l + 1)) {
      n /= 1024;
      l += 1;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  },
};
