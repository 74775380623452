<template>
  <LoginSection/>
</template>

<script>
import LoginSection from '../../components/login/LoginSection.vue';

export default {
  name: 'Login',
  components: { LoginSection },
};

</script>
