import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dateFormats = {
  month: 'YYYY.MM',
  month2: 'YYYY-MM',
  date: 'YYYY-MM-DD',
  date2: 'MM/DD',
  min: 'YYYY-MM-DD HH:mm',
  sec: 'YYYY-MM-DD HH:mm:ss',
  timestamp: 'YYYY-MM-DDTHH:mm:ss.000Z',
  zuluTimestamp: 'YYYY-MM-DDTHH:mm:ss.000[Z]',
  countDown: 'mm:ss',
};

function timeGetter({ date = new Date(), type, format = dateFormats.timestamp }) {
  if (type === 'utc') {
    const formatConvert = format === dateFormats.timestamp ? dateFormats.zuluTimestamp : format;
    return dayjs.utc(date).format(formatConvert);
  }
  return dayjs(date).format(format);
}

export default {
  getDate(type = 'local') {
    return timeGetter({ type, format: dateFormats.date });
  },
  getMonth(type = 'local') {
    return timeGetter({ type, format: dateFormats.month });
  },
  getMonth2(type = 'local') {
    return timeGetter({ type, format: dateFormats.month2 });
  },
  getTime(type = 'local') {
    return timeGetter({ type, format: dateFormats.sec });
  },
  getTimestamp(type = 'local') {
    return timeGetter({ type });
  },
  makeDate(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.date });
  },
  makeDate2(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.date2 });
  },
  makeTime(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.sec });
  },
  makeTimestamp(date, type = 'local') {
    return timeGetter({ date, type });
  },
  makeMin(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.min });
  },

  // 요일 찾기
  makeDays(date, i18n, type) {
    const day = new Date(date).getDate();
    const dayOfWeek = new Date(date).getDay();
    const lang = i18n.locale;
    const weekKo = ['일', '월', '화', '수', '목', '금', '토'];
    const weekJp = ['日', '月', '火', '水', '木', '金', '土 '];
    const weekEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if (type === 'full') {
      switch (lang) {
        case 'ko': return `${this.makeMin(date)} (${weekKo[dayOfWeek]})`;
        case 'jp': return `${this.makeMin(date)} (${weekJp[dayOfWeek]})`;
        case 'en': return `${this.makeMin(date)} (${weekEn[dayOfWeek]})`;
        default: break;
      }
    } else if (type === 'simple') {
      if (lang === 'ko') return `${day}일 ${weekKo[dayOfWeek]}요일`;
      if (lang === 'en') {
        if (day === 1) return `${day}st ${weekEn[dayOfWeek]}`;
        if (day === 2) return `${day}nd ${weekEn[dayOfWeek]}`;
        if (day === 3) return `${day}rd ${weekEn[dayOfWeek]}`;
        return `${day}th ${weekEn[dayOfWeek]}`;
      }
      if (lang === 'jp') return `${day}日 ${weekJp[dayOfWeek]}曜日`;
    }
    return '';
  },

  // 끝 - 시작 시간 계산
  makeCalcTime(start, end) {
    const startT = new Date(start);
    const endT = new Date(end);
    if (startT < endT) {
      const diff = dayjs(endT).diff(dayjs(startT), 'millisecond');
      const duration = diff / 1000; // 초 단위로 변환

      const formattedDiff = [
        Math.floor(duration / 3600).toString().padStart(2, '0'), // 시간
        Math.floor((duration % 3600) / 60).toString().padStart(2, '0'), // 분
        Math.floor(duration % 60).toString().padStart(2, '0'), // 초
      ].join(':');

      return formattedDiff;
    }
    return '-- : -- : --';
  },
  getLastDay(year, month) {
    if (year === undefined && month === undefined) {
      const date = this.getMonth2().split('-');
      const lastDay = new Date(date[0], date[1], 0);
      return `${this.makeDate(lastDay)}`;
    }
    const lastDay = new Date(year, month, 0);
    return `${this.makeDate(lastDay)}`;
  },

  // 카운트다운
  makeCountDown(sec) {
    const minutes = Math.floor(sec / 60).toString().padStart(2, '0');
    const seconds = (sec % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  },

  // 오늘 확인
  isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate()
      && date.getMonth() === today.getMonth()
      && date.getFullYear() === today.getFullYear()
    );
  },
  // 게시글 시간
  displayedAt(updated, i18n) {
    const updatedDate = new Date(updated);
    if (this.isToday(updatedDate)) {
      const milliSeconds = new Date() - updatedDate;
      const seconds = milliSeconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;

      const lang = i18n.locale;
      if (lang === 'ko') {
        if (seconds < 60) return '방금 전';
        if (minutes < 60) return `${Math.floor(minutes)}분 전`;
        if (hours < 24) return `${Math.floor(hours)}시간 전`;
      } else if (lang === 'jp') {
        if (seconds < 60) return '今';
        if (minutes < 60) return `${Math.floor(minutes)}分前`;
        if (hours < 24) return `${Math.floor(hours)}時間前`;
      } else if (lang === 'en') {
        if (seconds < 60) return 'now';
        if (minutes < 60) return `${Math.floor(minutes)}m ago`;
        if (hours < 24) return `${Math.floor(hours)}h ago`;
      }
    }
    return this.makeMin(updated);
  },
};
