<template>
  <Faq/>
</template>

<script>
import Faq from '../../components/customerCenter/faq/Faq.vue';

export default {
  name: 'faq',
  components: { Faq },
};

</script>
