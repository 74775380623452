<template>
  <Profile/>
</template>

<script>
import Profile from '../../components/myAccount/profile/Profile.vue';

export default {
  name: 'profile',
  components: { Profile },
};

</script>
