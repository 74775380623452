<template>
  <div class="px-5">
    <Title
      :title="'customer.issue.title'"
      :goBack="true"
    />
    <div v-if="selectedPost">
      <v-card class="detail">
        <v-card-title
          class="post-date my-2 px-4 py-0 justify-space-between grey--text text--darken-1"
        >
          <p>{{ userInfo.name }}</p>
          <p>{{ time.makeMin(selectedPost.created) }}</p>
        </v-card-title>
        <v-divider class="divider my-2 pa-0"></v-divider>
        <v-card-text
          class="post-body mt-2"
          v-if="!selectedPost.content.includes('<img')"
        >
          <p>{{ selectedPost.content }}</p>
          <p class="pt-2 font-weight-bold">
            {{$t('customer.issue.chargeNo')}} : {{ selectedPost.title }}</p>
          <div class="post-imgs d-flex flex-column">
            <img
              class="w-50 h-50 py-2"
              v-for="img in images"
              :key="img"
              :src="img"
              alt=""
            >
          </div>
        </v-card-text>
        <v-card-text class="post-body" v-else>
          <Viewer
            ref="viewer"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
// eslint-disable-next-line import/no-cycle
import fileConverter from '@/util/file';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/vue-editor';
import Title from '../../common/Title.vue';

export default {
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  components: { Viewer, Title },
  data: () => ({
    time,
    images: [],
    selectedPost: null,
  }),
  methods: {
    async getSelectedPost() {
      const postId = this.$route.params.id;
      const detail = await this.$emitter('post.get', {
        no: +(postId),
      });
      this.selectedPost = detail.item;
    },
    async fileRead() {
      try {
        await Promise.all(
          this.selectedPost.files.map(async (item) => {
            const result = await this.$emitter('file.read', {
              no: item.no,
            });
            this.images.push(fileConverter.arrayBufferToBase64(result.item.data));
          }),
        );
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getSelectedPost();
        }
      },
    },
    selectedPost: {
      handler(val) {
        if (val.files.length > 0) {
          this.fileRead();
        }
        if (val.content.includes('<img')) {
          this.$nextTick(() => {
            this.$refs.viewer.invoke('setMarkdown', val.content);
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
.detail {
  height: 90%;
  box-shadow: none !important;
  .post-date { font-size: 13px; }
  .divider {
    border-color: #d9d9d9;
    opacity: inherit;
  }
  .post-body { white-space: pre-wrap; }
}
</style>
