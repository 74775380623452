<template>
  <div class="search-pw-wrap h-100">
    <div class="go-back d-flex align-center my-6 px-5" @click="goBack">
      <font-awesome-icon icon="fa-solid fa-angle-left" />
    </div>
    <div class="search-pw d-flex align-center justify-start flex-column">
      <div class="logo py-5"><img :src="logo"></div>
      <div class="input-email w-100" v-if="!stepTwo">
        <p class="search-title d-flex justify-center pb-8 font-weight-bold">
          {{$t('login.searchPW.email')}}</p>
        <div class="search-email w-100 pa-4">
          <v-text-field
            v-model="email"
            :rules="rules"
            outlined
            dense
            prepend-inner-icon="mdi-account-outline"
            @keyup.enter="searchPW"
          ></v-text-field>
        </div>
        <BottomBtn
          :btnText="['login.searchPW.search']"
          :callback="searchPW"
          :btnCnt="1"
        />
      </div>
      <EmailCode
        v-if="stepTwo"
        :email="email"
        :stepTwo.sync="stepTwo"
        :stepThree.sync="stepThree"
        @email-token="handleToken"
      />
      <ChangePassword
        v-if="stepThree"
        :stepThree.sync="stepThree"
        :token="token"
      />
    </div>
  </div>
</template>

<script>
import fileConverter from '@/util/file';
import BottomBtn from '../../common/BottomBtn.vue';
import EmailCode from './EmailCode.vue';
import ChangePassword from './ChangePassword.vue';

export default {
  components: { BottomBtn, EmailCode, ChangePassword },
  props: {
    openSearch: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    rules() {
      return [(v) => /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/.test(v) || this.$t('rules.email')];
    },
  },
  created() {
    this.getLogo();
  },
  data: () => ({
    logo: '',
    email: '',
    stepTwo: false,
    stepThree: false,
    token: '',
  }),
  methods: {
    goBack() {
      this.$emit('update:openSearch', false);
    },
    async getLogo() {
      try {
        const res = await this.$emitter('sysconf.company.get', {});
        this.logo = fileConverter.imagePathToSrc(res.item.logoPath);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async searchPW() {
      try {
        await this.$emitter('user.email.auth.send', {
          action: 'password',
          email: this.email,
        });
        this.stepTwo = !this.stepTwo;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        this.email = '';
      }
    },
    handleToken(value) {
      this.token = value;
    },
  },
};
</script>

<style lang="scss">
.search-pw-wrap {
  .go-back {
    height: 5%;
    cursor: pointer;
  }
  .search-pw {
    height: 95%;
    .input-email {
      width: 80%;
      .search-btn .v-btn {height: 45px;}
    }
  }
}
</style>
