<template>
  <div>
    <div class="sigup-step2 wrap mt-11 px-5 text-subtitle-1" v-if="inputEmailCode && !inputDetail">
      <pre><p>{{$t('login.signUp.emailCode')}}</p></pre>
      <div>
        <v-icon x-large style="z-index: 1;">mdi-email-fast</v-icon>
      </div>
      <div class="divider darkGrey"></div>
      <pre><p class="signup-info">{{$t('login.signUp.emailCodeInfo')}}</p></pre>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          :label="$t('login.signUp.codeConfirm')"
          id="click-box"
          type="number"
          v-model="code"
          :rules="rules"
          @keyup.enter="next"
        >
          <template v-slot:append>
            <div class="countdown">{{ timeConvert.makeCountDown(countdown) }}</div>
          </template>
        </v-text-field>
      </v-form>
    </div>
    <BottomBtn
      v-if="inputEmailCode && !inputDetail"
      :btnCnt="1"
      :btnText="['button.next']"
      :callback="goInputDetail"
      :isDisabled="code === ''"
    />
    <div class="alert">
      <v-row justify="center">
        <v-dialog
          v-model="alert"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <pre><p class="msg">{{$t('login.signUp.message')}}</p></pre>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                depressed
                @click="alert = false"
              >
                CLOSE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import timeConvert from '@/util/time';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { BottomBtn },
  props: {
    inputEmailCode: {
      type: Boolean,
    },
    inputDetail: {
      type: Boolean,
    },
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userEmail: 'signUpUser/userEmail',
    }),
    rules() {
      return [(v) => !!v || this.$t('rules.require')];
    },
  },
  data: () => ({
    timeConvert,
    countdown: 600,
    valid: true,
    alert: false,
    code: '',
  }),
  methods: {
    ...mapMutations({
      userEmailTokenUpdate: 'signUpUser/userEmailToken',
    }),
    startCountdown() {
      setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        }
      }, 1000);
    },
    async goInputDetail() {
      const validation = this.$refs.form.validate();
      if (validation) {
        try {
          const response = await this.$emitter('user.email.auth.verify', {
            email: this.userEmail,
            code: this.code,
          });
          this.$emit('update:inputEmailCode', false);
          this.$emit('update:inputDetail', true);
          this.userEmailTokenUpdate(response.token);
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
          this.alert = true;
        }
      }
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.startCountdown();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.back {
  font-size: 20px;
  cursor: pointer;
}
.sigup-step2 {
  .signup-info {
    font-size: 14px;
  }
}
</style>
