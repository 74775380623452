<template>
  <HomeSection/>
</template>

<script>
import HomeSection from '../components/home/HomeSection.vue';

export default {
  name: 'Home',
  components: { HomeSection },
};
</script>
