import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Device } from '@capacitor/device';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import config from '../config/config.json';
import router from '../router';
import i18n from '../i18n';

export const requestNotificationPermission = async () => {
  const { receive } = await FirebaseMessaging.requestPermissions();
  return receive;
};
export const checkNotificationPermission = async () => {
  const { receive } = await FirebaseMessaging.checkPermissions();
  return receive;
};
export const getDeviceInfo = async () => {
  const { platform, osVersion } = await Device.getInfo();
  return {
    platform,
    osVersion,
  };
};
export const getFCMToken = async () => {
  const { token } = await FirebaseMessaging.getToken({
    vapidKey: config.firebase.vapidKey,
  });
  return token;
};

export default {
  async iPhoneNotchChecker() {
    const notchModels = [
      'iPhone10,3', // iPhone X
      'iPhone11,2', // iPhone XS
      'iPhone11,6', // iPhone XS Max
      'iPhone11,8', // iPhone XR
      'iPhone12,1', // iPhone 11
      'iPhone12,3', // iPhone 11 Pro
      'iPhone12,5', // iPhone 11 Pro Max
      'iPhone13,1', // iPhone 12 mini
      'iPhone13,2', // iPhone 12
      'iPhone13,3', // iPhone 12 Pro
      'iPhone13,4', // iPhone 12 Pro Max
      'iPhone14,4', // iPhone 13 mini
      'iPhone14,5', // iPhone 13
      'iPhone14,2', // iPhone 13 Pro
      'iPhone14,3', // iPhone 13 Pro Max
      'iPhone14,7', // iPhone 14
      'iPhone14,8', // iPhone 14 Plus
      'iPhone15,2', // iPhone 14 Pro
      'iPhone15,3', // iPhone 14 Pro Max
      'iPhone15,4', // iPhone 15
      'iPhone15,5', // iPhone 15 Plus
      'iPhone16,1', // iPhone 15 Pro
      'iPhone16,2', // iPhone 15 Pro Max
    ];
    const { platform, model } = await Device.getInfo();
    if (platform === 'ios') {
      const isNotchModel = notchModels.includes(model);
      if (isNotchModel) document.getElementById('app').classList.add('ios-notch-wrap');
    }
  },
  async firebaseInit() {
    // 모바일일 경우 권한요청
    const isMobile = await getDeviceInfo();
    if (['android', 'ios'].includes(isMobile.platform)) {
      // AOS 13이상 일경우 권한 요청
      if (+(isMobile.osVersion) > 12 || isMobile.platform === 'ios') {
        // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
        const permissionCheck = await checkNotificationPermission();
        // 서비스 알림 허가가 아닐 시
        if (permissionCheck !== 'granted') {
          const permissionRequest = await requestNotificationPermission();
          if (permissionRequest === 'denied') {
            store.commit('dialog/messageModal', { show: true, message: i18n.t('alert.allowPush') }, { root: true });
          }
        }
      }
    }
    // 알림 수신시 이벤트
    // ios의 경우: 앱이 켜있어도 push 수신
    // web의 경우: socket.notification.send에서 수신처리
    // android의 경우: 아래 리스너에서 처리
    FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.info('notificationReceived: ', { event });
      if (event.notification) {
        // 안드로이드 인앱 알림
        if (['android'].includes(isMobile.platform)) {
          store.commit('notification/notification', event.notification);
        }
        // unReadCount update
        const currentCnt = store.getters['notification/unReadCount'];
        store.commit('notification/unReadCount', currentCnt + 1);
      }
    });
    // 알림 클릭시 이벤트
    FirebaseMessaging.addListener('notificationActionPerformed', async (event) => {
      const { platform } = await Device.getInfo();
      let url = null;
      if (platform === 'ios') url = event.notification.data.aps.url;
      else if (platform === 'android') url = event.notification.data.url;
      // url이 있을시 해당 주소로 이동
      if (url !== '' && url !== null) router.push(url);
    });
  },
};
