<template>
  <div class="map h-100">
    <!-- 위치전환시 리랜더를 위해 key 추가함 -->
    <GmapMap
      :key="rerenderKey"
      :center="center"
      :zoom="15"
      class="google-map-area"
      @click="infoOpen=false"
      :options="mapOptions"
    >
      <!-- 충전기 위치 -->
      <GmapCluster :icon="chargerIcon">
        <GmapMarker
          v-for="(m,index) in markers"
          :key="index"
          :position="m.geolocation.position"
          :clickable="true"
          @click="openLocationInfo(m)"
          :icon="m.geolocation.type === 'my' ?
            myIcon :
            getChargerIcon(m.availableEvseCount, m.inUseEvseCount)"
        ></GmapMarker>
      </GmapCluster>
      <!-- 내 위치 -->
      <gmap-info-window
        :options="infoMyOptions"
        :position="infoMyPos"
        :opened="myInfoOpen"
        @closeclick="myInfoOpen=false"
      ></gmap-info-window>
    </GmapMap>
    <!-- 검색창 -->
    <div class="w-100 map-search-bar">
      <v-btn
        class="mx-5 justify-start"
        rounded
        depressed
        text
        large
        :ripple="false"
        style="background-color: #fff; width: 90%;"
        @click="searchOpen = true"
      >
      <v-icon left>mdi-magnify</v-icon>
      {{ $t('map.searchBox.search') }}
      </v-btn>
    </div>
    <!-- 현재 내 위치로 -->
    <v-btn
      class="my-location btns"
      @click="geoFind"
      depressed
      fab
      small
    >
      <font-awesome-icon
        icon="fa-solid fa-location-crosshairs"
      />
    </v-btn>
    <!-- 모니터링 테스트용 -->
    <!-- <v-btn
      style="position: absolute; top: 10%;"
      @click="test({
        status: 'Charging',
        chargepoint: {
          model: {
            type: 'DC',
          },
          cpCode: 90018
        },
        connectorId: 2,
        meter: {
          soc: 20,
          ampere: 65,
          voltage: 714,
        },
        chargedMeter: 470,
        chargedPrice: 63.92,
        chargingStartDate: '2024-08-05T06:06:46.283Z',
      })"
    >
      testtt
    </v-btn> -->
    <!-- 슬라이드 -->
    <transition name="slide-up">
      <MapLocationInfo
        v-show="infoOpen"
        :infoOpen.sync="infoOpen"
        :selectedMarkerData.sync="selectedMarkerData"
        :companyInfo="companyInfo"
        :transactionData="transactionData"
        :currency="currency"
      />
    </transition>
    <MapSearch
      v-show="searchOpen"
      :searchOpen.sync="searchOpen"
      :markers="markers"
      @centerEvent="handleCenter"
      @searchEvseEvent="handleEvse"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Device } from '@capacitor/device';
import { Geolocation } from '@capacitor/geolocation';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import chargerIconOn from '@/assets/images/marker/marker_green.png';
import chargerIconInUse from '@/assets/images/marker/marker_orange.png';
import chargerIconOff from '@/assets/images/marker/marker_red.png';
import myIcon from '@/assets/images/marker/my_icon.png';
import MapLocationInfo from './MapLocationInfo.vue';
import MapSearch from './MapSearch.vue';

export default {
  components: { MapLocationInfo, MapSearch },
  props: {
    transactionData: {
      type: null,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      progress: 'dialog/progress',
    }),
  },
  data: () => ({
    center: {
      lat: 37.400355422938325,
      lng: 126.99084058921801,
    },
    mapOptions: {
      zoomControl: false,
      streetViewControl: false,
      gestureHandling: 'greedy',
      mapTypeControl: false,
      fullscreenControl: false,
      visibility: 'off',
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    },
    charging: true,
    infoOpen: false,
    searchOpen: false,
    selectedMarkerData: {},
    infoMyPos: null,
    infoMyOptions: {
      content: '',
      pixelOffset: {
        width: 0,
        height: -35,
      },
    },
    myInfoOpen: false,
    markers: [],
    chargerIcon: [
      {
        url: chargerIconOn,
        scaledSize: { width: 50, height: 50 },
        labelOrigin: { x: 0, y: 0 },
      },
      {
        url: chargerIconOff,
        scaledSize: { width: 50, height: 50 },
        labelOrigin: { x: 0, y: 0 },
      },
      {
        url: chargerIconInUse,
        scaledSize: { width: 50, height: 50 },
        labelOrigin: { x: 0, y: 0 },
      },
    ],
    myIcon: {
      url: myIcon,
      scaledSize: { width: 30, height: 30 },
    },
    rerenderKey: false,
  }),
  methods: {
    // 모니터링 테스트용
    ...mapMutations({
      test: 'transaction/transactionData',
    }),
    /* eslint no-underscore-dangle: 0 */
    async chargerAreaList() {
      try {
        const response = await this.$emitter('area.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: [
            {
              where: 'and',
              key: 'evse',
              value: [],
              condition: 'ne',
            },
          ],
        });
        this.markers = response.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async openLocationInfo(marker, searchItem) {
      if (marker.geolocation.position.myLocation === undefined) {
        try {
          const res = await this.$emitter('area.get', {
            _id: marker._id,
          });
          if (searchItem) {
            const filteredEvse = res.item.evse.filter(
              (evse) => evse.evseNumber.includes(searchItem),
            );
            this.selectedMarkerData = { ...res.item, evse: filteredEvse };
          } else this.selectedMarkerData = res.item;
          this.infoOpen = true;
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      }
    },
    async geoFind() {
      this.$dialog.progress(true);
      const { platform } = await Device.getInfo();
      switch (platform) {
        case 'web':
          await this.geoForWeb();
          break;
        case 'android':
        case 'ios':
          await this.geoForNative();
          break;
        default:
          this.$dialog.alert('error', this.$t('error.errorMessage'));
          this.$dialog.progress(false);
      }
    },
    async geoForWeb() {
      // 브라우저 지원여부 확인
      if (!('geolocation' in navigator)) return;
      const errorMsg = `${this.$t('error.location')}`;
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        if (result.state === 'granted' || result.state === 'prompt') {
          const { coords: { latitude: lat, longitude: lng } } = await new Promise(
            (resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            },
          );
          this.geoApply(lat, lng);
        } else if (result.state === 'denied') {
          this.$dialog.alert('error', errorMsg);
          this.$dialog.progress(false);
        }
      } catch (error) {
        this.$dialog.alert('error', this.$t('error.errorMessage'));
        console.error(error);
        this.$dialog.progress(false);
      }
    },
    async geoForNative() {
      try {
        const {
          coords: { latitude: lat, longitude: lng },
        } = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });
        this.geoApply(lat, lng);
      } catch (error) {
        const permission = await Geolocation.checkPermissions();
        if (permission.coarseLocation === 'denied' && permission.location === 'denied') {
          this.$dialog.confirm({
            show: true,
            message: `${this.$t('error.location')} ${this.$t('error.requestLocationPermission')}`,
            btnText: this.$t('button.goToSettings'),
            callback: () => {
              NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App,
              });
              this.$dialog.confirm();
            },
          });
        }
        console.error(error);
        this.$dialog.progress(false);
      }
    },
    geoApply(lat, lng) {
      const marker = {
        geolocation: {
          position: {
            lat,
            lng,
            myLocation: this.$t('map.currLocation'),
          },
          type: 'my',
        },
      };

      // 초기화
      this.center = null;
      this.infoMyPos = null;
      this.infoMyOptions.content = '';
      this.myInfoOpen = false;

      // 마커에서 '현재 내 위치' 삭제
      this.markers = this.markers.filter((mark) => mark.geolocation.position.myLocation !== this.$t('map.currLocation'));

      // 새로 감지한 내 위치 마커에 추가
      this.markers.push(marker);

      // 내 위치 정보 맵에 입려
      this.infoMyPos = marker.geolocation.position;
      this.infoMyOptions.content = marker.geolocation.position.myLocation;
      this.myInfoOpen = true;

      // 지도 중심 이동
      this.center = marker.geolocation.position;
      this.$dialog.progress(false);

      // 리랜더
      this.rerenderKey = !this.rerenderKey;
    },
    handleCenter(data) {
      this.center = data;
    },
    handleEvse(data, evseNumber) {
      this.center = data.geolocation.position;
      this.openLocationInfo(data, evseNumber);
    },
    getChargerIcon(available, inUse) {
      if (inUse > 0 && available === 0) return this.chargerIcon[2];
      if (available > 0 || inUse > 0) return this.chargerIcon[0];
      return this.chargerIcon[1];
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.chargerAreaList();
        }
      },
    },
    center: {
      handler() {
      },
    },
  },
};
</script>

<style lang="scss">
.google-map-area {
  height: calc(var(--vh)*100 - 56px);
}
.map-search-bar {
  position: absolute;
  top: 40px;
}
.map-wrap {
  .map {
    .gmap-container {
      // height: calc(var(--vh)*100 - var(--vh)*10 - 8%);
      height: 100vh;
      width: 100vw;
    }
    .btns {
      position: absolute;
      bottom: 30px;
      box-shadow: 4px 4px 10px rgba(0,0,0,0.4);
      font-size: 23px;
    }
    .map-search { right: 10px; }
    .my-location { right: 10px; }
    .slide-up-enter-active,
    .slide-up-leave-active {transition: transform 0.5s ease;}
    .slide-up-enter {transform: translateY(100%);}
    .slide-up-leave-to {transform: translateY(100%);}
    .curr-charge {
      position: absolute;
      width: 50px;
      height: 50px;
      bottom: 5%;
      left: 28px;
      border-radius: 92px;
      background-color: #000;
      svg {
        font-size: 30px;
        position: absolute;
        left: 12px;
        top: 9px;
        color: #fff;
      }
    }
  }
}
// 내위치 css 수정
.gm-style-iw { display: flex; flex-direction: row !important; align-items: center; }
.gm-style-iw-chr { order: 2; }
.gm-style-iw-d { order: 1; overflow: auto !important;}
</style>
