<template>
  <MyAccount/>
</template>

<script>
import MyAccount from '../../components/myAccount/MyAccountSection.vue';

export default {
  name: 'account',
  components: { MyAccount },
};
</script>
