<template>
  <Notice/>
</template>

<script>
import Notice from '../../components/customerCenter/notice/Notice.vue';

export default {
  name: 'notice',
  components: { Notice },
};

</script>
