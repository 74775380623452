<template>
  <div>
    <v-snackbar
      v-model="showPushMessage"
      :vertical="true"
      :top="true"
      :timeout="5000"
    >
      <div class="notification d-flex justify-space-between align-center">
        <div class="w-100 d-flex align-center" @click="move">
          <v-img
            class="rounded-circle"
            :src="imgPath"
            max-width="40" height="40"
          />
          <div class="notification-msg pl-5">
            <p class="font-weight-bold">{{ pushMessage.title }}</p>
            <p class="text-caption">{{ pushMessage.message }}</p>
          </div>
        </div>
        <v-btn
          color="white"
          depressed
          text
          @click="showPushMessage = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fileConverter from '@/util/file';

export default {
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  props: {
    pushMessage: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fileConverter,
    showPushMessage: false,
    imgPath: '',
  }),
  methods: {
    move() {
      this.$router.push(`${this.pushMessage.link}`);
      this.showPushMessage = false;
    },
  },
  watch: {
    pushMessage(val) {
      this.showPushMessage = val.show;
      this.imgPath = fileConverter.imagePathToSrc(val.img);
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  min-width: 345px;
  .notification-msg {white-space: pre-line;}
}
</style>
