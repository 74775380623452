<template>
  <div class="test_wrap">
    <v-btn @click="checkPermission">알림권한확인</v-btn>
    <!-- 가입 유무 상관없음 -->
    <v-btn @click="requestPermission">알림권한요청</v-btn>
    <!-- 가입 완료할때 user정보랑 보내기-->
    <v-btn @click="getToken">토큰요청</v-btn>
    <v-btn @click="getDeviceInfo">디바이스정보요청</v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getDeviceInfo,
  checkNotificationPermission,
  requestNotificationPermission,
  getFCMToken,
} from '@/util/native';

export default {
  components: {},
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
  }),
  methods: {
    // 디바이스 정보 확인
    async getDeviceInfo() {
      const result = await getDeviceInfo();
      console.log(result);
    },
    // 알림권한 확인
    async checkPermission() {
      const result = await checkNotificationPermission();
      console.log(result);
    },
    // 알림권한 요청
    async requestPermission() {
      const result = await requestNotificationPermission();
      console.log(result);
    },
    // 토큰 요청
    async getToken() {
      const token = await getFCMToken();
      console.log(token);
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler() {
        // 테스트용 푸시 수신 이벤트 리스너 초기화
        // native.firebaseInit();
      },
    },
  },
};

</script>

<style lang="scss">
</style>
