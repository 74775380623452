<!-- 카드 등록이 되어있을 경우 보이는 페이지 -->
<template>
  <div class="creditcard-detail">
    <Title
      :title="'myAccount.paymentCard.title'"
      :goBack="true"
      class="px-5"
    />
    <p class="pa-5 d-flex align-center">
      <v-icon class="pr-1">mdi-credit-card-check</v-icon>
      {{ $t('myAccount.paymentCard.cnt', {total: userCards.length}) }}
    </p>
    <v-list class="px-5 card-list">
      <v-list-item
        v-for="(card, idx) in userCards"
        :key="idx"
        class="px-0 mb-2 rounded grey lighten-5"
      >
        <div class="w-100 d-flex align-center py-6 px-4">
          <p
            class="w-25 white--text rounded text-center mr-3"
            :style="{ background: getBackgroundColor(card.cardCode) }"
          >{{ card.cardName }}</p>
          <v-list-item-title class="w-75">
            {{ `${card.cardName}${$t('myAccount.paymentCard.card')} (${card.cardNo.slice(-4)})` }}
          </v-list-item-title>
          <div class="w-25 d-flex justify-center align-center">
            <v-btn
              text
              depressed
              height="0"
              min-width="0"
              @click="isDefaultCard(card)"
              class="pa-0 pr-2"
            >
              <v-icon :color="card.isDefault ? 'primary' : 'grey'">mdi-star</v-icon>
            </v-btn>
            <v-btn
              v-if="!card.isDefault"
              text
              depressed
              height="0"
              min-width="0"
              @click="deleteCard(card)"
              class="pa-0"
            >
              <v-icon color="grey">mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <BottomBtn
      :mdiIcon="'plus'"
      :btnText="['myAccount.paymentCard.addCard']"
      :callback="addCreditCard"
      :btnCnt="1"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getBackgroundColor } from '@/util/creditcardColors';
import Title from '../../common/Title.vue';
import BottomBtn from '../../common/BottomBtn.vue';

export default {
  components: { Title, BottomBtn },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    userCards: [],
    getBackgroundColor,
  }),
  methods: {
    async getUserCard() {
      try {
        const res = await this.$emitter('usercard.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['isDefault', 'created'],
          sortDesc: [true, true],
        });
        if (res.items.length > 0) {
          this.userCards = res.items;
          this.isCreditCard = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async isDefaultCard(card) {
      if (!card.isDefault) {
        try {
          await this.$emitter('usercard.default.update', {
            _id: card._id,
          });
          this.getUserCard();
          this.$dialog.alert('info', `${this.$t('myAccount.paymentCard.changeDefaultCard')}`);
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        this.$dialog.alert('error', `${this.$t('myAccount.paymentCard.alreadySetCard')}`);
      }
    },
    /* eslint no-underscore-dangle: 0 */
    async deleteCard(item) {
      this.$dialog.confirm({
        show: true,
        message: 'button.deleteQ',
        btnText: 'button.delete',
        callback: async () => {
          if (this.userCards.length !== 1 || !item.isDefault) {
            this.$dialog.progress(true);
            try {
              await this.$emitter('usercard.delete', {
                _id: item._id,
              });
              this.$dialog.alert('info', `${this.$t('myAccount.paymentCard.delete')}`);
              this.$dialog.confirm();
              this.getUserCard();
            } catch (error) {
              console.error(error);
              this.$dialog.alert('error', this.$error.makeErrorMessage(error));
            }
          } else {
            this.$dialog.alert('error', `${this.$t('error.400.400')}`);
          }
          this.$dialog.progress(false);
        },
      });
    },
    addCreditCard() {
      if (this.userCards.length > 2) {
        this.$dialog.alert('error', `${this.$t('error.pgError.400.BAD_REQUEST')}`);
      } else {
        this.$router.push('/account/card-enroll');
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getUserCard();
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
