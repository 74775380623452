import Home from '../views/Home.vue';
import MyAccount from '../views/myAccount/MyAccount.vue';
import PointDetail from '../views/myAccount/PointDetail.vue';
import ChargingHistory from '../views/myAccount/ChargingHistory.vue';
import Profile from '../views/myAccount/Profile.vue';
import PaymentCardEnroll from '../components/myAccount/paymentCard/PaymentCardEnroll.vue';
import PaymentCardDetail from '../views/myAccount/PaymentCardDetail.vue';
import MembershipEnroll from '../components/myAccount/membershipCard/MembershipEnroll.vue';
import MembershipCardDetail from '../views/myAccount/MembershipCardDetail.vue';
import CustomerCenter from '../views/customerCenter/CustomerCenter.vue';
import Faq from '../views/customerCenter/Faq.vue';
import Issue from '../views/customerCenter/Issue.vue';
import MyIssueDetail from '../components/customerCenter/issue/MyIssueDetail.vue';
import Notice from '../views/customerCenter/Notice.vue';
import NoticeDetail from '../components/customerCenter/notice/NoticeDetail.vue';
import Login from '../views/login/Login.vue';
import SignUp from '../views/signUp/SignUp.vue';
import Monitoring from '../components/monitoring/monitoring/Monitoring.vue';
import Test from '../views/test/Test.vue';

export default [
  {
    path: '/',
    component: Home,
    meta: {
      needLogin: false,
    },
    children: [
    ],
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      needLogin: true,
    },
    component: MyAccount,
  },
  {
    path: '/account/profile',
    name: 'profile',
    meta: {
      needLogin: true,
    },
    component: Profile,
  },
  {
    path: '/account/point',
    name: 'point',
    meta: {
      needLogin: true,
    },
    component: PointDetail,
  },
  {
    path: '/account/card-enroll',
    name: 'paymentCardEnroll',
    meta: {
      needLogin: true,
    },
    component: PaymentCardEnroll,
  },
  {
    path: '/account/paymentCard',
    name: 'paymentCardDetail',
    meta: {
      needLogin: true,
    },
    component: PaymentCardDetail,
  },
  {
    path: '/account/member-enroll',
    name: 'membershipEnroll',
    meta: {
      needLogin: true,
    },
    component: MembershipEnroll,
  },
  {
    path: '/account/member',
    name: 'membershipCardDetail',
    meta: {
      needLogin: true,
    },
    component: MembershipCardDetail,
  },
  {
    path: '/account/chargingHistory',
    name: 'chargingHistory',
    meta: {
      needLogin: true,
    },
    component: ChargingHistory,
  },
  {
    path: '/customer',
    name: 'customer',
    meta: {
      needLogin: false,
    },
    component: CustomerCenter,
  },
  {
    path: '/customer/faq',
    name: 'faq',
    meta: {
      needLogin: false,
    },
    component: Faq,
  },
  {
    path: '/customer/issue',
    name: 'issue',
    meta: {
      needLogin: true,
    },
    component: Issue,
  },
  {
    path: '/customer/issue/:id',
    name: 'myIssueDetail',
    meta: {
      needLogin: true,
    },
    component: MyIssueDetail,
  },
  {
    path: '/customer/notice/',
    name: 'notice',
    meta: {
      needLogin: false,
    },
    component: Notice,
  },
  {
    path: '/customer/notice/:id',
    name: 'noticeDetail',
    meta: {
      needLogin: false,
    },
    component: NoticeDetail,
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      needLogin: false,
    },
    component: Login,
  },
  {
    path: '/signup',
    name: 'signUp',
    meta: {
      needLogin: false,
    },
    component: SignUp,
  },
  {
    path: '/oauth/login',
    redirect: '/',
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    meta: {
      needLogin: false,
    },
    component: Monitoring,
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      needLogin: false,
    },
    component: Test,
  },
];
