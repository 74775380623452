<template>
  <div class="charging-history-wrap px-5">
    <div class="title-sticky">
      <Title :title="'myAccount.chargingHistory.title'" :goBack="true"/>
      <div class="charging-history-date w-100 d-flex justify-center">
        <button
          @click="moveMonth('prev')"
        >
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </button>
        <div class="px-5">{{ month }}</div>
        <button
          @click="moveMonth('next')"
        >
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </button>
      </div>
      <v-divider></v-divider>
      <div class="total-data d-flex align-center justify-space-around">
        <div class="my-charging" v-if="userInfo != null">
          <p>{{ $t('myAccount.chargingHistory.totalMeter') }}</p>
          <p class="meter-total font-weight-bold">
            {{ summary.total !== 0 ? summary.totalChargedMeter / 1000 : 0}} kWh
          </p>
        </div>
        <div class="my-charging" v-if="userInfo != null">
          <p>{{ $t('myAccount.chargingHistory.totalPrice') }}</p>
          <p class="price-total font-weight-bold">
            {{ currency }}
            {{ summary.total !== 0 ? summary.totalChargedPrice.toLocaleString() : 0 }}
          </p>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="charging-history-detail">
      <p class="total lightGrey2--text py-4" v-if="summary.total !== null">
        {{ $t('myAccount.chargingHistory.total', { total: summary.total}) }}
      </p>
      <ul
        class="history-list pa-0"
        v-for="(item, index) in chList"
        :key="index"
      >
        <li class="w-100">
          <p class="d-flex justify-space-between align-center text-caption font-weight-bold pb-2">
            {{ timeConvert.makeDays(item.sessionStartDate, $i18n, 'simple') }}
          </p>
          <div class="w-100 d-flex align-center">
            <div
              style="width: 90%;"
              class="d-flex align-center justify-space-between"
            >
              <div>
                <p
                  v-if="item.chargepoint.area !== undefined"
                  class="charging-name lightGrey2--text"
                >
                  {{ item.chargepoint.area.name }}
                </p>
                <p class="charging primary--text font-weight-bold">
                  {{ timeConvert.makeCalcTime(item.sessionStartDate, item.sessionEndDate) }}
                </p>
              </div>
              <div class="pr-3">
                <p class="charging-amount" v-if="item.chargedPrice !== undefined">
                  {{ currency }}
                  {{item.chargedPrice.toLocaleString() }}
                </p>
                <p class="charging-kwh lightGrey2--text">
                  {{ item.chargedMeter !== undefined
                    ? (item.chargedMeter/1000).toFixed(2) : '-'}}kWh
                </p>
              </div>
            </div>
            <div
              style="width: 10%;"
              class="text-right"
              @click="getPaymentDetail(item)"
            >
              <v-icon>mdi-receipt-text</v-icon>
            </div>
          </div>
        </li>
        <v-divider v-if="index < chList.length - 1"></v-divider>
      </ul>
      <div
        v-if="summary.total === 0"
        class="charging-history-nothing w-100 d-flex align-center justify-center flex-column"
      >
        <div><font-awesome-icon icon="fa-solid fa-charging-station" /></div>
        <div class="pt-4 font-weight-bold">{{ $t('myAccount.chargingHistory.noCharge') }}</div>
      </div>
    </div>
    <!-- receipt -->
    <v-dialog
      v-if="selectedItem !== null"
      transition="dialog-top-transition"
      v-model="receipt"
      width="400"
      scrollable
    >
      <v-card class="receipt-wrap">
        <v-card-title class="text-h6 d-flex flex-column align-start">
          <p>{{ $t('myAccount.chargingHistory.receipt.detail') }}</p>
        </v-card-title>
        <v-card-text>
          <div class="text-right">
            <p>{{ selectedItem.paymentType === 'credit'
              ? `${$t('myAccount.chargingHistory.receipt.creditCard')}
              ${selectedItem.payment.cardInfo.cardName}
              (${selectedItem.payment.cardInfo.cardNo.slice(-4)}) `
              : `${$t('myAccount.chargingHistory.receipt.point')}`}}
            </p>
          </div>
          <v-divider color="black"></v-divider>
          <div class="d-flex justify-space-between">
            <p>{{$t('myAccount.chargingHistory.receipt.date')}}</p>
            <p>{{ timeConvert.makeDays(selectedItem.sessionEndDate, $i18n, 'full') }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="pr-3">{{ $t('myAccount.chargingHistory.receipt.average') }}</p>
            <p>
              {{ `${(selectedItem.chargedPrice / (selectedItem.chargedMeter / 1000 )).toFixed(1)}
              ${$t('myAccount.chargingHistory.receipt.won')}` }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <p>{{ $t('myAccount.chargingHistory.receipt.charge') }}</p>
            <p>{{ (selectedItem.chargedMeter/1000).toFixed(2) }}kWh</p>
          </div>
          <div class="d-flex justify-space-between">
            <p>{{ $t('myAccount.chargingHistory.receipt.amount') }}</p>
            <p class="font-weight-bold black--text">
              {{ `${selectedItem.chargedPrice.toLocaleString()}
              ${$t('myAccount.chargingHistory.receipt.won')}` }}
            </p>
          </div>
          <v-divider color="black"></v-divider>
          <div class="d-flex justify-space-between">
            <p>{{ $t('map.mapInfo.evse') }}</p>
            <p class="font-weight-bold">
              {{ `${selectedItem.chargepoint.cpCode}-${selectedItem.connectorId}` }}
            </p>
          </div>
          <div>
            <div class="d-flex justify-space-between">
              <p>{{ $t('myAccount.chargingHistory.receipt.station') }}</p>
              <p class="text-right"> {{ selectedItem.chargepoint.area.name }}</p>
            </div>
            <p class="text-right">{{ selectedItem.chargepoint.area.address }}</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';
import { navigateMonth } from '@/util/calendar';
import Title from '../../common/Title.vue';

export default {
  components: { Title },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    currency: '',
    timeConvert,
    month: timeConvert.getMonth(),
    summary: {
      total: 0,
      totalChargedMeter: 0,
      totalChargedPrice: 0,
    },
    chList: [],
    filters: [
      {
        where: 'and',
        condition: 'gte',
        key: 'sessionStartDate',
        value: `${timeConvert.getMonth2()}-01 00:00:00`,
      },
      {
        where: 'and',
        condition: 'lte',
        key: 'sessionStartDate',
        value: `${timeConvert.getLastDay()} 23:59:59`,
      },
      {
        where: 'and',
        condition: 'eq',
        key: 'status',
        value: 'SessionCompleted',
      },
    ],
    receipt: false,
    selectedItem: null,
  }),
  methods: {
    async chargingHistoryList(filter) {
      this.$dialog.progress(true);
      try {
        const response = await this.$emitter('transaction.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: filter,
          sortBy: ['sessionStartDate'],
          sortDesc: [true],
        });
        this.chList = response.items;
        this.transactionTotal(filter);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    moveMonth(type) {
      const result = navigateMonth(type, this.month, this.filters, 'charging');
      this.month = result.newDate;
      this.chargingHistoryList(result.filters);
    },
    async transactionTotal(filter) {
      const res = await this.$emitter('transaction.summary.get', {
        filters: filter,
      });
      this.summary = res.data;
    },
    /* eslint no-underscore-dangle: 0 */
    async getPaymentDetail(item) {
      try {
        const res = await this.$emitter('transaction.get', {
          _id: item._id,
        });
        this.selectedItem = res.item;
        this.receipt = true;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(val) {
        if (val != null && this.userInfo != null) this.chargingHistoryList(val);
      },
    },
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          switch (val.currencyUnit) {
            case 'krw': this.currency = this.$t('common.krw'); break;
            case 'usd': this.currency = this.$t('common.usd'); break;
            case 'jpy': this.currency = this.$t('common.jpy'); break;
            default: break;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.charging-history-wrap {
  position: absolute !important;
  overflow-y: scroll;
  .charging-history-title { font-size: 20px; }
  .my-charging {
    p:nth-child(1) {font-size: 0.8rem;}
    .meter-total, .price-total {font-size: 1.2rem;}
  }
  .charging-history-detail {
    .total { font-size: 0.8rem; }
    .history-list {
      .charging-type { width: 30px; height: 30px; }
      .charging-kwh, .charging-name, .charging-start { font-size: 0.8rem; }
      .charging { font-size: 1.1rem; }
    }
    .charging-history-nothing {
      height: 50vh;
      svg { font-size: 50px;}
    }
  }
}
</style>
