<template>
  <div class="withdrawal-wrap" v-show="showWithdrawal === true">
    <v-row justify="center">
      <v-dialog
        v-model="showWithdrawal"
        max-width="500px"
      >
        <v-card
          min-height="150px"
        >
          <v-card-title class="withdrawal-card pb-0 flex-column justify-center">
            <pre class="msg mb-0"><p>{{$t('myAccount.update.withdrawalInfo')}}</p></pre>
            <v-text-field
              class="withdrawal-email w-75 pt-0"
              v-model="email"
              autocomplete="on"
            >
            </v-text-field>
          </v-card-title>
          <v-card-actions class="btns d-flex align-center justify-space-around">
            <v-btn
              outlined
              depressed
              color="primary"
              @click="btnAction('cxl')"
            >{{$t('button.cancel')}}</v-btn>
            <v-btn
              color="primary"
              depressed
              @click="btnAction('withdrawal')"
            >{{$t('myAccount.update.withdrawal')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    showWithdrawal: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    email: '',
    show: false,
  }),
  methods: {
    ...mapActions({
      signout: 'auth/signout',
    }),
    async btnAction(type) {
      const message1 = `${this.$t('alert.withdrawal')}`;
      if (type === 'cxl') this.$emit('update:showWithdrawal', false);
      if (type === 'withdrawal') {
        try {
          await this.$emitter('user.leave', {
            email: this.email,
          });
          this.$emit('update:showWithdrawal', false);
          this.$dialog.alert('success', message1);
          this.$router.push('/');
          this.signout();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      }
    },
  },
};
</script>

<style scoped lang="scss" scope>
.withdrawal-card {
  .msg {font-size: 1rem;}
}
</style>
