<template>
  <div>
    <div class="titles-wrap d-flex align-center pt-6">
      <div
        v-if="goBack"
        @click="$router.go(-1)"
        :class="isStyle ? 'pr-3' : ''"
      >
        <font-awesome-icon icon="fa-solid fa-angle-left" class="back-icon"/>
      </div>
      <p
        class="titles w-100 d-flex font-weight-bold"
        :class="isStyle ? 'justify-start' : 'justify-center'"
      >
        {{ $t(title) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    goBack: {
      type: Boolean,
      default: false,
    },
    isStyle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
.titles-wrap {
  height: 12vh;
  .back-icon {cursor: pointer;}
  .titles { font-size: 20px; }
}
</style>
