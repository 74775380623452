<template>
  <div>
    <p class="pt-4 font-weight-bold">{{ $t('myAccount.membershipCard.title') }}</p>
    <div
      class="membership-box rounded-lg mt-2 d-flex align-center"
      @click="$router.push('/account/member')"
    >
      <div v-if="membership.idTag" class="w-100 d-flex justify-space-between px-5">
        <div class="d-flex align-center">
          <v-icon class="pr-2">mdi-wallet-membership</v-icon>
          <p>{{ $t('myAccount.membershipCard.membership') }}</p>
        </div>
        <div class="d-flex align-center">
          <p>{{ $t('button.done') }}</p>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </div>
      <div
        v-else
        class="w-100 d-flex align-center justify-center"
        @click="goRegister"
      >
        <div
          v-if="!isCardIssuing"
          class="d-flex align-center"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
          <p class="text-center pl-1">{{ $t('myAccount.membershipCard.enroll')}}</p>
        </div>
        <div
          v-else
          class="d-flex align-center"
        >
          <v-icon>mdi-registered-trademark</v-icon>
          <p class="text-center pl-1">{{ $t('myAccount.membershipCard.cardIssuing') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    membership: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    isCardIssuing: false,
  }),
  methods: {
    goRegister() {
      if (this.isCardIssuing) this.$router.push('/account/member');
      else this.$router.push('/account/member-enroll');
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (val.address !== null && !this.membership.idTag) {
            this.isCardIssuing = true;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.membership-box {
  height: 50px;
  border: 1px solid #ccc;
}
</style>
