<template>
  <div v-if="userInfo !== null && userCards.length > 0">
    <v-dialog
      v-model="openChargeSetting"
      transition="dialog-bottom-transition"
      fullscreen
      persistent
    >
      <v-card class="start-wrap h-100">
        <div class="start-top d-flex align-center pt-8">
          <button
            @click="close"
            class="close-btn"
          ><font-awesome-icon icon="fa-solid fa-angle-down" /></button>
          <div class="start-title d-flex align-center flex-column">
            <p class="cp-name font-weight-bold">{{ selectedEvse.cpName }}</p>
            <p class="cp-address">{{ selectedEvse.cpAddress }}</p>
          </div>
        </div>
        <div class="start-body pa-5">
          <div class="model-detail d-flex align-center">
            <img
              :src="selectedEvse.imageUrl"
              :alt="selectedEvse.imageUrl"
              style="width: 40px;"
            />
            <div class="pl-4 font-weight-bold">
              <p>{{ selectedEvse.evseNumber }}</p>
              <div class="d-flex align-center gap-1">
                <p class="mr-1 text-caption primary--text">
                  {{ selectedEvse.kwh }}kW | {{ selectedEvse.type === 'DC'
                  ? $t('map.mapInfo.highSpeed') : $t('map.mapInfo.slow')}}</p>
                </div>
              </div>
          </div>
          <div class="d-flex align-center">
            <v-text-field
              :placeholder="$t('map.remoteStart.inputAmount')"
              :rules="amountRule"
              v-model="chargingAmount"
              clearable
            ></v-text-field>
            <p v-if="chargingAmount" class="text-subtitle-2 pl-3">
              {{ kwh }}kWh</p>
          </div>
          <div class="d-flex justify-center align-center w-100 pt-3 amount-btn-wrap">
            <v-btn
              v-for="(amount, idx) in amountRange"
              :key=idx
              depressed
              text
              outlined
              class="w-25"
              @click="increaseAmount(amount)"
            >+{{ amount.toLocaleString() }}</v-btn>
          </div>
          <div class="payment pt-5">
            <p class="py-4 font-weight-bold">{{ $t('map.remoteStart.payment') }}</p>
            <v-btn
              class="w-100"
              depressed
              x-large
              text
              outlined
              @click="methodsOpen = !methodsOpen"
            >
              <div class="d-flex align-center w-100">
                <v-avatar
                  v-if="Object.keys(selectedPayment).length > 0"
                  :color="getBackgroundColor(selectedPayment.cardCode)"
                  size="30"
                >
                  <span class="white--text text-caption">{{ selectedPayment.cardName }}</span>
                </v-avatar>
                <v-avatar v-else :color="getBackgroundColor(userCards[0].cardCode)" size="30">
                  <span class="white--text text-caption">{{ userCards[0].cardName }}</span>
                </v-avatar>
                <p class="pl-5">
                  {{ Object.keys(selectedPayment).length > 0
                    ? selectedPayment.type !== 'point'
                      ? `${selectedPayment.cardName}
                        ${$t('myAccount.paymentCard.card')} ${selectedPayment.cardNo}`
                      : `${selectedPayment.point} (${myPoint.toLocaleString()}P)`
                    : `${userCards[0].cardName}
                      ${$t('myAccount.paymentCard.card')} ${userCards[0].cardNo}`
                  }}
                </p>
              </div>
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="start-bottom d-flex justify-center">
          <v-btn
            class="start-btn rounded-lg"
            large
            depressed
            color="primary"
            @click="startCharging"
            :disabled="
              Object.keys(selectedPayment).length === 0
              || numericValue === null
              || numericValue > 50001
              || (selectedPayment.type === 'point' && myPoint < numericValue)"
          >{{$t('map.mapInfo.startCharging')}}</v-btn>
        </div>
      </v-card>
      <v-bottom-sheet v-model="methodsOpen" open-delay="2" max-width="767">
        <div class="list-wrap rounded-t-xl">
          <p class="d-flex justify-center font-weight-bold pt-5">
            {{ $t('map.remoteStart.paymentSelect') }}
          </p>
          <v-list class="overflow-y-auto" style="max-height: 340px; padding-bottom: 80px;">
            <v-list-item
              v-for="(card, idx) in userCards"
              :key="idx"
              @click="selectedMethod(card)"
            >
              <v-list-item-avatar>
                <v-avatar
                  :color="getBackgroundColor(card.cardCode)"
                  tile size="40"
                >
                  <span class="white--text">{{ card.cardName }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title class="pl-3">
                <div class="d-flex align-center justify-space-between">
                  <p>
                    {{ `${card.cardName}${$t('myAccount.paymentCard.card')} ${card.cardNo}` }}
                  </p>
                </div>
              </v-list-item-title>
              <v-icon v-if="card.isDefault" color="primary">mdi-star</v-icon>
            </v-list-item>
            <v-list-item
              v-if="userInfo.restPoints !== 0"
              @click="selectedMethod('point')"
            >
              <v-list-item-avatar>
                <v-avatar
                  color="primary"
                  tile size="40"
                >
                  <span class="white--text">P</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title class="pl-3">
                <div class="d-flex align-center justify-space-between">
                  <p>{{ $t('map.remoteStart.usePoints') }}</p>
                  <p class="font-weight-bold">{{ myPoint.toLocaleString() }}</p>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-bottom-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { getBackgroundColor } from '@/util/creditcardColors';

export default {
  props: {
    openChargeSetting: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: null,
      required: true,
    },
    selectedEvse: {
      type: Object,
      required: true,
    },
    chargeRate: {
      type: Number,
      required: true,
    },
    companyInfo: {
      type: Object,
      required: true,
    },
    userCards: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    getBackgroundColor,
    dialog: false,
    kwh: 0,
    maxValue: 50000,
    myPoint: 0,
    chargingAmount: null,
    numericValue: 0,
    selectedPayment: {},
    amountRange: [],
    methodsOpen: false,
  }),
  computed: {
    amountRule() {
      const rules = [];
      if (this.chargingAmount) {
        const value = parseInt(this.chargingAmount.replace(/[^\d]/g, ''), 10);
        const checkPoint = [() => value <= this.myPoint || this.$t('rules.checkPoint')];
        const minAmount = [() => value > 499 || this.$t('rules.minimumAmount')];
        const maxAmount = [() => value < 50001 || this.$t('rules.maximumAmount')];
        if (this.selectedPayment.type === 'point') rules.push(...checkPoint);
        rules.push(...minAmount, ...maxAmount);
      }
      return rules;
    },
  },
  methods: {
    ...mapMutations({
      changeTransactionData: 'transaction/transactionData',
      changeEvseInfo: 'transaction/evseInfo',
    }),
    ...mapActions({
      authorization: 'auth/authorization',
    }),
    close() {
      this.$emit('update:openChargeSetting', false);
      const defaultCard = this.userCards[0];
      this.selectedPayment = defaultCard;
      this.chargingAmount = null;
    },
    formatChargingAmount(value) {
      if (!value || value === 0) {
        return '';
      }
      const currencyFormat = {
        krw: { style: 'currency', currency: 'KRW', symbol: '₩' },
        usd: { style: 'currency', currency: 'USD', symbol: '$' },
        jpy: { style: 'currency', currency: 'JPY', symbol: '¥' },
      };

      const { currencyUnit } = this.companyInfo;
      const formattedValue = new Intl.NumberFormat('ko-KR', currencyFormat[currencyUnit]).format(value);
      const final = `${formattedValue.slice(0, 1)} ${formattedValue.slice(1)}`;
      return final;
    },
    increaseAmount(amount) {
      if (this.chargingAmount !== null && this.chargingAmount !== '') {
        const numericAmount = parseFloat(this.chargingAmount.replace(/[^\d.]/g, ''));
        const increasedAmount = this.formatChargingAmount(numericAmount + amount);
        this.chargingAmount = increasedAmount;
      } else {
        const increasedAmount = this.formatChargingAmount(amount);
        this.chargingAmount = increasedAmount;
      }
    },
    selectedMethod(item) {
      if (item === 'point') {
        this.selectedPayment = {
          cardName: 'P',
          point: `${this.$t('map.remoteStart.usePoints')}`,
          type: 'point',
        };
      } else {
        this.selectedPayment = item;
      }
      this.methodsOpen = false;
    },
    async getUser() {
      const res = await this.$emitter('user.get', {});
      this.myPoint = Math.floor(res.userInfo.restPoints);
    },
    /* eslint no-underscore-dangle: 0 */
    async startCharging() {
      this.$dialog.progress(true);
      const numericAmount = parseFloat(this.chargingAmount.replace(/[^\d.]/g, ''));
      const paymentType = this.selectedPayment.type === 'point' ? 'point' : 'credit';
      const params = {
        paymentType,
        targetPrice: numericAmount,
        evseNumber: this.selectedEvse.evseNumber,
        userCard_id: paymentType === 'credit' ? this.selectedPayment._id : '',
      };
      try {
        const res = await this.$emitter('transaction.start', params);
        this.changeTransactionData(res.data);
        this.changeEvseInfo(this.selectedEvse);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.transactionError(error));
      }
    },
  },
  watch: {
    // chargeRate: {
    //   immediate: true,
    //   handler(val) {
    //     if (val != null) {
    //       this.kwh = (this.chargingAmount / this.chargeRate).toFixed(2);
    //     }
    //   },
    // },
    chargingAmount: {
      deep: true,
      handler(val) {
        if (val != null && this.chargeRate != null) {
          this.numericValue = parseFloat(val.replace(/[^\d.]/g, '')) || 0;
          const formattedValue = this.formatChargingAmount(this.numericValue);
          this.kwh = (this.numericValue / this.chargeRate).toFixed(2);
          this.chargingAmount = formattedValue;
        }
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.myPoint = val.restPoints;
        }
      },
    },
    userCards: {
      immediate: true,
      handler(val) {
        if (val != null) {
          const defaultCard = val[0];
          this.selectedPayment = defaultCard;
        }
      },
    },
    selectedPayment: {
      handler(val) {
        if (val != null) {
          if (val === 'credit') this.currentMax = 50000;
          else if (this.myPoint < this.maxValue) {
            this.currentMax = this.myPoint;
          } else this.currentMax = 50000;
          if (val === 'point') this.getUser();
        }
      },
    },
    openChargeSetting: {
      handler(val) {
        if (val) this.getUser();
      },
    },
    companyInfo: {
      immediate: true,
      handler(val) {
        if (val != null) {
          switch (val.currencyUnit) {
            case 'krw': this.amountRange = [1000, 5000, 10000, 30000]; break;
            case 'usd': this.amountRange = [1, 5, 10, 50]; break;
            case 'jpy': this.amountRange = [100, 500, 1000, 5000]; break;
            default: break;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.start-wrap {
  position: relative;
  border-radius: 0px !important;
  .start-top {
    height: 10%;
    .close-btn {width: 15%;}
    .start-title {
      width: 70%;
      .cp-name { font-size: 1.2rem;}
      .cp-address { font-size: 0.8rem; }
    }
  }
  .start-body {
    height: 90%;
    .model-detail {
      .sub-detail {
        width: 80%;
        height: 30px;
        font-size: 0.9rem;
      }
    }
    .amount-btn-wrap {
      button+button{ border-left: none; }
      button {border-radius: 0px;}
      button:first-child { border-radius: 4px 0 0 4px;}
      button:last-child { border-radius: 0 4px 4px 0;}
    }
    .payment { .toggle-wrap { width: 70%; } }
  }
  .start-bottom {
    max-width: 767px;
    width: 100%;
    position: fixed;
    bottom: 1%;
    .start-btn {width: 90%;}
  }
}
.v-bottom-sheet {
  position: fixed;
  .list-wrap { background-color: #fff;}
}
</style>
