<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      max-width="300px"
      @click:outside="init()"
      @keydown="$event.key === 'Escape' ? init() : ''"
    >
      <v-card>
        <v-card-text class="pt-6 msg" style="word-break: auto-phrase;"> {{ $t(message) }}
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-2">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            outlined
            color="primary"
            @click="init()"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="callback"
          >
            {{ $t(btnText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgConfirm',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    btnText: {
      type: String,
      default: '확인',
    },
    color: {
      type: String,
      default: 'primary',
    },
    message: {
      type: String,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    init() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped lang="scss">
.msg{
  font-size: 1rem;
  color: #333 !important;
  font-weight: 500;
}
.row { flex: 0;}
</style>
